import React, { useState } from 'react';
import './MainBanner.scss';
import ImprovementImage from "../../..//Assets/AboutUs/Transforming-leaders.jpg";
import BreakthroughsImage from "../../..//Assets/AboutUs/Transforming-Students.jpg";
import TransformImage from "../../..//Assets/AboutUs/Transforming-Community.jpg";

const MainBanner = () => {
    const [hoveredItem, setHoveredItem] = useState(null);

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    return (
        <>
            <div className="main-banner-section">
                <div className="item" data-order="1" onMouseEnter={() => handleMouseEnter("leaders")} onMouseLeave={handleMouseLeave}>

                    {hoveredItem === "leaders" ? (
                        <div className="content">
                            <h2>Underlying improvement system</h2>
                            <p>Introduce new concepts, methods and tools that refreshes and increases a school’s capacity to improve and thrive in complexity.</p>
                        </div>
                    ) :
                        <div className="default" style={{ backgroundColor: "rgba(234, 130, 84, 0.85)", height: "100%" }}>
                            <h2>Underlying improvement system</h2>
                        </div>
                    }
                </div>

                <div className="item" data-order="2" onMouseEnter={() => handleMouseEnter("students")} onMouseLeave={handleMouseLeave}>
                    {hoveredItem === "students" ? (
                        <div className="content">
                            <h2>Breakthroughs</h2>
                            <p>Innovation and discoveries that dramatically change the outcomes and course of schools.</p>
                        </div>
                    ) :
                        <div className="default" style={{ backgroundColor: "rgba(59, 138, 110, 0.50)", height: "100%" }}>
                            <h2>Breakthroughs</h2>
                        </div>
                    }
                </div>

                <div className="item" data-order="3" onMouseEnter={() => handleMouseEnter("community")} onMouseLeave={handleMouseLeave} >
                    {hoveredItem === "community" ? (
                        <div className="content">

                            <h2>Transform</h2>
                            <p>Optimize and redesign schools so their leaders, educators and students can collectively achieve their highest potential.</p>
                        </div>
                    ) :
                        <div className="default" style={{ backgroundColor: "rgba(5, 41, 65, 0.50)", height: "100%" }}>
                            <h2>Transform</h2>
                        </div>
                    }
                </div>
            </div>

            <div className="mob-system-banner-section">
                <div className="content">
                    <div className="overlay" style={{ background: "rgba(234, 130, 84, 0.85)" }}>
                        <h2>Underlying improvement system</h2>
                        <p>Introduce new concepts, methods and tools that refreshes and increases a school’s capacity to improve and thrive in complexity.</p>
                    </div>
                    <img src={ImprovementImage} alt="OnsiteImage" className="image" />
                </div>

                <div className="content">
                    <div className="overlay" style={{ background: "rgba(59, 138, 110, 0.50)" }}>
                        <h2>Breakthroughs</h2>
                        <p>Innovation and discoveries that dramatically change the outcomes and course of schools.</p>
                    </div>
                    <img src={BreakthroughsImage} alt="TherapyImage" className="image" />
                </div>
                
                <div className="content">
                    <div className="overlay" style={{ background: "rgba(5, 41, 65, 0.50)" }}>
                        <h2>Transform</h2>
                        <p>Optimize and redesign schools so their leaders, educators and students can collectively achieve their highest potential.</p>
                    </div>
                    <img src={TransformImage} alt="FamilyImage" className="image" />
                </div>
            </div>
        </>
    );
};

export default MainBanner;