import React from 'react';
import './Products.scss'
import PersonalPathway from './Personal-Pathway/Personal-Pathway';


const Products = () => {

    return (
        <>
        <PersonalPathway />
        </>
    )
}

export default Products;