import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import "../../Signup/CreateAccount/CreateAccount.scss";
import logo from "../../../Assets/Logo/LOGO.svg";
import laulamaimage from "../../../Assets/Loginimage.svg";
import axios from "axios";
import { CONFIRMATION_URL, CONFIRMATION_URL_HEADERS, REFRESH_TOKEN_URL, RESEND_CONFIRMATION } from "../../../Utils/LoginService";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../../Shared-Component/ErrorMessage/ErrorMessage";
import { Messages } from 'primereact/messages';

const ConfirmationForm = () => {
  const [confirmCode, setConfirmCode] = useState("");
  const [error, setError] = useState("");
  const [showMessages, setShowMessages] = useState(false);
  const msgs = useRef(null);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setConfirmCode(e.target.value);
    setError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const confirmCodePattern = /^\d{6}$/;
    if (!confirmCode) {
      setError("Provide a confirmation code");
      return;
    }
    else if (!confirmCodePattern.test(confirmCode)) {
      setError("Invalid confirmation code. Please provide a 6-digit code.");
      return;
    }


    const request = {
      confirmationCode: confirmCode,
      mailId: localStorage.getItem("email"),
    }

    axios
      .post(CONFIRMATION_URL, request, { headers: CONFIRMATION_URL_HEADERS })
      .then((response) => {
        localStorage.setItem("userAccessToken", response.data.access_token);
        localStorage.setItem("userRefreshToken", response.data.refresh_token);
        localStorage.setItem("userId", response.data.user_id);
        localStorage.setItem("email", response.data.email_Id);
        localStorage.setItem("schoolId", response.data.school_id);
        localStorage.setItem("schoolName", response.data.school_name);

        let schoolName = response.data.school_name;

        if (schoolName === "Kapaa High School") {

          let redirection = response.data.user_type;

          switch (redirection) {
            case "Admin":
              window.location.href = "/admin/dashboard";
              break;
            case "Teacher":
              window.location.href = "/teacher/dashboard";
              break;
            case "Principal":
              window.location.href = "/principal/dashboard";
              break;
            case "Student":
              window.location.href = "/student/dashboard";
              break;
            default:
              break;
          }
        } else {

          let redirection = response.data.user_type;

          switch (redirection) {
            case "Admin":
              window.location.href = "/admin/dashboard";
              break;
            case "Teacher":
              window.location.href = "/teacher/dashboard";
              break;
            case "Principal":
              window.location.href = "/principal/dashboard";
              break;
            case "Student":
              window.location.href = "/student/dashboard";
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (error?.response?.data?.hasOwnProperty("message")) {
          const errorMessage = error.response.data.message;

          if (errorMessage === "Confirmation code doesn't match") {
            setError("Your confirmation code mismatched.");
          }
        }
        else {
          setError("Please double-check and try again.")
        }

      });
  }


  const handleResendCode = () => {
    const userName = localStorage.getItem("email")
    if (userName) {
      axios
        .get(RESEND_CONFIRMATION + userName, {})
        .then((response) => {
          setShowMessages(showMessages);
          msgs.current.show({ severity: 'info', detail: 'Your verification code has been resend to your email. Please verify that.' });
          setTimeout(() => {
            setShowMessages(!showMessages);
            setShowMessages('');
          }, 5000);
        })
        .catch((error) => {
          console.log(error?.response?.data?.message);
        });
    }
  };

  return (
    <div className="account-container">
      <div className="logo">
        <a href="/"><img src={logo} alt="" /></a>
      </div>
      <Card className="signup-card-section m-5">
        <div className="grid ml-0 mr-0">
          <div className="image-field-sec m-0 col-6">
            <img src={laulamaimage} alt="Laulama Image" />
          </div>
          <div className="form-container-section m-0 col-6 flex-auto">
            <div className="confirm m-5">
              {!showMessages && <Messages ref={msgs} className="noteMessages" />}
              <p>
                <b>Confirmation Code</b>
              </p>
              <div className="heading-content">
                <p>
                  Verify your email address by entering the code that was sent to
                  it
                </p>
                {error && <ErrorMessage message={error} isSuccess={true} />}
              </div>
              <div className="form-field grid">
                <div className="field col">
                  <label htmlFor="confirmcode">Confirm Code</label>
                  <InputText
                    maxLength={6}
                    keyfilter="int"
                    value={confirmCode}
                    placeholder="Enter the confirmation code"
                    onChange={handleInputChange}
                    className={error && !confirmCode ? "email-error" : ""}
                  />
                </div>
              </div>
              <div className="button-container-sec">
                <Button type="button" label="Confirm" onClick={handleSubmit} disabled={!confirmCode} />
                <div className='resend'><span onClick={handleResendCode}>Resend Code</span></div>
              </div>
            </div>
            
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ConfirmationForm;