import { Button } from "primereact/button";
import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import logoOpen from "../../../Assets/Logo/LOGO.svg";
import aboutUs from "../../../Assets/Menu/About Us/About-us.jpg";
import faq from "../../../Assets/Menu/About Us/FAQ.jpg";
import founder from "../../../Assets/Menu/About Us/Founder.jpg";
import history from "../../../Assets/Menu/About Us/History.jpg";
import ourPurpose from "../../../Assets/Menu/About Us/Our-purpose.jpg";
import strategicPartners from "../../../Assets/Menu/About Us/Strategic-partners.jpg";
import team from "../../../Assets/Menu/About Us/Team.jpg";
import DataAnalytics from "../../../Assets/Menu/Products/Data-analytics.jpg";
import Digitization from "../../../Assets/Menu/Products/Digitization-and-automation.jpg";
import PersonalPathway from "../../../Assets/Menu/Products/PersonalPathway.jpg";
import Modeling from "../../../Assets/Menu/Products/Modeling.jpg";
import Products from "../../../Assets/Menu/Products/Products.jpg";
import Strategic from "../../../Assets/Menu/Products/Strategic Planning.jpg";
import Technical from "../../../Assets/Menu/Products/Technical-support.jpg";
import WebPortal from "../../../Assets/Menu/Products/WebPortals.jpg";
import Articles from "../../../Assets/Menu/Stories/Articles.jpg";
import Media from "../../../Assets/Menu/Stories/Media.jpg";
import Stories from "../../../Assets/Menu/Stories/Stories.jpg";
import Testimonials from "../../../Assets/Menu/Stories/Testimonials.jpg";
import ChangeExperiments from "../../../Assets/Menu/System/Change-experiments-and-initiatives.jpg";
import ImprovementInnovation from "../../../Assets/Menu/System/Improvement-and-innovation-platform.jpg";
import ImprovementToolImage from "../../../Assets/Menu/System/Improvement-tools.jpg";
import SchoolNetwork from "../../../Assets/Menu/System/Laulama Schools Network.jpg";
import PriorityImage from "../../../Assets/Menu/System/Priority-areas.jpg";
import RedesignImage from "../../../Assets/Menu/System/Redesign-and-innovation.jpg";
import system from "../../../Assets/Menu/System/System.jpg";
import './NewHeader.scss';

const NewHeader = () => {

    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    const [hoveredItem, setHoveredItem] = useState(null);

    const handleMouseEnter = (itemName) => {
        setHoveredItem(itemName);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    const [show, setShow] = useState("Default System");

    const showContent = (itemName) => {
        setShow(itemName);
    };

    const hideContent = () => {
        setShow(null);
        setShow('Default System');
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleRedirect = () => {
        navigate('/login');
    }

    const renderCaretIcon = (itemName) => {
        return (
            <div className="icon">
                {hoveredItem === itemName ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
        );
    };

    const handleClick = (url) => {
        navigate('/system')
    };

    const menus = [
        {
            mainMenu: "Home",
            href: "/"
        },
        {
            mainMenu: "About Us",
            items: [
                { name: "Our Purpose", href: "/aboutus#OurPurpose" },
                { name: "History", href: "/aboutus#OurHistory" },
                { name: "Founder", href: "/aboutus#Founder" },
                { name: "Team", href: "/aboutus#teamMembers" },
                { name: "Strategic Partners", href: "/strategicPartners" },
                { name: "FAQ", href: "/aboutus#frequentlyAskedQuestions" }
            ],

        },
        {
            mainMenu: "System",
            items: [
                { name: "ADEPt Continuous Improvement Process", href: "/system#improvementInnovationPlatform" },
                { name: "Improvement Tools", href: "/system#improvementTools" },
                { name: "Laulama Schools Network", href: "/system#laulamaSchoolsNetwork" },
                { name: "Change Experiments & Initiatives", href: "/system#changeExperimentsAndInitiatives" },
                { name: "Priority Areas", href: "/system#priorityAreas" },
                { name: "Redesign and Innovation", href: "/system#redesignAndInnovation" }
            ]
        },
        {
            mainMenu: "Products",
            items: [
                { name: "Personal Pathway", href: "/products" },
                { name: "Modeling", href: "/modeling" },
                { name: "Data Analytics", href: "/dataanalytics" },
                // { name: "Digitization & Automation", href: "#" },
                // { name: "WebPortals", href: "#" },
                // { name: "Strategic Planning", href: "#" },
                // { name: "Technical Support", href: "#" }
            ]
        },
        {
            mainMenu: "Subscription",
            href: "/subscription"
        },
        {
            mainMenu: "Stories",
            items: [
                // { name: "Articles", href: "#" },
                { name: "Testimonials", href: "/stories#Testimonials" },
                // { name: "Media", href: "#" }
            ]
        },
        {
            mainMenu: "Contact Us",
            href: "/contactus"
        },
    ];

    const [showMenuItem, setShowMenuItem] = useState(true);
    const [showItems, setShowItems] = useState(null);

    const handleMenuClick = (menu) => {
        setShowMenuItem(false);
        setShowItems(menu);
    };

    const handleBackClick = () => {
        setShowMenuItem(true);
        setShowItems(null);
    };

    const ourPuposeConstent = (
        <div className="row">
            <div>
                <header>Empowering Schools and Students</header>
                <p>We are driven by our purpose to create breakthroughs that transform schools for its leaders, educators and students.</p>
                <img src={ourPurpose} alt="" />
            </div>
        </div>
    )

    const historyContent = (
        <div className="row">
            <div>
                <header>Laying a Legacy of Service, Progress and Impact</header>
                <p>Hear about Laulama Learnings journey over the past 13 years</p>
                <img src={history} alt="" style={{ objectPosition: 'center' }} />
            </div>
        </div>
    )

    const founderContent = (
        <div className="row">
            <div>
                <header>Horatio Alger Tale</header>
                <p>Striving for and applying one’s highest potential for a greater purpose.</p>
                <img src={founder} alt="" style={{ objectPosition: 'center' }} />
            </div>
        </div>
    )

    const teamContent = (
        <div className="row">
            <div>
                <header>Diverse Talent & Experience</header>
                <p>We are bridging time and space to produce the best outcomes for the schools and partners we serve.</p>
                <img src={team} alt="" />
            </div>
        </div>
    )

    const strategicContent = (
        <div className="row">
            <div>
                <header>Engaging our School Communities</header>
                <p>We are building ways to productively engage with members of our school communities.</p>
                <img src={strategicPartners} alt="" />
            </div>
        </div>
    )

    const faqContent = (
        <div className="row">
            <div>
                <header>Frequently Asked Questions</header>
                <p>Answer the popular questions</p>
                <img src={faq} alt="" />
            </div>
        </div>
    )

    const ADEPtContinuousImprovementProcessContent = (
        <div className="row">
            <div>
                <header>Developing a Modernized Improvement System</header>
                <p>We are working to modernize education’s improvement system for breakthroughs.</p>
                <img src={ImprovementInnovation} alt="" />
            </div>
        </div>
    )

    const ImprovementToolsContent = (
        <div className="row">
            <div>
                <header>Systemic, Systematic and Sustainable Change</header>
                <p>We identify and adapt widely-recognized improvement concepts, methods and tools for education.</p>
                <img src={ImprovementToolImage} alt="" />
            </div>
        </div>
    )

    const LaulamaSchoolNetworkContent = (
        <div className="row">
            <div>
                <header>Accelerating & Scaling Improvement</header>
                <p>Collaboration through a shared understanding of school designs, problems and theories of improvement.</p>
                <img src={SchoolNetwork} alt="" />
            </div>
        </div>
    )

    const ChangeExperimentsInitiatives = (
        <div className="row">
            <div>
                <header>Good Practice Sharing Guide</header>
                <p>We seek to contribute to the creation and sharing of knowledge.</p>
                <img src={ChangeExperiments} alt="" />
            </div>
        </div>
    )

    const PriorityAreas = (
        <div className="row">
            <div>
                <header>Impact through Optimization</header>
                <p>We work across schools to streamline duplicate efforts around areas of high impact & need.</p>
                <img src={PriorityImage} alt="" />
            </div>
        </div>
    )

    const RedesignInnovativeContent = (
        <div className="row">
            <div>
                <header>Impact through Innovation</header>
                <p>We support educators in the creation of innovative approaches to areas of high impact and need.</p>
                <img src={RedesignImage} alt="" />
            </div>
        </div>
    )
    const PersonalPathwayContent = (
        <div className="row">
            <div>
                <header>Personalizing Education</header>
                <p>We are working with schools to make personalized education for all students a reality.</p>
                <img src={PersonalPathway} alt="" />
            </div>
        </div>
    )

    const ModelingContent = (
        <div className="row">
            <div>
                <header>Clarity and Shared Understanding</header>
                <p>We create and use visual models that make complex things clearer and easier to understand, and for effective collaboration.</p>
                <img src={Modeling} alt="" />
            </div>
        </div>
    )

    const DataAnalyticsContent = (
        <div className="row">
            <div>
                <header>Synthesizing Data for Intelligence</header>
                <p>We help schools to elevate their use of data.</p>
                <img src={DataAnalytics} alt="" />
            </div>
        </div>
    )

    const DigitizationAutomationContent = (
        <div className="row">
            <div>
                <header>Making Data Work for Schools</header>
                <p>We use data and technology to streamline school workflows.</p>
                <img src={Digitization} alt="" />
            </div>
        </div>
    )

    const WebportalsContent = (
        <div className="row">
            <div>
                <header>Enhancing Connections & Interactions</header>
                <p>Laulama Schools include individualized webportals for schools that
                    transform how educators and students access information and engage with each other.</p>
                <img src={WebPortal} alt="" />
            </div>
        </div>
    )

    const StrategicPlanningContent = (
        <div className="row">
            <div>
                <header>Progress & Sustainability through Consistency</header>
                <p>We improve the outcomes of strategic planning by streamlining the end-to-end processs,
                    from continuous improvement activities to strategy formation, implementation and compliance reporting</p>
                <img src={Strategic} alt="" />
            </div>
        </div>
    )

    const TechnicalSupportContent = (
        <div className="row">
            <div>
                <header>Personalized Technical Support</header>
                <p>We work to constantly adapt our technical support based on experiences working with schools in the field</p>
                <img src={Technical} alt="" style={{ objectPosition: 'center' }} />
            </div>
        </div>
    )

    // const ArticleContent = (
    //     <div className="row">
    //         <div>
    //             <header>Experiencing our Work</header>
    //             <p>Become aware, gain a deeper appreciation through shared knowledge and stories.</p>
    //             <img src={Articles} alt="" />
    //         </div>
    //     </div>
    // )

    const TestimonialsContent = (
        <div className="row">
            <div>
                <header>Stakeholder Feedback</header>
                <p>Hear how stakeholders feel about our work.</p>
                <img src={Testimonials} alt="" />
            </div>
        </div>
    )

    // const MediaContent = (
    //     <div className="row">
    //         <div>
    //             <header>In the Media</header>
    //             <p>Select recent and past articles that were published.</p>
    //             <img src={Media} alt="" />
    //         </div>
    //     </div>

    // )

    return (
        <>
            <div className="head">
                <nav className="navigation">
                    <div className="wrapper">
                        <div className="logo">
                            <a href="/"><img src={logoOpen} alt="" /></a>
                        </div>

                        <div
                            className={`menu-toggle ${showMenu ? "active" : ""}`}
                            onClick={toggleMenu}>
                            <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                            <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                            <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                        </div>

                        <div className={`menu ${showMenu ? "show-menu" : ""}`}>

                            <ul className="nav-links">

                                <li className={location.pathname === '/' ? 'active-menu-item' : ''}>
                                    <a href="/">Home</a>
                                </li>

                                <li className={`${location.pathname === '/aboutus' ? 'active-menu-item' : ''}`}
                                    onMouseEnter={() => handleMouseEnter('About Us')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <a href="/aboutus">About Us {renderCaretIcon('About Us')}</a>

                                    <div className="aboutus-submenus">
                                        <div className="content">

                                            <div className="row">

                                                <ul className="mega-links">
                                                    <li onMouseEnter={() => showContent('Our Purpose')} onMouseLeave={hideContent}>
                                                        <a href="/aboutus#OurPurpose">Our Purpose</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('History')} onMouseLeave={hideContent}>
                                                        <a href="/aboutus#OurHistory">History</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Founder')} onMouseLeave={hideContent}>
                                                        <a href="/aboutus#Founder">Founder</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Team')} onMouseLeave={hideContent}>
                                                        <a href="/aboutus#teamMembers">Team</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Strategic Partners')} onMouseLeave={hideContent}>
                                                        <a href="/aboutus#strategicPartners">Strategic Partners</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('FAQ')} onMouseLeave={hideContent}>
                                                        <a href="/aboutus#frequentlyAskedQuestions">Frequently Asked Questions</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            {show === 'Default System' && (
                                                <div className="row">
                                                    <div>
                                                        <header>Purpose-Driven Team</header>
                                                        <p>Harnessing the power of purpose, people, processes and platforms</p>
                                                        <img src={aboutUs} alt="" />
                                                    </div>
                                                </div>
                                            )}

                                            {show === 'Our Purpose' && (ourPuposeConstent)}
                                            {show === 'History' && (historyContent)}
                                            {show === 'Founder' && (founderContent)}
                                            {show === 'Team' && (teamContent)}
                                            {show === 'Strategic Partners' && (strategicContent)}
                                            {show === 'FAQ' && (faqContent)}

                                        </div>
                                    </div>
                                </li>

                                <li className={location.pathname === '/system' ? 'active-menu-item' : ''}
                                    onMouseEnter={() => handleMouseEnter('System')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <a href="/system">System {renderCaretIcon('System')}</a>

                                    <div className="system-submenus">
                                        <div className="content">

                                            <div className="row">

                                                <ul className="mega-links">
                                                    <li onMouseEnter={() => showContent('ADEPt Continuous Improvement Process')} onMouseLeave={hideContent}>
                                                        <a href="/system#improvementInnovationPlatform">ADE<span style={{ color: "red" , fontSize:"22px" }}>P</span>t Continuous Improvement Process</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Improvement Tools')} onMouseLeave={hideContent}>
                                                        <a href="/system#improvementTools">Improvement Tools</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Laulama Schools Network')} onMouseLeave={hideContent} onClick={handleClick}>
                                                        <a href="/system#laulamaSchoolsNetwork">Laulama Schools Network</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Change Experiments & Initiatives')} onMouseLeave={hideContent}>
                                                        <a href="/system#changeExperimentsAndInitiatives">Change Experiments & Initiatives</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Priority Areas')} onMouseLeave={hideContent}>
                                                        <a href="/system#priorityAreas">Priority Areas</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Redesign and Innovation')} onMouseLeave={hideContent}>
                                                        <a href="/system#redesignAndInnovation">Redesign and Innovation</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            {show === 'Default System' && (
                                                <div className="row">
                                                    <div>
                                                        <header>Innovating Education’s Improvement System</header>
                                                        <p>Increasing school improvement capacity and ability to thrive in complexity.</p>
                                                        <img src={system} alt="" />
                                                    </div>
                                                </div>
                                            )}

                                            {show === 'ADEPt Continuous Improvement Process' && (ADEPtContinuousImprovementProcessContent)}
                                            {show === 'Improvement Tools' && (ImprovementToolsContent)}
                                            {show === 'Laulama Schools Network' && (LaulamaSchoolNetworkContent)}
                                            {show === 'Change Experiments & Initiatives' && (ChangeExperimentsInitiatives)}
                                            {show === 'Priority Areas' && (PriorityAreas)}
                                            {show === 'Redesign and Innovation' && (RedesignInnovativeContent)}

                                        </div>
                                    </div>
                                </li>

                                <li className={location.pathname === '/products' ? 'active-menu-item' : ''}
                                    onMouseEnter={() => handleMouseEnter('Products')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <a href="/products">Products {renderCaretIcon('Products')}</a>

                                    <div className="product-submenus">
                                        <div className="content">

                                            <div className="row">

                                                <ul className="mega-links">
                                                <li onMouseEnter={() => showContent('Personal Pathway')} onMouseLeave={hideContent}>
                                                        <a href="/products">Personal Pathway</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Modeling')} onMouseLeave={hideContent}>
                                                        <a href="/modeling">Modeling</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Data Analytics')} onMouseLeave={hideContent}>
                                                        <a href="/dataanalytics">Data Analytics</a>
                                                    </li>
                                                    {/* <li onMouseEnter={() => showContent('Digitization & Automation')} onMouseLeave={hideContent}>
                                                        <a href="#">Digitization & Automation</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('WebPortals')} onMouseLeave={hideContent}>
                                                        <a href="#">WebPortals</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Strategic Planning')} onMouseLeave={hideContent}>
                                                        <a href="#">Strategic Planning</a>
                                                    </li>
                                                    <li onMouseEnter={() => showContent('Technical Support')} onMouseLeave={hideContent}>
                                                        <a href="#">Technical Support</a>
                                                    </li> */}
                                                </ul>
                                            </div>

                                            {show === 'Default System' && (
                                                <div className="row">
                                                    <div>
                                                        <header>Enabling Empowerment</header>
                                                        <p>A new kind of accountability based on a true ownership of one’s school design</p>
                                                        <img src={Products} alt="" />
                                                    </div>
                                                </div>
                                            )}

                                            {show === 'Personal Pathway' && (PersonalPathwayContent)}
                                            {show === 'Modeling' && (ModelingContent)}
                                            {show === 'Data Analytics' && (DataAnalyticsContent)}
                                            {show === 'Digitization & Automation' && (DigitizationAutomationContent)}
                                            {show === 'WebPortals' && (WebportalsContent)}
                                            {show === 'Strategic Planning' && (StrategicPlanningContent)}
                                            {show === 'Technical Support' && (TechnicalSupportContent)}

                                        </div>
                                    </div>
                                </li>

                                <li className={location.pathname === '/subscription' ? 'active-menu-item' : ''}>
                                    <a href="/subscription">Subscription</a>
                                </li>

                                <li className={location.pathname === '/stories' ? 'active-menu-item' : ''}
                                    onMouseEnter={() => handleMouseEnter('Stories')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <a href="/stories">Stories {renderCaretIcon('Stories')}</a>

                                    <div className="stories-submenus">
                                        <div className="content">

                                            <div className="row">

                                                <ul className="mega-links">
                                                    {/* <li onMouseEnter={() => showContent('Articles')} onMouseLeave={hideContent}>
                                                        <a href="#">Articles</a>
                                                    </li> */}
                                                    <li onMouseEnter={() => showContent('Testimonials')} onMouseLeave={hideContent}>
                                                        <a href="/stories#Testimonials">Testimonials</a>
                                                    </li>
                                                    {/* <li onMouseEnter={() => showContent('Media')} onMouseLeave={hideContent}>
                                                        <a href="#">Media</a>
                                                    </li> */}
                                                </ul>
                                            </div>

                                            {show === 'Default System' && (
                                                <div className="row">
                                                    <div>
                                                        <header>Transforming Schools, Impacting Lives</header>
                                                        <p>Read about our work and how it is transforming schools and changing lives.</p>
                                                        <img src={Stories} alt="" />
                                                    </div>
                                                </div>
                                            )}

                                            {/* {show === 'Articles' && (ArticleContent)} */}
                                            {show === 'Testimonials' && (TestimonialsContent)}
                                            {/* {show === 'Media' && (MediaContent)} */}

                                        </div>
                                    </div>
                                </li>

                                <li className={location.pathname === '/contactus' ? 'active-menu-item' : ''}>
                                    <a href="/contactus">Contact Us</a>
                                </li>

                                <li>
                                    <Button onClick={handleRedirect}>Log In</Button>
                                </li>
                            </ul>
                        </div>

                        {window.innerWidth <= 1220 && (
                            <div className={`menu ${showMenu ? "show-menu" : ""}`}>

                                <ul className="nav-links mobile">

                                    {showMenuItem && menus.map((menu, index) => (
                                        menu && (
                                            <>
                                                <li key={index} onClick={() => handleMenuClick(menu)}>
                                                    <a href={menu.items ? "#" : menu.href} {...(menu.items ? { responsive: "true" } : {})}>
                                                        {menu.mainMenu} {menu.items && <FaArrowRight />}
                                                    </a>
                                                </li>
                                            </>
                                        )
                                    ))}

                                    {showMenuItem && (
                                        <li style={{ paddingLeft: "15px" }}>
                                            <Button onClick={handleRedirect}>Log In</Button>
                                        </li>
                                    )}

                                    {showItems && showItems.items && (
                                        <div className="submenuItems">
                                            <button onClick={handleBackClick}>
                                                <FaArrowLeft /> Back
                                            </button>
                                            
                                            <ul>
                                                {showItems.items.map((item, index) => (
                                                    <li key={index}>
                                                        <a href={item.href}>{item.name}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                </ul>
                            </div>
                        )}
                    </div>
                </nav>
            </div>
        </>

    );
};

export default NewHeader;
