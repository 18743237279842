import React, { useState } from 'react'
import NavBar from '../../../../../Shared-Component/StackNavBar/StackNavBar';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { Button } from 'primereact/button';
import TestScoresForm from '../../StudentForm/Test-Scores-Form/TestScoresForm';

export const MobileTestScoreForm = () => {
    const userRole = 'Student';
    const [activeMenuItem, setActiveMenuItem] = useState('dashboard');

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

    };

    return (
        <div className="mob-extraactivities-section">

            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />

            <div className='m-5 '>
                <div className='mob-extraactivities-link' >
                    <Link to="/student/dashboard"> <FaChevronLeft /> Back To Dashboard</Link>
                </div>


                <div className='mob-extraactivities-button mt-3 mb-3' >
                    <Button className='Button'>6. Test Score </Button>
                </div>

                <TestScoresForm />

            </div>

        </div>
    );
};