import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import './Studentprofile.scss';
import axios from 'axios';
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { useNavigate } from 'react-router-dom';
import { InputMask } from 'primereact/inputmask';
import { GET_STUDENT_DETAILS, CONTENT_HEADERS, CONTENT_MULTIPART_HEADERS, UPDATE_PROFILE_URL, GET_RESUME_URL, AUTHORIZATION_HEADERS } from '../../../Utils/StudentService';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';
import { Calendar } from 'primereact/calendar';
import { FaRegTrashAlt } from 'react-icons/fa';
import maleAvatar from "../../../Assets/Avatar/StudentProfile.png";
import femaleAvatar from "../../../Assets/Avatar/TeacherProfile.png";
import { Accordion, AccordionTab } from 'primereact/accordion';
import MobilePTPGoals from '../MobilePTPGoals';

const StudentProfile = () => {
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('profile');
    const [studentDetails, setStudentDetails] = useState({
        email: '',
        phone: '',
        dob: '',
        schoolName: '',
        grade: null,
        gpa: '',
        street: '',
        city: '',
        zipcode: ''
    });
    const gradeOptions = ["Grade 9", "Grade 10", "Grade 11", "Grade 12"];
    const [editMode, setEditMode] = useState(false);
    const [resumeName, setResumeName] = useState('');

    const [loading, setLoading] = useState(false);
    const [getResume, setGetResume] = useState(null);
    const [showUploader, setShowUploader] = useState(false);

    useEffect(() => {
        fetchDetails();
        fetchResume();
    }, []);

    const fetchDetails = () => {
        setLoading(true);
        axios.get(GET_STUDENT_DETAILS, { headers: CONTENT_HEADERS })
            .then(response => {
                const data = response.data;
                const date = new Date(data?.dob);
                setStudentDetails({
                    email: data.emailId,
                    phoneNumber: data.phoneNumber,
                    dob: date,
                    schoolName: data.schools.schoolName,
                    grade: data.groups[0]?.grade,
                    gpa: data.highSchoolGap,
                    street: data.address?.addressLine1,
                    city: data.address?.city,
                    zipcode: data.address?.postalCode,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    registrationId: data.registrationId,
                    gender: data.gender,

                });
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const getAvatarForGender = () => {
        if (studentDetails?.gender === "Male" || studentDetails?.gender === "M") {
            return <img src={maleAvatar} alt="Male Avatar" width={120} />;
        } else if (studentDetails?.gender === "Female" || studentDetails?.gender === "F") {
            return <img src={femaleAvatar} alt="Female Avatar" width={120} />;
        } else {
            return <Avatar icon="pi pi-user" size="xlarge" shape="circle" />;
        }
    };

    const fetchResume = () => {
        const userId = localStorage.getItem('userId');
        axios
            .get(GET_RESUME_URL + userId, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {
                if (response.data !== "resume not found !") {
                    setGetResume(response.data);
                }
            })
    }

    const handleInputChange = (name, value) => {
        setStudentDetails(prevDetails => {
            const updatedDetails = { ...prevDetails, [name]: value };
            return updatedDetails;
        });
    };

    const handleSubmit = () => {
        setEditMode(false);
        setLoading(true);

        const dob = studentDetails.dob;
        const dobDate = new Date(dob);
        const day = dobDate.getDate();
        const month = (dobDate.getMonth() + 1).toString().padStart(2, '0');
        const year = dobDate.getFullYear();
        const formatedDobDate = `${year}-${month}-${day}`;

        const formData = new FormData();

        const updatedprofileData = {
            emailId: studentDetails.email,
            registrationId: studentDetails.registrationId,
            grade: studentDetails.grade,
            firstName: studentDetails.firstName,
            middleName: studentDetails.middleName,
            lastName: studentDetails.lastName,
            phoneNumber: studentDetails.phoneNumber,
            address: {
                addressLine1: studentDetails.street,
                city: studentDetails.city,
                postalCode: studentDetails.zipcode,
            },
            // dob: formatedDobDate,
            highSchoolGap: studentDetails.gpa,
        };

        formData.append('json', JSON.stringify(updatedprofileData))
        formData.append('file', selectedFile);

        axios
            .patch(UPDATE_PROFILE_URL, formData, { headers: CONTENT_MULTIPART_HEADERS })
            .then(() => {
                navigate("/student/profile");
                setLoading(false);
                setShowUploader(false);
                fetchResume();
            })
            .catch((error) => {
                console.log("API Error:", error)
            })

    };




    const onUpload = e => {
        const file = e.files[0];
        setSelectedFile(file);
        setIsUploadEnabled(true);
        setResumeName(e.files[0].name);
    };

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
        navigate(`/student/${item}`);
    };

    const handleResumeGenerator = () => {
        navigate("/student/resumebuilder")
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploadEnabled, setIsUploadEnabled] = useState(false);

    const handleCancel = (event) => {
        event.preventDefault();
        setSelectedFile(null);
        setShowUploader(false)
    };

    const isTabOpen = (index) => activeIndex === index;
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div className="student-profile">
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>

            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole="Student"
            />
            <div className="card desktop-profile-view">
                <div className="header">
                    {getAvatarForGender()}
                    <div className="user-details">
                        <h1 className="name">{studentDetails.firstName} {studentDetails.lastName}</h1>
                        <h2 className="id">(ID:{studentDetails.registrationId})</h2>
                    </div>
                </div>

                <div className="forms-section">

                    <h2>Personal Information
                        {editMode ? (

                            ""
                        ) : (
                            <Button icon="pi pi-pencil" onClick={() => setEditMode(true)} className="edit-button" label="Edit" />
                        )}
                    </h2>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <InputText id="email" value={studentDetails.email} onChange={(e) => handleInputChange('email', e.target.value)} disabled={!editMode} readOnly />
                            </div>
                            <div className="form-group">
                                <label htmlFor="schoolName">School Name</label>
                                <InputText id="schoolName" value={studentDetails.schoolName} onChange={(e) => handleInputChange('schoolName', e.target.value)} disabled={!editMode} readOnly />
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="dob">Date of Birth</label>
                                <Calendar id="dob" type="date" value={studentDetails.dob} onChange={(e) => handleInputChange('dob', e.target.value)} disabled={!editMode} className="p-inputtext-sm w-full" />
                            </div> */}
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>Phone Number</label>
                                <InputMask
                                    mask="(999) 999-9999"
                                    name="phoneNumber"
                                    value={studentDetails.phoneNumber}
                                    onChange={(e) => { handleInputChange('phoneNumber', e.target.value) }}
                                    disabled={!editMode}
                                    className="p-inputtext-sm w-full"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="grade">Grade</label>
                                <Dropdown id="grade" value={studentDetails.grade} options={gradeOptions} onChange={(e) => handleInputChange('grade', e.value)} disabled={!editMode} className="grade-dropdown full-width" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="gpa">High School GPA</label>
                                <InputText id="gpa" type="number" value={studentDetails.gpa} onChange={(e) => handleInputChange('gpa', e.target.value)} disabled={!editMode} />
                            </div>
                        </div>

                        <h2 className="address-header">Address</h2>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="street">Street</label>
                                <InputText id="street" value={studentDetails.street} onChange={(e) => handleInputChange('street', e.target.value)} disabled={!editMode} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <InputText id="city" value={studentDetails.city} onChange={(e) => handleInputChange('city', e.target.value)} disabled={!editMode} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="zipcode">Zip Code</label>
                                <InputText id="zipcode" value={studentDetails.zipcode} onChange={(e) => handleInputChange('zipcode', e.target.value)} disabled={!editMode} />
                            </div>
                        </div>

                        {editMode && <Button type="submit" label="Save" onClick={handleSubmit} className="p-mt-2 save-button" />}
                    </form>
                </div>

                <div className="documents-section">
                    <h2>Documents</h2>
                    <p>You can generate your resume using the Laulama provided design or you can upload your own design of your resume.</p>

                    <div className="upload-section">
                        {getResume ? (
                            <div className='show-resume flex gap-2'>
                                <a href={`data:application/pdf;base64,${getResume}`} download={`${studentDetails.firstName} Resume.pdf`} style={{ textDecoration: "none", color: "#1067B8" }}>
                                    {`${studentDetails.firstName}_${studentDetails.lastName}_Resume.pdf`}
                                </a>
                                <FaRegTrashAlt style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => { setShowUploader(false); setGetResume(false) }} />
                            </div>
                        ) : (
                            !showUploader && (
                                <>
                                    <Button onClick={() => setShowUploader(true)} className='m-2'>
                                        Add My Resume
                                    </Button>
                                    <Button
                                        label="Generate My Resume"
                                        className="p-button-outlined generate-button m-2"
                                        onClick={handleResumeGenerator}
                                    />
                                </>
                            )
                        )}
                        {showUploader && (
                            <div className="principal-page-left flex">
                                <FileUpload
                                    chooseLabel="Choose File"
                                    uploadLabel="Upload"
                                    value={''}
                                    onSelect={onUpload}
                                    mode="basic"
                                    accept=".pdf"
                                    className='m-2'
                                />
                                <div className="flex gap-4">
                                    <Button onClick={handleCancel} className='gap-2 p-button-secondary m-2'>
                                        <i className="pi pi-times m-1"></i>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmit} disabled={!isUploadEnabled} className='m-2'>
                                        <i className="pi pi-upload m-1"></i>
                                        Upload File
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="mobile-profile-view">
                <h2>My Profile</h2>
                <div>
                    <div>
                        <Accordion
                            activeIndex={activeIndex}
                            onTabChange={(e) => toggleAccordion(e.index)}
                        >
                            <AccordionTab header={
                                <div className="accordion-header">
                                    <div className="header-text">Personal Information</div>
                                    <div className="header-icon">
                                        <i className={`pi ${isTabOpen(0) ? "pi-minus" : "pi-plus"}`}></i>
                                    </div>
                                </div>
                            }>
                                <div className="mob-view-PI">
                                    <div className="edit-button">
                                        {editMode ? (
                                            ""
                                        ) : (
                                            <a onClick={() => setEditMode(true)} className="edit-a">Edit</a>
                                        )}
                                    </div>
                                    <div className="header">
                                        <div className="user-details">
                                            <h1 className="name">{studentDetails.firstName} {studentDetails.lastName}</h1>
                                            <h2 className="id">(ID:{studentDetails.registrationId})</h2>
                                        </div>
                                    </div>

                                    <div className="forms-section">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <InputText id="email" value={studentDetails.email} onChange={(e) => handleInputChange('email', e.target.value)} disabled={!editMode} readOnly />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="schoolName">School Name</label>
                                                    <InputText id="schoolName" value={studentDetails.schoolName} onChange={(e) => handleInputChange('schoolName', e.target.value)} disabled={!editMode} readOnly />
                                                </div>
                                                {/* <div className="form-group">
                                                    <label htmlFor="dob">Date of Birth</label>
                                                    <Calendar id="dob" type="date" value={studentDetails.dob} onChange={(e) => handleInputChange('dob', e.target.value)} disabled={!editMode} className="p-inputtext-sm w-full" />
                                                </div> */}
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <InputMask
                                                        mask="(999) 999-9999"
                                                        name="phoneNumber"
                                                        value={studentDetails.phoneNumber}
                                                        onChange={(e) => { handleInputChange('phoneNumber', e.target.value) }}
                                                        disabled={!editMode}
                                                        className="p-inputtext-sm w-full"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="grade">Grade</label>
                                                    <Dropdown id="grade" value={studentDetails.grade} options={gradeOptions} onChange={(e) => handleInputChange('grade', e.value)} disabled={!editMode} className="grade-dropdown full-width" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="gpa">High School GPA</label>
                                                    <InputText id="gpa" type="number" value={studentDetails.gpa} onChange={(e) => handleInputChange('gpa', e.target.value)} disabled={!editMode} />
                                                </div>
                                            </div>

                                            <h3 className="address-header m-0">Address</h3>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label htmlFor="street">Street</label>
                                                    <InputText id="street" value={studentDetails.street} onChange={(e) => handleInputChange('street', e.target.value)} disabled={!editMode} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="city">City</label>
                                                    <InputText id="city" value={studentDetails.city} onChange={(e) => handleInputChange('city', e.target.value)} disabled={!editMode} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="zipcode">Zip Code</label>
                                                    <InputText id="zipcode" value={studentDetails.zipcode} onChange={(e) => handleInputChange('zipcode', e.target.value)} disabled={!editMode} />
                                                </div>
                                            </div>

                                            {editMode && <Button type="submit" label="Save" onClick={handleSubmit} className="p-mt-2 save-button" />}
                                        </form>
                                    </div>
                                </div>
                            </AccordionTab>

                            <AccordionTab header={
                                <div className="accordion-header">
                                    <div className="header-text">Documents</div>
                                    <div className="header-icon">
                                        <i className={`pi ${isTabOpen(1) ? "pi-minus" : "pi-plus"}`}></i>
                                    </div>
                                </div>
                            }>
                                <div className="mobile-view-doc">
                                    <div className="documents-section">
                                        <p>You can generate your resume using the Laulama provided design or you can upload your own design of your resume.</p>

                                        <div className="upload-section">
                                            {getResume ? (
                                                <div className='show-resume flex gap-2'>
                                                    <a href={`data:application/pdf;base64,${getResume}`} download={`${studentDetails.firstName} Resume.pdf`} style={{ textDecoration: "none", color: "#1067B8" }}>
                                                        {`${studentDetails.firstName}_${studentDetails.lastName}_Resume.pdf`}
                                                    </a>
                                                    <FaRegTrashAlt style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => { setShowUploader(false); setGetResume(false) }} />
                                                </div>
                                            ) : (
                                                !showUploader && (
                                                    <>
                                                        <Button
                                                            onClick={() => setShowUploader(true)}
                                                            label="Add My Resume"
                                                        />
                                                        <Button
                                                            label="Generate My Resume"
                                                            className="p-button-outlined generate-button"
                                                            onClick={handleResumeGenerator}
                                                        />
                                                    </>
                                                )
                                            )}
                                            {showUploader && (
                                                <div className="principal-page-left flex">
                                                    <FileUpload
                                                        chooseLabel="Choose File"
                                                        uploadLabel="Upload"
                                                        value={''}
                                                        onSelect={onUpload}
                                                        mode="basic"
                                                        accept=".pdf"
                                                        className='m-2'
                                                    />

                                                    <Button onClick={handleSubmit} disabled={!isUploadEnabled} className='gap-2 m-2'>
                                                        <i className="pi pi-upload m-1"></i>
                                                        Upload File
                                                    </Button>

                                                    <Button onClick={handleCancel} className='gap-2 p-button-secondary m-2'>
                                                        <i className="pi pi-times m-1"></i>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>

                            <AccordionTab header={
                                <div className="accordion-header">
                                    <div className="header-text">My PTP Goals</div>
                                    <div className="header-icon">
                                        <i className={`pi ${isTabOpen(1) ? "pi-minus" : "pi-plus"}`}></i>
                                    </div>
                                </div>
                            }>
                                <div className="">
                                    <MobilePTPGoals />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentProfile;