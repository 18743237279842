import React, { useEffect, useState } from 'react';
import '../Help.scss';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faFile, faFileVideo, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import NavBar from '../../StackNavBar/StackNavBar';
import SubCategory from '../Category/Category';

const Resume = () => {
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const [userRole, setUserRoles] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Resume');
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        const currentUrl = window.location.pathname;

        if (currentUrl.includes('/admin')) {
            handleAdminMenuClick(activeMenuItem);
            setUserRoles("Admin")
        } else if (currentUrl.includes('/principal')) {
            handlePrincipalMenuClick(activeMenuItem);
            setUserRoles("Principal")
        } else if (currentUrl.includes('/teacher')) {
            handleTeacherMenuClick(activeMenuItem);
            setUserRoles("Teacher")
        } else if (currentUrl.includes('/student')) {
            handleStudentMenuClick(activeMenuItem);
            setUserRoles("Student")
        }

    }, [activeMenuItem]);

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
    };

    const handleAdminMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case "dashboard":
                navigate("/admin/dashboard");
                break;
            case "principals":
                navigate("/admin/principals");
                break;
            case "teachers":
                navigate("/admin/teachers");
                break;
            case "groups":
                navigate("/admin/groups");
                break;
            case "students":
                navigate("/admin/students");
                break;
            default:
                break;
        }
    };

    const handlePrincipalMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/principal/dashboard');
                break;
            case 'groups':
                navigate('/principal/groups');
                break;
            case 'students':
                navigate('/principal/students');
                break;
            default:
                break;
        }
    }

    const handleTeacherMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/teacher/dashboard');
                break;
            case 'students':
                navigate('/teacher/studentsList');
                break;
            default:
                break;
        }
    };

    const handleStudentMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/student/dashboard');
                break;
            case "profile":
                navigate("/student/profile");
                break;
            default:
                break;
        }
    }
    return (
        <>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />
            <div className='help-section'>
                <div className='help-heading'>
                    <h3>Help Section</h3>
                </div>
                <div className='help-sub-section'>
                    <div className='m-5 flex flex-wrap'>
                        <div className='col-3 category-section border-right-3'>
                            <h3 className='mb-6'><b>By Category</b></h3>
                            <SubCategory selectedCategory={selectedCategory} handleCategoryClick={handleCategoryClick} />
                        </div>
                        <div className='col-9 guide-section-details pl-5'>
                            {selectedCategory === 'Resume' && (
                                <div className='resume'>
                                    <h2 className='mb-6'>Resume</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails mb-3 mr-3'>
                                            <div className='img flex align-items-center justify-content-evenly'>
                                                <FontAwesomeIcon icon={faFile} className='file mr-3' /><h3>Resume Guide</h3>
                                            </div>
                                            <div className='heading p-1'>Crafting a professional summary of your achievements and skills.</div>
                                            <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faFileVideo} className='mr-3' />
                                                <a href="https://drive.google.com/file/d/1XljFQ2ssj0cMMOsjRpH0_LWGUAc_qLOt/view?t=143" target="_blank">Open Video</a>
                                            </div>
                                        </div>
                                        <div className='guidesdetails mb-3 mr-3'>
                                            <div className='img flex align-items-center justify-content-evenly'>
                                                <FontAwesomeIcon icon={faPaperclip} className='clip-1 mr-3' /><h3>Resume Template Example</h3>
                                            </div>
                                            <div className='heading p-1'>Explore additional resume examples for inspiration.</div>
                                            <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-3' />
                                                <a href="https://create.microsoft.com/en-us/template/restaurant-manager-resume-57cae682-222c-4646-9a80-c404ee5c5d7e" target='_blank'>External Link</a>
                                            </div>
                                        </div>
                                        <div className='guidesdetails mb-3 mr-3'>
                                            <div className='img flex align-items-center justify-content-evenly'>
                                                <FontAwesomeIcon icon={faPaperclip} className='clip-2 mr-3' /><h3>Resume Template Example 2</h3>
                                            </div>
                                            <div className='heading p-1'>Document showcasing examples.</div>
                                            <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-3' />
                                                <a href="https://create.microsoft.com/en-us/template/project-management-resume-5ff67a28-3747-45d8-ab40-465bf569783b" target='_blank'>External Link</a>
                                            </div>
                                        </div>
                                        <div className='guidesdetails mb-3 mr-3'>
                                            <div className='img flex align-items-center justify-content-evenly'>
                                                <FontAwesomeIcon icon={faPaperclip} className='clip-3 mr-3' /><h3>Resume Template Example 3</h3>
                                            </div>
                                            <div className='heading p-1'>Document showcasing examples.</div>
                                            <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-3' />
                                                <a href="https://create.microsoft.com/en-us/template/basic-sales-resume-7af668a8-d9d4-4a54-989e-422a9b87280f" target='_blank'>External Link</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Resume;
