import React, { useEffect, useRef } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import PropTypes from "prop-types";
import "./CustomFilter.scss";

const CustomFilter = ({
  checkboxes,
  checkedItems,
  onCheckboxChange,
  onApplyFilter,
}) => {
  const checkboxContainerRef = useRef(null);
  useEffect(() => {
    if (checkboxes.length > 7 && checkboxContainerRef.current) {
      checkboxContainerRef.current.style.overflowY = "scroll";
      checkboxContainerRef.current.style.maxHeight = "300px";
    }
  }, [checkboxes]);

  return (
    <div className="checkbox-container">
      <div className="cb-group mb-2" ref={checkboxContainerRef}>
        {checkboxes.map((checkbox, index) => (
          <div key={index} className="checkbox-field">
            <Checkbox
              className="m-2"
              inputId={checkbox.value}
              value={checkbox.value}
              checked={checkedItems[checkbox.value]}
              onChange={() => onCheckboxChange(checkbox.value)}
            />
            <label htmlFor={`checkbox-${index}`} className="p-checkbox-label">
              {checkbox.label}
            </label>
            {index < checkboxes.length - 1 && <Divider />}
          </div>
        ))}
      </div>
      <Button className="button-bar" label="Apply" onClick={onApplyFilter} />
    </div>
  );
};

CustomFilter.propTypes = {
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  checkedItems: PropTypes.object.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
};

export default CustomFilter;
