import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import React, { useEffect, useState } from 'react';
import Illustration from '../../../../../../Assets/Student/tableilustration.svg';
import { InputNumber } from 'primereact/inputnumber';
import ErrorMessage from '../../../../../../Shared-Component/ErrorMessage/ErrorMessage';

const APCourses = ({ getApiResponse }) => {
    const [scoreError, setScoreError] = useState(false);
    const [apexamError, setApExamError] = useState(false);
    const [error, setError] = useState("");
    const [editIndex, setEditIndex] = useState(null);


    useEffect(() => {
        const storedData = localStorage.getItem(localStorageKey);
        if (storedData) {
            setNewData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        const scoresData = getApiResponse?.scores;
        if (scoresData?.length > 0) {
            localStorage.setItem(localStorageKey, JSON.stringify(scoresData));
        }
    }, [getApiResponse]);

    const localStorageKey = 'AP_Courses';
    const ApCourse = [
        "AP Language & Composition",
        "AP Literature & Composition",
        "AP US History",
        "AP Psychology I/II",
        "AP Computer Science Principles",
        "AP Biology",
    ];
    const [toggleForm, setToggleForm] = useState(false);
    const [newData, setNewData] = useState([]);
    const [selected, setSelected] = useState("");

    const changeSelectOptionHandler = (event) => {
        setSelected(event.target.value);
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const intValue = parseInt(value, 10);
        switch (name) {
            case "apExam":
                if (!value) {
                    setApExamError('AP Exam is required.')
                }
                else {
                    setApExamError(null);
                }
                break;
            case "score":
                if (intValue < 1 || intValue > 5) {
                    setScoreError('Score must be between 1 and 5.');
                }
                else {
                    setScoreError('');
                }
                break;
            default:
                break;
        }
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fieldLabelMap = {
            apExam: "apExam",
            score: "score",
        };

        if (scoreError) {
            return;
        }

        if (apexamError) {
            return;
        }

        const setError = (field, message) => {
            switch (field) {
                case "apExam":
                    setApExamError(message);
                    break;
                case "score":
                    setScoreError(message);
                    break;
                default:
            }
        };
        const emessage = "This field is required";
        const errors = Object.entries(fieldLabelMap).reduce(
            (acc, [field, label]) => {
                if (!formData[field]) {
                    acc[field] = emessage;
                }
                return acc;
            },
            {}
        );

        if (Object.keys(errors).length > 0) {
            Object.entries(errors).forEach(([field, message]) => {
                setError(field, message);
            });
            return;
        }
        const newTableData = {
            apExam: formData.apExam,
            score: formData.score,
        };

        if (editIndex !== null) {
            const updatedData = [...newData];
            const editedData = {
                apExam: formData.apExam,
                score: formData.score,
            };
            updatedData[editIndex] = editedData;
            setNewData(updatedData);
            setEditIndex(null);
            setFormData(data);
            localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
        } else {

            const updatedFormData = [...newData, newTableData];

            setFormData(data);

            localStorage.setItem(localStorageKey, JSON.stringify(updatedFormData));
        }

        const storedData = localStorage.getItem(localStorageKey);
        if (storedData) {
            setNewData(JSON.parse(storedData));
        }

        handleClick();
    };

    const data = {
        apExam: '',
        score: '',
    };

    const [formData, setFormData] = useState(data);

    const handleClick = () => {
        setToggleForm(!toggleForm);
        setEditIndex(null);
        setFormData(data);
    };

    const handleEdit = (rowData, index) => {
        setToggleForm(true);
        setFormData({ ...rowData });
        setEditIndex(index);
    };

    const handleDelete = (index) => {
        const updatedData = [...newData];
        updatedData.splice(index, 1);
        setNewData(updatedData);
        setEditIndex(null);
        localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
        setFormData(data);
    };

    return (
        <div className="complete-courses">
            {toggleForm ? (
                <div className="complete-course-section">
                    <form onSubmit={handleSubmit}>
                        {error && <ErrorMessage message={error} isSuccess={true} />}
                        <div className='cc-note'>If you take one of the AP exams please provide here the score.</div>
                        <div className="complete-course-container flex flex-wrap gap-3 pt-4">
                            <div className="col flex flex-column gap-2">
                                <label>AP Exam</label>
                                <Dropdown
                                    name="apExam"
                                    options={ApCourse}
                                    value={formData.apExam}
                                    onChange={changeSelectOptionHandler}
                                    placeholder="Select an AP Exam"
                                    className="w-full"
                                    required
                                />
                                {apexamError && <small className="text-danger" style={{ color: "#E04A4A" }}>{apexamError}</small>}
                            </div>
                            <div className="course-section col flex flex-column gap-2">
                                <label>Score</label>
                                <InputNumber name="score" value={formData.score} onValueChange={handleChange} keyfilter="num" placeholder="Enter your points: 1-5" />
                                {scoreError && <small className="text-danger" style={{ color: "#E04A4A" }}>{scoreError}</small>}
                            </div>
                        </div>

                        <footer className="Extra-activities-form-footer gap-4">
                            <Button
                                className="course-button"
                                label="Cancel"
                                severity="info"
                                onClick={handleClick}
                            />
                            <Button
                                type="submit"
                                value="Submit"
                                label={editIndex !== null ? "Update AP Courses" : "Add AP Courses"}
                                severity="secondary"
                            />
                        </footer>
                    </form>
                </div>
            ) : (
                <div>
                    <div
                        className="flex flex-wrap justify-content-end gap-1 pt-4 pb-4"
                        onClick={handleClick}
                    >
                        <div className="addextra-text">+ Add AP Courses</div>
                    </div>

                    {newData.length === 0 ? (
                        <div className="course-complete-img flex flex-column justify-content-center align-items-center">
                            <Image src={Illustration} alt="Image" width="550px" />
                            <p>
                                You will see information here when you add your AP Scores
                            </p>

                            <Button className='m-3' label="Add AP Courses" severity="secondary" onClick={handleClick} />

                        </div>
                    ) : (
                        <div className="extra-activities-tables pt-4 pb-4">
                            <DataTable
                                className="datatable-container"
                                value={newData}
                                tableStyle={{ minWidth: "50rem" }}
                            >
                                <Column field="apExam" header="AP Exam"></Column>
                                <Column field="score" header="Score"></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData, column) => (
                                        <div className="actions">
                                            <div>
                                                <Button
                                                    icon="pi pi-pencil"
                                                    className="p-button-rounded p-button-success mr-1"
                                                    onClick={() => handleEdit(rowData, column.rowIndex)}
                                                />
                                                <Button
                                                    icon="pi pi-trash"
                                                    className="p-button-rounded p-button-danger ml-1"
                                                    onClick={() => handleDelete(column.rowIndex)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                            </DataTable>
                            <ul className="hide-on-large-screens">
                                {newData.map((item, index) => (
                                    <li key={item.id}>
                                        <div className="activity-heading flex justify-content-evenly align-items-center">
                                            <strong className="col-6">AP Exam </strong>
                                            <strong className="col-6">Score</strong>
                                        </div>
                                        <div>
                                            <div className="px-2 py-2">
                                                <div className="flex justify-content-center align-items-center">
                                                    <span className="col-6">{item.apExam}</span>
                                                    <span className="col-6">{item.score}</span>
                                                </div>
                                                <Divider />
                                                <div className="flex justify-content-evenly align-items-center">
                                                    <strong>Actions</strong>
                                                    <div className="actions">
                                                        <Button
                                                            icon="pi pi-pencil"
                                                            className="p-button-rounded p-button-success mr-1"
                                                            onClick={() => handleEdit(item, index)}
                                                        />
                                                        <Button
                                                            icon="pi pi-trash"
                                                            className="p-button-rounded p-button-danger ml-1"
                                                            onClick={() => handleDelete(item.id)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default APCourses