import React, { useState,useEffect } from 'react';
import "../Teacher.scss";
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar'
import { useNavigate } from 'react-router-dom'
import StudentDataTable from '../../../Shared-Component/ViewLists/StudentDataTable';
import axios from 'axios';
import { GET_TEACHER_DETAILS_URL, TEACHER_URL_HEADERS } from '../../../Utils/TeacherService';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';

const MyStudent = () => {
    const userRole = 'Teacher';
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('students');
    const [name, setName] = useState();
    const [teacherDetails, setTeacherDetails] = useState();
    const [teacherName, setTeacherName] = useState();

    
    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/teacher/dashboard');
                break;
            case 'students':
                navigate('/teacher/studentsList');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        fetchTeacherDetails();
    }, []);

    const fetchTeacherDetails = () => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });


        const teacherId = localStorage.getItem('userId');
        axios
            .get(GET_TEACHER_DETAILS_URL + teacherId, { headers: TEACHER_URL_HEADERS })
            .then((response) => {
                setTeacherDetails(response.data);
                const teacherFirstNames = response.data?.groupUsersInfo;
                setTeacherName(teacherFirstNames)
                setName(`${response.data.teacher.firstName} ${response.data.teacher.lastName}`);
                setLoading(false);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
            });
    };

    return (
        <div className='teacher-student-section'>
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>
            <NavBar 
                activeMenuItem={activeMenuItem} 
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole} 
                name={name}
            />
            <StudentDataTable/>
        </div>
    )
}

export default MyStudent