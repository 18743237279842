import React, { useState, useEffect, useRef } from 'react'
import "./StudentInformation.scss";
import ProfileCard from '../../../../Shared-Component/ProfileCard/profileCard'
import { Link } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useNavigate } from 'react-router-dom';
import NavBar from '../../../../Shared-Component/StackNavBar/StackNavBar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from "primereact/inputtext";
import logo from '../../../../Assets/Logo/LOGO.svg';
import { InputTextarea } from "primereact/inputtextarea";
import axios from 'axios';
import { STUDENT_INFO, AUTHORIZATION_HEADERS, STUDENT_INFO_HIGH_SCHOOL_CREDITS } from '../../../../Utils/StudentService';
import moment from 'moment';
import { usePDF } from 'react-to-pdf';
import Spinner from '../../../../Shared-Component/Spinner/Gif-Loader';
import StudentCard from '../../../../Shared-Component/Student-PersonalPathway-Card/Student-PersonalPathway-Card';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { TEACHER_NOTES_URL } from '../../../../Utils/TeacherService';

const StudentInformation = () => {

    const [activeMenuItem, setActiveMenuItem] = useState("");
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [studentDetails, setStudentDetails] = useState();
    const [graduationYear, setGraduationYear] = useState();
    const [lastUpdate, setLastUpdate] = useState('');
    const [activeIndex, setActiveIndex] = useState(false);
    const [personalStatementNotes, setPersonalStatementNotes] = useState('');
    const [careerGoalsNotes, setCareerGoalsNotes] = useState('');
    const [carrerGoalsNotes, setCarrerGoalsNotes] = useState('');
    const [academicGoalsNotes, setAcademicGoalsNotes] = useState('');
    const [academiesPathwaysNotes, setAcademiesPathwaysNotes] = useState('');
    const [highSchoolProgressNotes, setHighSchoolProgressNotes] = useState('');
    const [testScoresNotes, setTestScoresNotes] = useState('');
    const [extraActivitiesNotes, setExtraActivitiesNotes] = useState('');
    const [academiesCTEPathways, setAcademiesCTEPathways] = useState('');
    const [datass, setDatass] = useState([]);
    const [positionFix, setPositionFix] = useState([]);
    const { toPDF, targetRef } = usePDF({ filename: 'StudentInformation.pdf' });

    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

    const [mySkills, setMySkills] = useState([]);
    const [personalStatement, setPersonalStatement] = useState('');
    const [careerGoals, setCareerGoals] = useState('');
    const [teacherNotes, setTeacherNotes] = useState('');
    const [updateDate, setUpdateDate] = useState(null);
    const [youScienceResults, setYouScienceResults] = useState(null);
    const [riasecResults, setRiasecResults] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [goalPathway, setGoalPathway] = useState('');
    const [careerMessage, setCareerMessage] = useState('');
    const [academicGoalsData, setAcademicGoalsData] = useState([]);

    const [personalStatementUpdateDate, setPersonalStatementUpdateDate] = useState(null);
    const [careerGoalsUpdateDate, setCareerGoalsUpdateDate] = useState(null);
    const [academicGoalsUpdateDate, setAcademicGoalsUpdateDate] = useState(null);
    const [academiesPathwaysUpdateDate, setAcademiesPathwaysUpdateDate] = useState(null);
    const [highSchoolProgressUpdateDate, setHighSchoolProgressUpdateDate] = useState(null);
    const [testScoresUpdateDate, setTestScoresUpdateDate] = useState(null);
    const [extraActivitiesUpdateDate, setExtraActivitiesUpdateDate] = useState(null);
    const [actScores, setACTScores] = useState([]);
    const [satScores, setSATScores] = useState([]);
    const [psatScores, setPSATScores] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [achievements, setAchievements] = useState([]);
    const [extraCurricularActivities, setExtraCurricularActivities] = useState([]);
    const [communityServices, setCommunityServices] = useState([]);
    const [jobExperiences, setJobExperiences] = useState([]);

    const [personalStatementTeacherNotes, setPersonalStatementTeacherNotes] = useState('');
    const [careerGoalsTeacherNotes, setCareerGoalsTeacherNotes] = useState('');
    const [academicGoalsTeacherNotes, setAcademicGoalsTeacherNotes] = useState('');
    const [academiesPathwaysTeacherNotes, setAcademiesPathwaysTeacherNotes] = useState('');
    const [highSchoolProgressTeacherNotes, setHighSchoolProgressTeacherNotes] = useState('');
    const [testScoreTeacherNotes, setTestScoreTeacherNotes] = useState('');
    const [extraActivityTeacherNotes, setExtraActivityTeacherNotes] = useState('');
    const [coursesList, setCoursesList] = useState([]);
    const [apScores, setApScores] = useState([]);
    const [highschoolTeacherNotes, setHighschoolTeacherNotes] = useState("");
    const [testScoresTeacherNotes, setTestScoresTeacherNotes] = useState('');
    const [extraActivitiesTeacherNotes, setExtraActivitiesTeacherNotes] = useState('');
    const [academiesPathWayNames, setAcademiesPathWayNames] = useState([]);

    const [userRole, setUserRole] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [registrationId, setRegistrationId] = useState('');

    const [graduationCredits, setGraduationCredits] = useState('');
    const [creditsCompleted, setCreditsCompleted] = useState("");
    const [creditsPending, setCreditsPending] = useState("");
    const [totalGraduationCredits, setTotalGraduationCredits] = useState("");
    const [educationalGoal, setEducationalGoal] = useState();
    const [academicCGPAGoals, setAcademicCGPAGoals] = useState();
    const [honorsRecognitionCertificate, setHonorsRecognitionCertificate] = useState();
    const [academiesCategoryName, setAcademiesCategoryName] = useState();
    const [highSchoolAcademicGoals, setHighSchoolAcademicGoals] = useState();
    const [allZero, setAllZero] = useState();



    useEffect(() => {
        const currentUrl = window.location.pathname;

        if (currentUrl.includes('/principal')) {
            handlePrincipalMenuClick(activeMenuItem);
            setUserRole("Principal")
        } else if (currentUrl.includes('/teacher')) {
            handleTeacherMenuClick(activeMenuItem);
            setUserRole("Teacher")
        }

    }, [activeMenuItem]);


    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
    };

    const handlePrincipalMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/principal/dashboard');
                break;
            case 'groups':
                navigate('/principal/groups');
                break;
            case 'students':
                navigate('/principal/students');
                break;
            default:
                break;
        }
    }

    const handleTeacherMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/teacher/dashboard');
                break;
            case 'students':
                navigate('/teacher/studentsList');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });
        fetchGraduationRequirement();

        axios
            .get(STUDENT_INFO + localStorage?.rowStudentId, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {
                const data = response.data;

                setStudentDetails(response.data)

                if (data?.firstName) {
                    setFirstName(data.firstName);
                }

                setLastName(data.lastName);
                setRegistrationId(data.schools ? data.schools.schoolRegistrationId : '-');
                const graduationYear = data.groups && data.groups.length > 0 ? data.groups[0].graduationYear || "N/A" : "-";
                const lastUpdate = data.groups && data.groups.length > 0 ? new Date(data.groups[0].updatedOn).toLocaleDateString() : "-";
                setGraduationYear(graduationYear);
                setLastUpdate(lastUpdate);

                if (data?.personalStatement?.mySkills?.versions) {
                    const latestMySkillsVersion = data.personalStatement.mySkills.versions[data.personalStatement.mySkills.versions.length - 1];
                    setMySkills(latestMySkillsVersion?.mySkills);
                }
                if (data?.personalStatement?.personalStatement?.versions) {
                    const latestPersonalStatementVersion = data.personalStatement.personalStatement.versions[data.personalStatement.personalStatement.versions.length - 1];
                    setPersonalStatement(latestPersonalStatementVersion?.personalStatement);
                }

                const latestCareerGoalsVersion = data?.careerGoals?.careerGoalCategories[data?.careerGoals?.careerGoalCategories?.length - 1];
                setCareerGoals(latestCareerGoalsVersion)
                const latestYouScienceResults = data?.careerGoals?.youScienceResults[data?.careerGoals?.youScienceResults?.length - 1];
                const latestRiasecResults = data?.careerGoals?.riasecResults[data?.careerGoals?.riasecResults?.length - 1];
                if (latestRiasecResults && latestRiasecResults?.result && Array.isArray(latestRiasecResults?.result)) {
                    setRiasecResults(latestRiasecResults?.result);
                } else {
                    console.error('Invalid format for RIASEC results');
                }
                setYouScienceResults(latestYouScienceResults);
                setRiasecResults(latestRiasecResults);

                if (latestCareerGoalsVersion?.notDecided) {
                    setGoalPathway("Not Decided");
                } else {
                    const categories = latestCareerGoalsVersion?.categories;
                    if (categories && categories.length > 0) {
                        const categoryElements = categories.map(category => {
                            const categoryString = `${category.categoryName} : ${category.goalPathway[0]}`;
                            return (
                                <div key={category.categoryName}>
                                    <b>{category.categoryName}</b> : {category.goalPathway[0]}
                                </div>
                            );
                        });
                        setCategoryName(categoryElements);
                        setGoalPathway(categoryElements[0]);
                    } else {
                        console.log("No data available.");
                    }
                }

                // else {
                //     setCategoryName(latestCareerGoalsVersion?.categories[0]?.categoryName);
                //     setGoalPathway(latestCareerGoalsVersion?.categories[0]?.goalPathway[0]);
                // }

                setCareerMessage(latestCareerGoalsVersion?.careerMessage);
                setTeacherNotes(data.careerGoals?.teacherNotes);


                const latestAcademicGoalsVersion = data?.academicGoals?.academicGoals[data?.academicGoals?.academicGoals.length - 1];

                const goalsData = latestAcademicGoalsVersion && latestAcademicGoalsVersion.goals
                    ? latestAcademicGoalsVersion.goals.map(goal => ({
                        academicGoalsCategoryName: goal.academicGoalsCategoryName,
                        goalOptions: goal.goalOptions
                    })) : [];

                setAcademicGoalsData(goalsData);

                const educationalGoalsOptions = latestAcademicGoalsVersion && latestAcademicGoalsVersion.goals
                    ? latestAcademicGoalsVersion.goals.filter(goal => goal.academicGoalsCategoryName === "Educational Goal")
                        .map(goal => goal.goalOptions) : [];
                setEducationalGoal(educationalGoalsOptions?.length ? educationalGoalsOptions : "-");

                const filteredAcademicCGPAGoal = latestAcademicGoalsVersion && latestAcademicGoalsVersion.goals
                    ? latestAcademicGoalsVersion.goals.filter(goal => goal?.academicGoalsCategoryName === "Cumulative GPA Goal")
                        .map(goal => goal.goalOptions) : [];
                setAcademicCGPAGoals(filteredAcademicCGPAGoal?.length ? filteredAcademicCGPAGoal : "-");

                const filteredHonorRecognitionCertificateGoal = latestAcademicGoalsVersion && latestAcademicGoalsVersion.goals
                    ? latestAcademicGoalsVersion.goals.filter(goal => goal?.academicGoalsCategoryName === "Honors Recognition Certificate Goal")
                        .map(goal => goal.goalOptions) : [];
                setHonorsRecognitionCertificate(filteredHonorRecognitionCertificateGoal?.length ? filteredHonorRecognitionCertificateGoal : "-");

                const filteredAfterHighSchoolGoal = latestAcademicGoalsVersion && latestAcademicGoalsVersion.goals
                    ? latestAcademicGoalsVersion.goals.filter(goal => goal?.academicGoalsCategoryName === "After High School Plan")
                        .map(goal => goal.goalOptions) : [];
                setHighSchoolAcademicGoals(filteredAfterHighSchoolGoal?.length ? filteredAfterHighSchoolGoal : "-");

                setPersonalStatementUpdateDate(data?.personalStatement?.updatedOn ? new Date(data.personalStatement.updatedOn).toLocaleDateString() : (data?.createdOn ? new Date(data.createdOn).toLocaleDateString() : null));
                setCareerGoalsUpdateDate(data?.careerGoals?.updatedOn ? new Date(data.careerGoals.updatedOn).toLocaleDateString() : (data?.createdOn ? new Date(data.createdOn).toLocaleDateString() : null));
                setAcademicGoalsUpdateDate(data?.academicGoals?.updatedOn ? new Date(data.academicGoals.updatedOn).toLocaleDateString() : (data?.createdOn ? new Date(data.createdOn).toLocaleDateString() : null));
                setAcademiesPathwaysUpdateDate(data?.academiesPathways?.updatedOn ? new Date(data.academiesPathways.updatedOn).toLocaleDateString() : (data?.createdOn ? new Date(data.createdOn).toLocaleDateString() : null));
                setHighSchoolProgressUpdateDate(data?.highSchoolProgressDTO?.updatedOn ? new Date(data.highSchoolProgressDTO?.updatedOn).toLocaleDateString() : (data?.createdOn ? new Date(data.createdOn).toLocaleDateString() : null));
                setTestScoresUpdateDate(data?.testScores?.updatedOn ? new Date(data.testScores.updatedOn).toLocaleDateString() : (data?.createdOn ? new Date(data.createdOn).toLocaleDateString() : null));
                setExtraActivitiesUpdateDate(data?.extraActivitiesDTO?.updatedOn ? new Date(data.extraActivitiesDTO.updatedOn).toLocaleDateString() : (data?.createdOn ? new Date(data.createdOn).toLocaleDateString() : null));

                const latestACTScoresVersion = data.testScores?.actScores?.actScoresVersion[data?.testScores?.actScores.actScoresVersion.length - 1];
                const latestSATScoresVersion = data.testScores?.satScores?.scoresVersion[data?.testScores?.satScores.scoresVersion.length - 1];
                const latestPSATScoresVersion = data.testScores?.psatScores?.scoresVersion[data?.testScores?.psatScores.scoresVersion.length - 1];

                if (latestACTScoresVersion) {
                    setACTScores(latestACTScoresVersion?.actScores);
                }

                if (latestSATScoresVersion) {
                    setSATScores(latestSATScoresVersion?.scores);
                }

                if (latestPSATScoresVersion) {
                    setPSATScores(latestPSATScoresVersion?.scores);
                }

                const latestAchievements = data.extraActivitiesDTO?.achievements[data?.extraActivitiesDTO?.achievements?.length - 1]?.achievement;
                const latestActivities = data.extraActivitiesDTO?.extraCurricularActivities[data?.extraActivitiesDTO?.extraCurricularActivities?.length - 1]?.activities;
                const latestServices = data.extraActivitiesDTO?.communityServices[data?.extraActivitiesDTO?.communityServices?.length - 1]?.services;
                const latestExperiences = data.extraActivitiesDTO?.experiences[data?.extraActivitiesDTO?.experiences?.length - 1]?.jobExperiances;
                const latestRecommendations = data.extraActivitiesDTO?.letterOfRecommendations[data?.extraActivitiesDTO?.letterOfRecommendations?.length - 1]?.recommendations;

                setAchievements(latestAchievements);
                setExtraCurricularActivities(latestActivities);
                setCommunityServices(latestServices);
                setJobExperiences(latestExperiences);
                setRecommendations(latestRecommendations);

                const academiesPathways = data.academiesPathways || {};
                const academiesPathwayVersion = academiesPathways.academiesPathwayVersion || {};
                const academiesPathway = academiesPathwayVersion.academiesPathway || [];


                const latestVersionIndex = (data.academiesPathways?.academiesPathwayVersion?.academiesPathway?.length ?? 0) - 1;
                if (latestVersionIndex >= 0) {
                  const latestVersion = data.academiesPathways?.academiesPathwayVersion?.academiesPathway[latestVersionIndex];
                  const academiesPathWayNames = latestVersion?.academiesPathwayCategory.flatMap(category => {
                      if (category?.academiesPathWay && category?.academiesPathWay[0] !== null) {
                        return {
                          categoryName: category?.academiesCategoryName,
                          pathWay: category?.academiesPathWay[0]
                        };
                      } else {
                        return null;
                      }
                    })
                    .filter(category => category !== null);
                
                  setAcademiesPathWayNames(academiesPathWayNames);
                } else {
                  setAcademiesPathWayNames([]);
                }
                

                if (data.academiesPathways?.academiesPathwayVersion?.academiesPathway.length > 0) {
                    const latestVersion = ((data.academiesPathways?.academiesPathwayVersion?.academiesPathway.length) - 1);
                    const latestAcademyPathway = data?.academiesPathways?.academiesPathwayVersion?.academiesPathway[latestVersion];
                    const academicCategory = latestAcademyPathway?.academiesPathwayCategory?.find(category => category?.academiesPathWay.some(pathway => pathway !== null));
                    const categoryName = academicCategory ? academicCategory?.academiesCategoryName : null;
                    setAcademiesCategoryName(categoryName)
                }

                setPersonalStatementNotes(data?.personalStatement?.teacherNotes?.versions[data?.personalStatement?.teacherNotes?.versions?.length - 1].notes || '');
                setCareerGoalsNotes(data?.careerGoals?.teacherNotes?.versions[data?.careerGoals?.teacherNotes?.versions?.length - 1].notes || '');
                setAcademicGoalsNotes(data?.academicGoals?.teacherNotes?.versions[data?.academicGoals?.teacherNotes?.versions?.length - 1].notes || '');
                setAcademiesPathwaysNotes(data?.academiesPathways?.teacherNotes?.versions[data?.academiesPathways?.teacherNotes?.versions?.length - 1].notes || '');
                setHighSchoolProgressNotes(data?.highSchoolProgressDTO?.teacherNotes?.versions[data?.highSchoolProgressDTO?.teacherNotes?.versions?.length - 1].notes || '');
                setTestScoresNotes(data?.testScores?.teacherNotes?.versions[data?.testScores?.teacherNotes?.versions?.length - 1].notes || '');
                setExtraActivitiesNotes(data?.extraActivitiesDTO?.teacherNotes?.versions[data?.extraActivitiesDTO?.teacherNotes?.versions?.length - 1].notes || '');

                const highSchoolProgressCourses = data?.highSchoolProgressDTO?.highSchoolCourses?.progress[data?.highSchoolProgressDTO?.highSchoolCourses?.progress.length - 1];
                const highSchoolProgressElective = data?.highSchoolProgressDTO?.electiveCategories?.electives[data?.highSchoolProgressDTO?.electiveCategories?.electives.length - 1];
                const apCoursesList = data?.highSchoolProgressDTO?.apScores?.scoresVersion[data?.highSchoolProgressDTO?.apScores?.scoresVersion.length - 1]
                const highschoolTeacherNotes = data?.highSchoolProgressDTO?.teacherNotes;

                const highSchoolProgress = ({ ...highSchoolProgressCourses, ...highSchoolProgressElective })

                const highSchoolCoursesCompleted = highSchoolProgress && (highSchoolProgress.subjects || highSchoolProgress.electiveCourses) ?
                    (highSchoolProgress.subjects || []).flatMap(subject => {
                        if (subject?.courses) {
                            const courseDetails = subject.courses.map(course => ({
                                area: course?.area || "",
                                course: course?.course || "",
                                creditValue: course?.creditValue || "",
                                status: course?.status || ""
                            }));
                            return courseDetails;
                        }
                        return [];
                    }).concat(
                        (highSchoolProgress.subjects || []).flatMap(subject => {
                            if (subject.electiveCategories) {
                                return subject.electiveCategories.flatMap(category => {
                                    return category.courses.map(course => ({
                                        area: course.area || "",
                                        course: course.course || "",
                                        creditValue: course.creditValue || "",
                                        status: course.status || ""
                                    }));
                                });
                            }
                            return [];
                        })
                    ).concat(
                        (highSchoolProgress.electiveCourses || []).flatMap(category => {
                            return category.courses.map(course => ({
                                area: course.area || "",
                                course: course.course || "",
                                creditValue: course.creditValue || "",
                                status: course.status || ""
                            }));
                        })
                    ) : [];

                setCoursesList(highSchoolCoursesCompleted);
                setApScores(apCoursesList?.scores);
                setHighschoolTeacherNotes(highschoolTeacherNotes);

                setLoading(false);

            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });

    }, []);

    const fetchGraduationRequirement = () => {

        const id = localStorage.getItem("rowStudentId")
        axios
            .get(STUDENT_INFO_HIGH_SCHOOL_CREDITS + id, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {
                setCreditsCompleted(response.data?.creditsCompleted);
                setCreditsPending(response.data?.creditsPending);
                setTotalGraduationCredits(response.data?.totalGraduationCredits);

                const dataTableData = Object.keys(response.data?.pendingCredits).map(area => ({
                    area: area,
                    pendingCredits: response.data?.pendingCredits[area],
                    completedCredits: response.data?.completedCredit[area],
                    totalCredits: response.data?.pendingCredits[area] + response.data?.completedCredit[area],
                    newValue: response.data?.completedCredit[area] + "/" + (response.data?.pendingCredits[area] + response.data?.completedCredit[area])
                }));

                setGraduationCredits(dataTableData)

                const allValuesZero = dataTableData.every(data => {
                    return data.pendingCredits === 0 &&
                        data.completedCredits === 0 &&
                        data.totalCredits === 0;
                });

                setAllZero(allValuesZero);

                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    const downloadPDF = () => {
        setPositionFix('Active');
        setTimeout(() => {
            toPDF();
            setVisible(false);
        }, 70);
    }

    const openDialogBox = () => {
        setVisible(true);
        setPositionFix('');
    }

    const calculateTotal = (rowData) => {
        const { evidenceBasedReadingAndWriting, math } = rowData;
        const total = evidenceBasedReadingAndWriting + math;
        return total;
    };

    const renderCell = (rowData, field, activityType) => {
        const value = rowData[field];
        const presentText = getActivityPresentText(activityType);
        return value !== null ? moment(value).format('MM/YYYY') : presentText;
    };
    
    const getActivityPresentText = (activityType) => {
        switch (activityType) {
            case 'extraCurricularActivities':
                return 'Present Activity';
            case 'communityServices':
                return 'Present Service';
            case 'jobExperiences':
                return 'Present Work';
            default:
                return 'Present';
        }
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "personalStatementNotes") {
            setPersonalStatementNotes(value);
        } else if (name === "careerGoalsNotes") {
            setCareerGoalsNotes(value);
        } else if (name === "academicGoalsNotes") {
            setAcademicGoalsNotes(value);
        } else if (name === "academiesPathwaysNotes") {
            setAcademiesPathwaysNotes(value);
        } else if (name === "highSchoolProgressNotes") {
            setHighSchoolProgressNotes(value);
        } else if (name === "testScoresNotes") {
            setTestScoresNotes(value);
        } else if (name === "extraActivitiesNotes") {
            setExtraActivitiesNotes(value);
        }
    };

    const handleAddNote = (e) => {

        const payload = {
            personalStatementNotes: personalStatementNotes,
            personalStatementId: studentDetails?.personalStatement?.personalStatementId,
            careerGoalsNotes: careerGoalsNotes,
            careerGoalsId: studentDetails?.careerGoals?.careerGoalsId,
            academicGoalsNotes: academicGoalsNotes,
            academicGoalsId: studentDetails?.academicGoals?.academicGoalsId,
            academicPathwaysNotes: academiesPathwaysNotes,
            academicPathwaysId: studentDetails?.academiesPathways?.academiesPathwaysId,
            highSchoolProgressNotes: highSchoolProgressNotes,
            highSchoolProgressId: studentDetails?.highSchoolProgressDTO?.highSchoolProgressId,
            testScoresNotes: testScoresNotes,
            testScoresId: studentDetails?.testScores?.testScoreId,
            extraActivitiesNotes: extraActivitiesNotes,
            extraActivitiesId: studentDetails?.extraActivitiesDTO?.extraActivitiesId,
        }

        axios
            .put(TEACHER_NOTES_URL, payload, { headers: AUTHORIZATION_HEADERS })
            .then((response) => { })
            .catch((error) => { console.log(error) });
    }

    return (

        <div className='main-s'>
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>

            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />

            <div className='backBtn'>
                <Link
                    to={'/' + sessionStorage.userType + '/dashboard'}
                    style={{ textDecoration: "none", color: "#1575e5" }}
                >{'<'} Back to my dashboard</Link>
            </div>

            <div className="card">

                <div className='left m-2'>
                    <ProfileCard
                        userRole={'Student'}
                        name={firstName + ' ' + lastName}
                        schoolId={studentDetails?.registrationId}
                        grade={studentDetails?.groups[0]?.grade}
                        gpa={studentDetails?.highSchoolGap}
                        gender={studentDetails?.gender}
                        className="student-profilecard"
                    />
                </div>

                <div className='right m-2'>
                    <StudentCard
                        coreDrivers={youScienceResults?.coreDrivers}
                        aptitudes={youScienceResults?.aptitudes}
                        riasecResults={riasecResults?.result[0]}
                        careerGoals={goalPathway}
                        educationalGoal={educationalGoal}
                        academiCGPAGoals={academicCGPAGoals}
                        honorsRecognitionCertificate={honorsRecognitionCertificate}
                        highSchoolAcademicGoals={highSchoolAcademicGoals}
                        academiesCategoryName={academiesCategoryName}
                    />
                </div>

            </div>

            < div className='student-info-button' >

                <div className="flex justify-content-between w-full mt-6">
                    <h2>Student Personal Pathway</h2>
                    <Button label="Download PDF Personal Pathway" onClick={openDialogBox} />
                </div>

                {
                    visible ? <div className='dialogSec'>

                        <div ref={targetRef}>

                            <div className='nav' style={{ position: (positionFix === 'Active' ? "relative" : "fixed"), marginTop: (positionFix === 'Active' ? "25px" : "0px"), marginBottom: (positionFix === 'Active' ? "-70px" : "0px") }}>
                                <div className="logo">
                                    <img src={logo} alt="" />
                                </div>
                                <i className="pi pi-times" style={{ fontSize: '1.5rem', cursor: 'pointer' }} onClick={() => setVisible(false)}></i>
                            </div>

                            <br></br>

                            {datass ? (
                                <div>
                                    <div className='inputFeild' style={{ top: (positionFix === 'Active' ? "70px" : "100px") }}>
                                        <div>
                                            <span>Name</span>
                                            <InputText value={`${firstName} ${lastName}`} />
                                        </div>

                                        <div>
                                            <span>Student ID</span>
                                            <InputNumber value={registrationId || "N/A"} />
                                        </div>

                                        <div>
                                            <span>Graduation Year</span>
                                            <InputText value={graduationYear} />
                                        </div>

                                        <div>
                                            <span>Last Update</span>
                                            <InputText value={lastUpdate} />
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <br></br>

                            <div>
                                <div className='personal m-5' >
                                    <div className='heading'>
                                        Section 1 : Personal Statement
                                    </div>
                                    <div className='mainContent m-5 '>
                                        <div className='personal-skills-section'>
                                            <h3>Student Skills</h3>
                                            <div className='flex align-items-center justify-content-start flex-wrap gap-5'>
                                                {mySkills.length > 0 ? (
                                                    mySkills.map((skill, index) => (
                                                        <button className='flex align-items-center justify-content-center h-3rem skills-button' style={{ borderRadius: '30px', border: 'none', backgroundColor: "#052941", color: "#FFFFFF", fontSize: "20px", padding: "7px 15px" }} key={index}>{skill}</button>
                                                    ))
                                                ) : (
                                                    <span>N/A</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className='personal-statement-section'>
                                            <h3>Personal Statement</h3>
                                            <p>{personalStatement || 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className='note m-5'>
                                        <h3>Teacher Notes</h3>
                                        <InputTextarea autoResize value={personalStatementNotes || 'N/A'} style={{ width: '100%', margin: 'auto', position: 'relative', display: 'flex' }} rows={5} cols={30} />
                                    </div>
                                    <br></br>
                                    <hr style={{ position: 'relative', width: '98%' }}></hr>
                                </div>

                                <div className='career m-5'>
                                    <div className='heading'>
                                        Section 2 : Career Goals
                                    </div>
                                    <div className='mainCondent m-5 pt-3'>
                                        <div className='career-section'>
                                            <div className='results-section'>
                                                <>
                                                    <div className='flex align-items-center justify-content-start flex-wrap gap-6'>
                                                        <div className='line pl-2'>
                                                            <span><b>Core Drivers</b></span>
                                                            <p>{youScienceResults?.coreDrivers || 'N/A'}</p>
                                                        </div>
                                                        <div className='line pl-2'>
                                                            <span><b>Aptitudes</b></span>
                                                            <p>{youScienceResults?.aptitudes || 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                            <div className='riasec-section'>
                                                <div>
                                                    <div className='riasec-heading mt-3 mb-2'><b>RIASEC Results:</b></div>
                                                    <span className='flex align-items-center justify-content-between line'>{riasecResults?.result.join(", ") || 'N/A'}</span>
                                                </div>
                                            </div>
                                            <div className='selection-section'>
                                                <div>
                                                    <div className='selection-heading mt-3 mb-2'><b>Student Career Selection</b></div>
                                                    <div className='line'>
                                                        <div className='selection-head'>{categoryName || 'N/A'} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='reason-section mb-2'>
                                                <div className='reason-heading mt-3 mb-2'><b>{firstName}'s Reason</b></div>
                                                <span>{careerGoals?.careerMessage || 'N/A'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='note m-5'>
                                        <span className='teacher-heading'><b>Teacher Notes</b></span>
                                    </div>
                                    <InputTextarea autoResize value={careerGoalsNotes || 'N/A'} style={{ width: '94%', margin: 'auto', position: 'relative', display: 'flex' }} rows={5} cols={30} />
                                    <br></br>
                                    <hr style={{ position: 'relative', width: '98%' }}></hr>
                                </div>


                                <div className='academic m-5'>
                                    <div className='heading'>
                                        Section 3 : Academic Goals
                                    </div>
                                    <div className='mainCondent pt-1'>
                                        {academicGoalsData.length > 0 ? (
                                            academicGoalsData?.map((goal, index) => (
                                                <div className='flex align-items-start justify-content-start flex-wrap'>
                                                    <div key={index} className='academic-sec line'>
                                                        <span><b>{goal.academicGoalsCategoryName}</b>  </span>
                                                        <p>{goal.goalOptions}</p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p >Academic Goals was not selected.</p>
                                        )}
                                    </div>
                                    <div className='note m-5'>
                                        <span><b>Teacher Notes</b></span>
                                    </div>
                                    <InputTextarea autoResize value={academicGoalsNotes || 'N/A'} style={{ width: '94%', margin: 'auto', position: 'relative', display: 'flex' }} rows={5} cols={30} />
                                    <br></br>
                                    <hr style={{ position: 'relative', width: '98%' }}></hr>
                                </div>

                                <div className='pathway mt-5 m-5'>
                                    <div className='heading'>
                                        Section 4 : Academies Pathways
                                    </div>
                                    {academiesPathWayNames.length > 0 ? (
                                        academiesPathWayNames.map((pathway, index) => (
                                            <div className='pos line mt-5'>
                                                <div key={index}>
                                                    <span> <b>{pathway.categoryName}</b></span>
                                                    {pathway.pathWay && <p> {pathway.pathWay}</p>}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p className='pt-3 m-5'>Academies Pathways was not selected.</p>
                                    )}
                                    <div className='note pt-2 m-5'>
                                        <span><b>Teacher Notes</b></span>
                                    </div>
                                    <InputTextarea autoResize value={academiesPathwaysNotes || 'N/A'} style={{ width: '94%', margin: 'auto', position: 'relative', display: 'flex' }} rows={5} cols={30} />
                                    <br></br>

                                    <hr style={{ position: 'relative', width: '98%' }}></hr>

                                </div>


                                <div className='highschool m-5'>

                                    <div className='heading mt-6'>
                                        Section 5 : High School Progress
                                    </div>


                                    <div className='pt-5'>
                                        <h2 className='px-5'><b>Graduation Requirement</b></h2>

                                        <div className='w-full pl-5'>
                                            <div className="GR-view">
                                                <div className="circular-bar col-4 flex align-items-center flex-column">
                                                    <div className="circular-progress-bar">
                                                        <CircularProgressbarWithChildren value={creditsCompleted}>
                                                            <div>
                                                                <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits"><span style={{ fontSize: "32px", fontWeight: "bold" }}>{creditsCompleted}</span> /24</div>
                                                                <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits">Credits</div>
                                                            </div>
                                                        </CircularProgressbarWithChildren>
                                                    </div>
                                                    <div className='text-center'>
                                                        <p>Credits Completed = {creditsCompleted}</p>
                                                        <p>Credits Pending = {creditsPending}</p>
                                                        <p>Total Credits for Graduation = {totalGraduationCredits}</p>
                                                    </div>
                                                </div>
                                                <div className="progressbars-section col-8">
                                                    <DataTable value={graduationCredits}>
                                                        <Column field="area" header="Area" />
                                                        <Column field="pendingCredits" header="Pending Credits" />
                                                        <Column field="completedCredits" header="Completed Credits" />
                                                        <Column field="totalCredits" header="Total Credits" />
                                                    </DataTable>
                                                </div>
                                            </div>
                                        </div>

                                        <h2 className='px-5'><b>Completed Courses</b></h2>
                                        {coursesList.length > 0 ?
                                            <div className='w-full p-3'>
                                                <DataTable value={coursesList}>
                                                    <Column header="Courses" field="course"></Column>
                                                    <Column header="Area" field="area"></Column>
                                                    <Column header="Credit Value" field="creditValue"></Column>
                                                    <Column header="Status" field="status"></Column>
                                                </DataTable>
                                            </div>
                                            :
                                            <p className='m-5'>N/A</p>
                                        }


                                        <div className='section4 top px-5'>
                                            <div className='sub-head'><b>AP Scores</b></div>
                                        </div>

                                        {apScores?.length > 0 ? (
                                            <div className='w-full p-3'>
                                                <DataTable className="datatable-container" value={apScores}>
                                                    <Column field="apExam" header="AP Exam"></Column>
                                                    <Column field="score" header="Score"></Column>
                                                </DataTable>
                                            </div>
                                        ) : <p className='m-5'>N/A</p>}
                                    </div>

                                    <div className='note pt-1 m-5'>
                                        <span><b>Teacher Notes</b></span>
                                    </div>
                                    <InputTextarea autoResize value={highSchoolProgressNotes || 'N/A'} style={{ width: '94%', margin: 'auto', position: 'relative', display: 'flex' }} rows={5} cols={30} />
                                    <br></br>

                                    <hr style={{ position: 'relative', width: '98%' }}></hr>

                                </div>

                                <div className='scores m-5'>

                                    <div className='heading mt-6'>
                                        Section 6 : Test Scores
                                    </div>

                                    <div className='pt-5 w-full'>
                                        <div className='section5 top px-5'>
                                            <div className='sub-head'><b>ACT Scores</b></div>
                                        </div>

                                        {actScores?.length > 0 ?
                                            <div className='w-full p-3'>
                                                <DataTable value={actScores} tableStyle={{ minWidth: '50rem' }}>
                                                    <Column field="date" header="Date" body={(rowData) => {
                                                        const dateValue = rowData.date;
                                                        if (dateValue === null || dateValue < 0) {
                                                            return '-';
                                                        } else {
                                                            return new Date(dateValue).toLocaleDateString();
                                                        }
                                                    }}></Column>
                                                    <Column field="composite" header="Composite"></Column>
                                                    <Column field="english" header="English"></Column>
                                                    <Column field="math" header="Math"></Column>
                                                    <Column field="reading" header="Reading"></Column>
                                                    <Column field="science" header="Science"></Column>
                                                    <Column field="writing" header="Writing"></Column>
                                                </DataTable>
                                            </div>
                                            :
                                            <><p className='px-6' >N/A</p></>
                                        }

                                        <div className='section5 top px-5'>
                                            <div className='sub-head'><b>PSAT Scores</b></div>
                                        </div>

                                        {psatScores?.length > 0 ?
                                            <div className='w-full p-3'>
                                                <br />
                                                <DataTable value={psatScores} tableStyle={{ minWidth: '50rem' }}>
                                                    <Column field="date" header="Date" body={(rowData) => {
                                                        const dateValue = rowData.date;
                                                        if (dateValue === null || dateValue < 0) {
                                                            return '-';
                                                        } else {
                                                            return new Date(dateValue).toLocaleDateString();
                                                        }
                                                    }}></Column>
                                                    <Column field="total" header="Total" body={(rowData) => calculateTotal(rowData)}></Column>
                                                    <Column field="evidenceBasedReadingAndWriting" header="EB R/W"></Column>
                                                    <Column field="math" header="Math"></Column>
                                                    <Column field="essay_CR" header="Essay-CR"></Column>
                                                    <Column field="essay_M" header="Essay-M"></Column>
                                                    <Column field="essay_W" header="Essay-W"></Column>
                                                </DataTable>
                                            </div>
                                            :
                                            <><p className='px-6'>N/A</p></>
                                        }

                                        <div className='section5 top px-5'>
                                            <div className='sub-head'><b>SAT Scores</b></div>
                                        </div>

                                        {satScores?.length > 0 ?
                                            <div className='w-full p-3'>
                                                <br />
                                                <DataTable value={satScores} tableStyle={{ minWidth: '50rem' }}>
                                                    <Column field="date" header="Date" body={(rowData) => {
                                                        const dateValue = rowData.date;
                                                        if (dateValue === null || dateValue < 0) {
                                                            return '-';
                                                        } else {
                                                            return new Date(dateValue).toLocaleDateString();
                                                        }
                                                    }}></Column>
                                                    <Column field="total" header="Total" body={(rowData) => calculateTotal(rowData)}></Column>
                                                    <Column field="evidenceBasedReadingAndWriting" header="EB R/W"></Column>
                                                    <Column field="math" header="Math"></Column>
                                                    <Column field="essay_CR" header="Essay-CR"></Column>
                                                    <Column field="essay_M" header="Essay-M"></Column>
                                                    <Column field="essay_W" header="Essay-W"></Column>
                                                </DataTable>
                                                <br />
                                            </div>
                                            :
                                            <><p className='px-6'>N/A</p></>
                                        }
                                    </div>

                                    <div className='note pt-1 m-5'>
                                        <span><b>Teacher Notes</b></span>
                                    </div>
                                    <InputTextarea autoResize value={testScoresNotes || 'N/A'} style={{ width: '94%', margin: 'auto', position: 'relative', display: 'flex' }} rows={5} cols={30} />

                                    <hr style={{ position: 'relative', width: '98%' }}></hr>

                                </div>

                                <div className='activities m-5 '>
                                    <div className='heading'>
                                        Section 7 : Extra Activities
                                    </div>

                                    <div className='m-5 pt-2'>
                                        <div className='section4 top'>
                                            <div className='mt-2 sub-head'><b>Achievements</b></div>
                                        </div>

                                        {achievements?.length > 0 ?
                                            <DataTable value={achievements} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="achievement" header="Achievements"></Column>
                                                <Column header="Date" body={(rowData) => ((moment.utc(rowData?.date).format('MM/YYYY')))}  ></Column>
                                                <Column field="description" header="Description"></Column>
                                            </DataTable>
                                            :
                                            <><p >N/A</p></>
                                        }

                                        <div className='section4 top'>
                                            <div className='sub-head'><b>Extracurricular Activities</b></div>
                                        </div>

                                        {extraCurricularActivities?.length > 0 ?
                                            <DataTable value={extraCurricularActivities} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="extraCurricularActivity" header="Extracurricular Activities"></Column>
                                                <Column field="positionHeld" header="Position Held"></Column>
                                                <Column field="startDate" header="Start Date" body={(rowData) => moment(rowData.startDate).format('MM/YYYY')} />
                                                <Column field="endDate" header="End Date" body={(rowData) => renderCell(rowData, 'endDate','extraCurricularActivities')} />
                                                <Column field="description" header="Description"></Column>
                                            </DataTable>
                                            :
                                            <><p>N/A</p></>
                                        }

                                        <div className='section4 top'>
                                            <div className='sub-head'><b>Community Services</b></div>
                                        </div>
                                        {communityServices?.length > 0 ?
                                            <DataTable value={communityServices} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="communityService" header="Community Service" />
                                                <Column field="place" header="Place" />
                                                <Column field="startDate" header="Start Date" body={(rowData) => moment(rowData.startDate).format('MM/YYYY')} />
                                                <Column field="endDate" header="End Date" body={(rowData) => renderCell(rowData, 'endDate','communityServices')} />
                                                <Column field="totalHours" header="Total Hours" />
                                                <Column field="description" header="Description" />
                                            </DataTable>
                                            :
                                            <><p >N/A</p></>
                                        }
                                        <div className='section4 top'>
                                            <div className='sub-head'><b> Work/Internship Experience </b></div>
                                        </div>

                                        {jobExperiences?.length > 0 ?
                                            <DataTable value={jobExperiences} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="jobTitle" header="Job Title"></Column>
                                                <Column field="company" header="Company"></Column>
                                                <Column field="startDate" header="Start Date" body={(rowData) => moment(rowData.startDate).format('MM/YYYY')} />
                                                <Column field="endDate" header="End Date" body={(rowData) => renderCell(rowData, 'endDate','jobExperiences')} />
                                                {/* <Column field="totalHours" header="Total Hours"></Column> */}
                                                <Column field="description" header="Description"></Column>
                                            </DataTable>
                                            :
                                            <><p>N/A</p></>
                                        }

                                        <div className='section4 top'>
                                            <div className='sub-head'><b>Letter of Recommendations</b></div>
                                        </div>

                                        {recommendations?.length > 0 ?
                                            <DataTable value={recommendations} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="name" header="Name of the Person"></Column>
                                                <Column field="relationship" header="Relationship"></Column>
                                                <Column field="email" header=" E-mail"></Column>
                                            </DataTable>
                                            :
                                            <><p>N/A</p></>
                                        }
                                    </div>


                                    <div className='note m-5'>
                                        <span><b>Teacher Notes</b></span>
                                    </div>
                                    <InputTextarea autoResize value={extraActivitiesNotes || 'N/A'} style={{ width: '94%', margin: 'auto', position: 'relative', display: 'flex' }} onChange={(e) => setAcademiesCTEPathways(e.target.value)} rows={5} cols={30} />
                                    <br></br>

                                    <hr style={{ position: 'relative', width: '98%' }}></hr>

                                </div>
                            </div>

                            <div className='downloadBtn pos'>
                                <Button label="Download PDF" onClick={downloadPDF} />
                            </div>
                        </div>

                    </div> : null
                }

            </div >
            <div className='dd'>
                <hr></hr>
                <Accordion
                    activeIndex={activeIndex}
                    onTabChange={(e) => toggleAccordion(e.index)}
                >

                    <AccordionTab className='personal-section' header={<div style={{ display: 'flex', justifyContent: 'space-between' }}> <div className='personal-heading'><span style={{ color: '#000000' }}>Section 1: Personal Statement</span> <span className='personal-field'> {firstName}'s  Last Update: {personalStatementUpdateDate}</span></div>
                        <div className="header-icon">
                            <i
                                className={`pi ${activeIndex === 0 ? "pi-minus" : "pi-plus"}`}
                            ></i>
                        </div></div>}>
                        <div className='personal-skills-section'>
                            <h3>Student Skills</h3>
                            <div className='flex align-items-center justify-content-start flex-wrap gap-5'>
                                {mySkills.length > 0 ? (
                                    mySkills.map((skill, index) => (
                                        <button className='flex align-items-center justify-content-center h-3rem skills-button' style={{ borderRadius: '30px', border: 'none', padding: "7px 15px" }} key={index}>{skill}</button>
                                    ))
                                ) : (
                                    <span>N/A</span>
                                )}
                            </div>
                        </div>
                        <div className='personal-statement-section'>
                            <h3>Personal Statement</h3>
                            <p>{personalStatement || 'N/A'}</p>
                        </div>
                        <div className='personal-teacher-section mb-4'>
                            <h3>Teacher Notes</h3>
                            <InputTextarea autoResize name="personalStatementNotes" value={personalStatementNotes} style={{ width: '100%', margin: 'auto', position: 'relative', display: 'flex' }} onChange={handleInputChange} rows={5} cols={30} />
                            <div className="pt-3 flex justify-content-end">
                                <Button onClick={handleAddNote}>Add Note</Button>
                            </div>
                        </div>
                    </AccordionTab>

                    <AccordionTab header={<div className='career-sec' style={{ display: 'flex', justifyContent: 'space-between' }}> <div className='career-heading'><span style={{ color: '#000000' }}>Section 2: Career Goals</span> <span className='career-field'> {firstName}'s  Last Update: {careerGoalsUpdateDate}</span></div>
                        <div className="header-icon">
                            <i
                                className={`pi ${activeIndex === 1 ? "pi-minus" : "pi-plus"}`}
                            ></i>
                        </div>
                    </div>}>
                        <div className='career-section mt-3 mb-3'>
                            <div className='results-section'>

                                <div className='result-heading mb-2'>You Science Results</div>
                                {youScienceResults ? (
                                    <>
                                        <div className='flex align-items-center justify-content-start flex-wrap gap-6'>
                                            <div className='line pl-2'>
                                                <span><b>Core Drivers</b></span>
                                                <p> {youScienceResults.coreDrivers || 'N/A'}</p>
                                            </div>
                                            <div className='line pl-2'>
                                                <span><b>Aptitudes</b></span>
                                                <p> {youScienceResults.aptitudes || 'N/A'}</p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <p>N/A</p>
                                )}
                            </div>
                            <div className='riasec-section  '>
                                <div>
                                    <div className='riasec-heading mt-3 mb-2'>RIASEC Results:</div>
                                    {riasecResults ? (
                                        <span className='flex align-items-center justify-content-between line'>{riasecResults.result.join("        ")}</span>
                                    ) : (
                                        <p>N/A</p>
                                    )}
                                </div>

                            </div>
                            <div className='selection-section '>
                                <div className='selection-heading mt-3 mb-2'>Student Career Selection</div>
                                <div className='line'>
                                    <div className='selection-head'> {categoryName || 'N/A'}  </div>
                                </div>
                            </div>

                            <div className='reason-section mb-2'>
                                <div className='reason-heading mt-3 mb-2'>{firstName}'s Reason</div>
                                <div>{careerMessage || 'N/A'}</div>
                            </div>

                            <div className='career-teacher-section mb-3'>
                                <h3 className='teacher-heading mb-2'> Teacher Notes </h3>
                                <InputTextarea autoResize name="careerGoalsNotes" value={careerGoalsNotes} style={{ width: '100%', margin: 'auto', position: 'relative', display: 'flex' }} onChange={handleInputChange} rows={5} cols={30} />
                                <div className="pt-3 flex justify-content-end">
                                    <Button onClick={handleAddNote}>Add Note</Button>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>

                    <AccordionTab header={<div className='academic-section' style={{ display: 'flex', justifyContent: 'space-between' }}> <div className='academic-heading'><span style={{ color: '#000000' }}>Section 3: Academic Goals</span> <span className='academic-field'> {firstName}'s  Last Update: {academicGoalsUpdateDate}</span></div>
                        <div className="header-icon">
                            <i className={`pi ${activeIndex === 2 ? 'pi-minus' : 'pi-plus'}`}></i>
                        </div>
                    </div>}>
                        <div className='flex flex-wrap'>
                            {academicGoalsData.length > 0 ? (
                                academicGoalsData.map((goal, index) => (
                                    <div className='flex align-items-start justify-content-start flex-wrap' key={index}>
                                        <div className='academic-sec'>
                                            <span>{goal.academicGoalsCategoryName} </span>
                                            <p>{goal.goalOptions}</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className='pt-3'>Academic Goals was not selected.</p>
                            )}
                        </div>
                        <div className='academic-teacher-section mt-3 mb-3'>
                            <h3 className='academic-teacher-heading mt-3 mb-2'> Teacher Notes </h3>
                            <InputTextarea autoResize name="academicGoalsNotes" value={academicGoalsNotes} style={{ width: '100%', margin: 'auto', position: 'relative', display: 'flex' }} onChange={handleInputChange} rows={5} cols={30} />
                            <div className="pt-3 flex justify-content-end">
                                <Button onClick={handleAddNote}>Add Note</Button>
                            </div>
                        </div>
                    </AccordionTab>


                    <AccordionTab header={<div className='pathway-section' style={{ display: 'flex', justifyContent: 'space-between' }}> <div className='pathway-heading'><span style={{ color: '#000000' }} >Section 4: Academies Pathways</span> <span className='pathway-field'> {firstName}'s  Last Update: {academiesPathwaysUpdateDate}</span></div>
                        <div className="header-icon">
                            <i
                                className={`pi ${activeIndex === 3 ? "pi-minus" : "pi-plus"}`}
                            ></i>
                        </div></div>}>
                        <div className='pathways-details pt-3 pb-3'>
                            <div className='line'>
                                {academiesPathWayNames.length > 0 ? (
                                    academiesPathWayNames.map((pathway, index) => (
                                        <div key={index}>
                                            <span> <b>{pathway.categoryName}</b></span>
                                            {pathway.pathWay && <p> {pathway.pathWay}</p>}
                                        </div>
                                    ))
                                ) : (
                                    <p className='pt-3'>Academies Pathways was not selected.</p>
                                )}

                            </div>
                            <div className='pathway-teacher-section mt-3'>
                                <h3 className='pathway-teacher-heading mt-3 mb-2'> Teacher Notes </h3>
                                <InputTextarea autoResize name="academiesPathwaysNotes" value={academiesPathwaysNotes} style={{ width: '100%', margin: 'auto', position: 'relative', display: 'flex' }} onChange={handleInputChange} rows={5} cols={30} />
                                <div className="pt-3 flex justify-content-end">
                                    <Button onClick={handleAddNote}>Add Note</Button>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>


                    <AccordionTab header={<div className='progress-section' style={{ display: 'flex', justifyContent: 'space-between' }}> <div className='progress-heading'><span style={{ color: '#000000' }} >Section 5: High School Progress</span><span className='progress-field'> {firstName}'s  Last Update: {highSchoolProgressUpdateDate} </span></div>
                        <div className="header-icon">
                            <i
                                className={`pi ${activeIndex === 4 ? "pi-minus" : "pi-plus"}`}
                            ></i>
                        </div></div>}>


                        <div className='section4'>
                            <div><b>Graduation Requirements</b></div>
                        </div>


                        <div className="GR-view w-full">
                            <div className="circular-bar col-4">
                                <div className="circular-progress-bar">
                                    <CircularProgressbarWithChildren value={creditsCompleted}>
                                        <div>
                                            <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits"><span style={{ fontSize: "32px", fontWeight: "bold" }}>{creditsCompleted}</span> /24</div>
                                            <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits">Credits</div>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </div>
                                <div>
                                    <p>Credits Completed = {creditsCompleted}</p>
                                    <p>Credits Pending = {creditsPending}</p>
                                    <p>Total Credits for Graduation = {totalGraduationCredits}</p>
                                </div>
                            </div>
                            <div className="progressbars-section col-8">
                                <DataTable value={graduationCredits}>
                                    <Column field="area" header="Area" />
                                    <Column field="pendingCredits" header="Pending Credits" />
                                    <Column field="completedCredits" header="Completed Credits" />
                                    <Column field="totalCredits" header="Total Credits" />
                                </DataTable>
                            </div>
                        </div>

                        <div className='section4 top'>
                            <div><b>Courses Completed</b></div>
                        </div>

                        {coursesList?.length > 0 ? (
                            <DataTable value={coursesList}>
                                <Column header="Courses" field="course"></Column>
                                <Column header="Area" field="area"></Column>
                                <Column header="Credit Value" field="creditValue"></Column>
                                <Column header="Status" field="status"></Column>
                            </DataTable>
                        ) :
                            (
                                <div className="px-5">N/A</div>
                            )
                        }

                        <div className='section4 top'>
                            <div><b>AP Scores</b></div>
                        </div>

                        {apScores?.length > 0 ? (
                            <div className='flex flex-wrap w-full p-3'>
                                <DataTable className="datatable-container" value={apScores}>
                                    <Column field="apExam" header="AP Exam"></Column>
                                    <Column field="score" header="Score"></Column>
                                </DataTable>
                            </div>

                        ) :
                            (
                                <div className="px-5">N/A</div>
                            )
                        }

                        <div className='career-teacher-section mb-3'>
                            <h3 className='teacher-heading mb-2'> Teacher Notes </h3>
                            <InputTextarea autoResize name="highSchoolProgressNotes" value={highSchoolProgressNotes} style={{ width: '100%', margin: 'auto', position: 'relative', display: 'flex' }} onChange={handleInputChange} rows={5} cols={30} />
                            <div className="pt-3 flex justify-content-end">
                                <Button onClick={handleAddNote}>Add Note</Button>
                            </div>
                        </div>

                    </AccordionTab>

                    <AccordionTab header={<div className='scores-section' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='scores-heading'>
                            <span style={{ color: '#000000' }}>Section 6: Test Scores</span>
                            <span className='scores-field'> {firstName}'s  Last Update: {testScoresUpdateDate}</span>
                        </div>
                        <div className="header-icon">
                            <i className={`pi ${activeIndex === 5 ? "pi-minus" : "pi-plus"}`}></i>
                        </div>
                    </div>}>

                        <div className='section4 top'>
                            <div><b>ACT Scores</b></div>
                        </div>

                        {actScores.length > 0 ?
                            <div className='flex flex-wrap w-full p-3'>
                                <DataTable value={actScores} tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="date" header="Date" body={(rowData) => {
                                        const dateValue = rowData.date;
                                        if (dateValue === null || dateValue < 0) {
                                            return '-';
                                        } else {
                                            return new Date(dateValue).toLocaleDateString();
                                        }
                                    }}></Column>
                                    <Column field="composite" header="Composite"></Column>
                                    <Column field="english" header="English"></Column>
                                    <Column field="math" header="Math"></Column>
                                    <Column field="reading" header="Reading"></Column>
                                    <Column field="science" header="Science"></Column>
                                    <Column field="writing" header="Writing"></Column>
                                </DataTable>
                            </div>
                            :
                            <div className='pl-6'>N/A</div>
                        }

                        <div className='section4 top'>
                            <div><b>PSAT Scores</b></div>
                        </div>

                        {psatScores.length > 0 ?
                            <div className='flex flex-wrap w-full p-3'>
                                <br />
                                <DataTable value={psatScores} tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="date" header="Date" body={(rowData) => {
                                        const dateValue = rowData.date;
                                        if (dateValue === null || dateValue < 0) {
                                            return '-';
                                        } else {
                                            return new Date(dateValue).toLocaleDateString();
                                        }
                                    }}></Column>
                                    <Column field="total" header="Total" body={(rowData) => calculateTotal(rowData)}></Column>
                                    <Column field="evidenceBasedReadingAndWriting" header="EB R/W"></Column>
                                    <Column field="math" header="Math"></Column>
                                    <Column field="essay_CR" header="Essay-CR"></Column>
                                    <Column field="essay_M" header="Essay-M"></Column>
                                    <Column field="essay_W" header="Essay-W"></Column>
                                </DataTable>
                            </div>
                            :
                            <div className='pl-6'>N/A</div>
                        }

                        <div className='section4 top'>
                            <div><b>SAT Scores</b></div>
                        </div>

                        {satScores.length > 0 ?
                            <div className='flex flex-wrap w-full p-3'>
                                <br />
                                <DataTable value={satScores} tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="date" header="Date" body={(rowData) => {
                                        const dateValue = rowData.date;
                                        if (dateValue === null || dateValue < 0) {
                                            return '-';
                                        } else {
                                            return new Date(dateValue).toLocaleDateString();
                                        }
                                    }}></Column>
                                    <Column field="total" header="Total" body={(rowData) => calculateTotal(rowData)}></Column>
                                    <Column field="evidenceBasedReadingAndWriting" header="EB R/W"></Column>
                                    <Column field="math" header="Math"></Column>
                                    <Column field="essay_CR" header="Essay-CR"></Column>
                                    <Column field="essay_M" header="Essay-M"></Column>
                                    <Column field="essay_W" header="Essay-W"></Column>
                                </DataTable>
                                <br />
                            </div>
                            :
                            <div className='pl-6'>N/A</div>
                        }

                        <div className='scores-teacher-section mt-3 mb-3'>
                            <h3 className='scores-teacher-heading mt-3 mb-2'> Teacher Notes </h3>
                            <InputTextarea autoResize name="testScoresNotes" value={testScoresNotes} style={{ width: '100%', margin: 'auto', position: 'relative', display: 'flex' }} onChange={handleInputChange} rows={5} cols={30} />
                            <div className="pt-3 flex justify-content-end">
                                <Button onClick={handleAddNote}>Add Note</Button>
                            </div>
                        </div>
                    </AccordionTab>

                    <AccordionTab header={<div className='activities-section' style={{ display: 'flex', justifyContent: 'space-between' }}> <div className='activities-heading'><span style={{ color: '#000000' }} >Section 7: Extra Activities</span><span className='activities-field'> {firstName}'s  Last Update: {extraActivitiesUpdateDate}</span></div>
                        <div className="header-icon">
                            <i
                                className={`pi ${activeIndex === 6 ? "pi-minus" : "pi-plus"}`}
                            ></i>
                        </div></div>}>
                        <div className='section4 top'>
                            <div><b>Achievements</b></div>
                        </div>
                        {achievements?.length > 0 ? (
                            <DataTable value={achievements} tableStyle={{ minWidth: '50rem' }}>
                                <Column field="achievement" header="Achievements"></Column>
                                <Column header="Date" body={(rowData) => ((moment.utc(rowData?.date).format('MM/YYYY')))}  ></Column>
                                <Column field="description" header="Description"></Column>
                            </DataTable>
                        ) :
                            <div className='pl-6'>N/A</div>
                        }

                        <div className='section4 top'>
                            <div><b>Extracurricular Activities</b></div>
                        </div>

                        {extraCurricularActivities?.length > 0 ? (
                            <DataTable value={extraCurricularActivities} tableStyle={{ minWidth: '50rem' }}>
                                <Column field="extraCurricularActivity" header="Extracurricular Activities"></Column>
                                <Column field="positionHeld" header="Position Held"></Column>
                                <Column field="startDate" header="Start Date" body={(rowData) => moment(rowData.startDate).format('MM/YYYY')} />
                                <Column field="endDate" header="End Date" body={(rowData) => renderCell(rowData, 'endDate','extraCurricularActivities')} />
                                <Column field="description" header="Description"></Column>
                            </DataTable>
                        ) :
                            <div className='pl-6'>N/A</div>
                        }

                        <div className='section4 top'>
                            <div><b>Community Services</b></div>
                        </div>

                        {communityServices?.length > 0 ? (
                            <DataTable value={communityServices} tableStyle={{ minWidth: '50rem' }}>
                                <Column field="communityService" header="Community Service" />
                                <Column field="place" header="Place" />
                                <Column field="startDate" header="Start Date" body={(rowData) => moment(rowData.startDate).format('MM/YYYY')} />
                                <Column field="endDate" header="End Date" body={(rowData) => renderCell(rowData, 'endDate','communityServices')} />
                                <Column field="totalHours" header="Total Hours" />
                                <Column field="description" header="Description" />
                            </DataTable>
                        ) :
                            <div className='pl-6'>N/A</div>
                        }

                        <div className='section4 top'>
                            <div><b> Work/Internship Experience </b></div>
                        </div>

                        <div className='w-full'>
                            {jobExperiences?.length > 0 ? (
                                <DataTable value={jobExperiences} tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="jobTitle" header="Job Title"></Column>
                                    <Column field="company" header="Company"></Column>
                                    <Column field="startDate" header="Start Date" body={(rowData) => moment(rowData.startDate).format('MM/YYYY')} />
                                    <Column field="endDate" header="End Date" body={(rowData) => renderCell(rowData, 'endDate','jobExperiences')} />
                                    {/* <Column field="totalHours" header="Total Hours"></Column> */}
                                    <Column field="description" header="Description"></Column>
                                </DataTable>
                            ) :
                                <div className='pl-6'>N/A</div>
                            }
                        </div>

                        <div className='section4 top'>
                            <div><b>Letter of Recommendations</b></div>
                        </div>
                        <div className='w-full'>
                            {recommendations?.length > 0 ? (
                                <DataTable value={recommendations} tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="name" header="Name of the Person"></Column>
                                    <Column field="relationship" header="Relationship"></Column>
                                    <Column field="email" header=" E-mail"></Column>
                                </DataTable>
                            ) :
                                <div className='pl-6'>N/A</div>
                            }
                        </div>

                        <div className='activities-teacher-section mt-3 mb-3'>
                            <h3 className='activities-teacher-heading mt-3 mb-2'> Teacher Notes </h3>
                            <InputTextarea autoResize name="extraActivitiesNotes" value={extraActivitiesNotes} style={{ width: '100%', margin: 'auto', position: 'relative', display: 'flex' }} onChange={handleInputChange} rows={5} cols={30} />
                            <div className="pt-3 flex justify-content-end">
                                <Button onClick={handleAddNote}>Add Note</Button>
                            </div>
                        </div>

                    </AccordionTab>
                </Accordion >
            </div >

            {/* Dialog box */}



        </div >
    );
};

export default StudentInformation