import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import Waitingimg from '../../Assets/Wait-teachers-ilustration.svg';
import '../../Shared-Component/PieChart/PieChart.scss';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';

const PieChart = ({ pieChartData, isDataAvailable }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [showDivider, setShowDivider] = useState(false);
    const [selectedOptionName, setSelectedOptionName] = useState("");
    const [data, setData] = useState(false);

    const careerPlans = [
        {
            name: "Educational Goal",
            labels: [
                "High School Diploma",
                "Technical or vocational certificate",
                "Graduate degree (MD, MA, JD, PhD, MBA)",
                "Two-year college degree (AA/AS)",
                "Four-year college degree (BA/BS)",
            ],
            data: Object.values(pieChartData?.educationalGoalResponse || {}),
        },
        {
            name: "Cumulative GPA Goal",
            labels: [
                "Cum Laude – 3.0 to 3.5",
                "Magna Cum Laude – 3.5+ to 3.8",
                "Summa Cum Laude – 3.8+",
                "Valedictorian – 4.0+ and met requirements of one Honors Recognition Certificate ",
            ],
            data: Object.values(pieChartData?.cumulativeGPAGoalResponse || {}),
        },
        {
            name: "Certificate Goal",
            labels: ["Academic Honors", "STEM Honors", "CTE Honors"],
            data: Object.values(pieChartData?.certificateGoalResponse || {}),
        },
        {
            name: "After HS Plan",
            labels: [
                "Go to a vocational or trade school",
                "Go to a four-year college/University*",
                "Find a Job",
                "Go to a two-year college/Community College*",
                "Join the military",
                "I don't know",
            ],
            data: Object.values(pieChartData?.highSchoolPlanResponse || {}),
        },
    ];

    const [selectedCareerPlan, setSelectedCareerPlan] = useState(careerPlans[0]);

    const chartDataMap = careerPlans.reduce((allgoals, plan) => {
        allgoals[plan.name] = {
            labels: plan.labels,
            datasets: [
                {
                    data: plan.data,
                    backgroundColor: ['#3B8A6E', '#91BF82', '#FC5145', '#EBB448', '#FFB6B6', '#6C63FF', '#FFD700', '#F943BC', '#7FFF00'],

                    borderWidth: [0],
                }
            ]
        };
        return allgoals;
    }, []);



    useEffect(() => {
        const handleResize = () => {
            if (selectedCareerPlan) {
                setChartData(chartDataMap[selectedCareerPlan.name]);
                if (window.innerWidth < 768) {
                    setChartOptions({
                        plugins: {
                            legend: {
                                position: 'bottom',
                                labels: {
                                    usePointStyle: true,
                                    padding: 25,
                                    borderWidth: 2,
                                    borderColor: 'rgba(0, 0, 0, 0.2)',
                                },
                            },
                        },
                    });

                    setShowDivider(true);
                    setSelectedOptionName(selectedCareerPlan.name);
                } else {
                    setChartOptions({
                        plugins: {
                            legend: {
                                position: 'right',
                                labels: {
                                    usePointStyle: true,
                                    padding: 25,
                                    borderWidth: 2,
                                    borderColor: 'rgba(0, 0, 0, 0.2)',
                                },
                            },
                        },
                    });

                    setShowDivider(true);
                    setSelectedOptionName(selectedCareerPlan.name);
                }
            } else {
                setShowDivider(false);
                setSelectedOptionName("");
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [selectedCareerPlan]);

    useEffect(() => {
        if (selectedCareerPlan) {
            const selectedPlan = careerPlans.find(plan => plan.name === selectedCareerPlan.name);
            const hasData = selectedPlan.data.some(value => value !== 0);
            setData(hasData);
        }
    }, [selectedCareerPlan, careerPlans]);
    

    return (
        <div className='pie-chart-container h-full'>
            <Card className='pie-card-section' >
                <div className='dropdown-section flex justify-content-between align-items-center flex-auto px-4 py-4'>
                    <div>
                        <h3><b>{isDataAvailable ? selectedOptionName : ""}</b></h3>
                    </div>
                    <div>
                        {isDataAvailable ?
                            <Dropdown
                                value={selectedCareerPlan}
                                onChange={(e) => setSelectedCareerPlan(e.value)}
                                options={careerPlans}
                                optionLabel="name"
                                placeholder="Select One"
                                className="sm:w-15rem"
                                disabled={!isDataAvailable}
                            />
                            :
                            <Dropdown
                                value={!selectedCareerPlan}
                                onChange={(e) => setSelectedCareerPlan(e.value)}
                                options={careerPlans}
                                optionLabel="name"
                                placeholder="Select One"
                                className="sm:w-15rem"
                                disabled={!isDataAvailable}
                            />
                        }
                    </div>
                </div>

                {showDivider && <Divider />}
                {selectedCareerPlan && isDataAvailable ? (
                    <div>
                        {data ? (
                            <div className='pie-chart-section' style={{ height: '67vh' }}>
                                <div className="card flex justify-content-center align-items-center pie" >
                                    <Chart
                                        type="pie"
                                        data={chartDataMap[selectedCareerPlan.name]}
                                        options={chartOptions}
                                        className="pie-chart"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className='pie-chart-section' style={{ height: '67vh', display: "grid", justifyContent: "center" }}>
                                <div className='card flex align-items-center justify-content-center'>
                                    <p>No data available for {selectedCareerPlan.name}</p>
                                </div>
                                <div className='wait-img flex align-items-center justify-content-center p-3 '>
                                    <img src={Waitingimg} alt='Loginimage' />
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className='p-6 wait-section'>
                        <div className='wait-img flex align-items-center justify-content-center p-3 '>
                            <img src={Waitingimg} alt='Loginimage' />
                        </div>
                        <div className='wait-content flex align-items-center justify-content-center w-auto'>
                            <p>Once students fill out their 10-year plan, you will start seeing information here. </p>
                        </div>
                    </div>
                )}
            </Card>
        </div>
    );
}

export default PieChart;




