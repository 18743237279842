import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import '../Help.scss';
import LaulamaGuides from '../Laulama-Guides/LaulamaGuides';
import PersonalStatement from '../Personal-Statement/PersonalStatement';
import Courses from '../Courses/Courses';
import { LuAtom, LuPencilLine } from 'react-icons/lu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faFile, faFilePdf, faFilePen, faFileVideo, faFileWord, faFolderOpen, faList, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Resume from '../Resume/Resume';
import PTPResources from '../PTP-Resources/PTPResources';
import HelpResources from '../Helps-Resources/HelpsResources';

const SubCategory = ({ selectedCategory, handleCategoryClick }) => {
    const [activeIndex, setActiveIndex] = useState(null);


    const toggleAccordion = (index) => {
        const newIndex = activeIndex === index ? null : index;
        setActiveIndex(newIndex);
        if (newIndex !== null) {
            const selectedCategory = CategoryItems[index]?.label?.toLowerCase().replace(/\s+/g, '');
            window.history.pushState(null, null, `/student/help/${selectedCategory}`);
        }
    };
    

    const CategoryItems = [
        {
            label: 'Laulama Guides',
            component: < LaulamaGuides />,
        },
        {
            label: 'Personal Statement',
            component: <PersonalStatement />,
        },
        {
            label: 'Courses',
            component: <Courses />,
        },
        {
            label: 'Resume',
            component: <Resume />,
        },
        {
            label: 'PTP Resources',
            component: <PTPResources />,
        },
        {
            label: 'Help Resources',
            component: <HelpResources />,
        },

    ];

    return (
        <>
            <div className='sub-category'>
                <Link to='/student/help/laulamaGuides' style={{ textDecoration: "none", color: "black" }}>  <p onClick={() => handleCategoryClick('Laulama Guides')} style={{ backgroundColor: selectedCategory === 'Laulama Guides' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}><b>Laulama Guides</b></p></Link>
                <Link to='/student/help/personalStatement' style={{ textDecoration: "none", color: "black" }}> <p onClick={() => handleCategoryClick('Personal Statement')} style={{ backgroundColor: selectedCategory === 'Personal Statement' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}><b>Personal Statement</b></p></Link>
                <Link to='/student/help/courses' style={{ textDecoration: "none", color: "black" }}> <p onClick={() => handleCategoryClick('Courses')} style={{ backgroundColor: selectedCategory === 'Courses' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}><b>Courses</b></p></Link>
                <Link to='/student/help/resume' style={{ textDecoration: "none", color: "black" }}> <p onClick={() => handleCategoryClick('Resume')} style={{ backgroundColor: selectedCategory === 'Resume' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}><b>Resume</b></p></Link>
                <Link to='/student/help/ptpResources' style={{ textDecoration: "none", color: "black" }}> <p onClick={() => handleCategoryClick('PTP Resources')} style={{ backgroundColor: selectedCategory === 'PTP Resources' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}><b>PTP Resources</b></p></Link>
                <Link to='/student/help/helpResources' style={{ textDecoration: "none", color: "black" }}>  <p onClick={() => handleCategoryClick('Help Resources')} style={{ backgroundColor: selectedCategory === 'Help Resources' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}><b>More Helps Resourses</b></p></Link>
            </div>

            <div className='mobile-sub-category'>
                <Accordion activeIndex={activeIndex} onTabChange={(e) => toggleAccordion(e.index)}>
                    {CategoryItems.map((item, index) => (
                        <AccordionTab key={index} header={
                            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                                <div className="header-text">{item.label}</div>
                                <div className="header-icon">
                                    <i className={`pi ${activeIndex === index ? "pi-minus" : "pi-plus"}`}></i>
                                </div>
                            </div>
                        }>
                            {item.label === 'Laulama Guides' && (
                                <div className='col-9 mob-guides-section pl-5'>
                                    <div className='guides'>
                                        <div className="guides-collections">
                                            <div className='guidesdetails'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <LuPencilLine className='mr-3' /><h3>Students Dashboard Guideline</h3>
                                                </div>
                                                <div className='heading p-1'>Clear instructions for using your student dashboard effectively.</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faFilePdf} className='mr-3'/>
                                                    <a href="https://staticfile.laulamaschools.com/Student+Dashboard+Guideline.pdf" target='_blank' download> Download PDF</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {item.label === 'Personal Statement' && (
                                <div className='col-9 mob-guides-section pl-5'>
                                    <div className='personal'>
                                        <div className="guides-collections ">
                                            <div className='guidesdetails mb-3'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <FontAwesomeIcon icon={faFilePen} className='filepen mr-3' /><h3>Personal Statement Paragraph</h3>
                                                </div>
                                                <div className='heading p-1'>Your opportunity to share your unique story and aspirations.</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faFilePdf} className='mr-3' />
                                                    <a href="https://staticfile.laulamaschools.com/Personal+Statement+Paragraph+Frame.pdf" target="_blank" download>Download PDF</a>
                                                </div>
                                            </div>
                                            <div className='guidesdetails mb-3'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <LuAtom className='atom mr-3' /><h3>You Science</h3>
                                                </div>
                                                <div className='heading p-1'>Video of how to LOG IN TO you science ACCOUNT</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-3' />
                                                    <a href="https://sites.google.com/k12.hi.us/class-of-2025-ptp/you-science" target="_blank">External Link</a>
                                                </div>
                                            </div>
                                            <div className='guidesdetails mb-3'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <LuAtom className='atom-two mr-3' /><h3>Video: You Science</h3>
                                                </div>
                                                <div className='heading p-1'>Summit Results Walkthrough</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faFileVideo} className='mr-3' />
                                                    <a href="https://vimeo.com/347806606" target="_blank">Open Video</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {item.label === 'Courses' && (
                                <div className='col-9 mob-guides-section pl-5'>
                                    <div className='courses'>
                                        <div className="guides-collections ">
                                            <div className='guidesdetails'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <FontAwesomeIcon icon={faList} className='list mr-3' /><h3>Courses List</h3>
                                                </div>
                                                <div className='heading p-1'>High School Courses Information</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faFilePdf} className='mr-3' />
                                                    <a href="https://staticfile.laulamaschools.com/Kauia+Courses+List.pdf" target="_blank" download>Download PDF</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {item.label === 'Resume' && (
                                <div className='col-9 mob-guides-section pl-5'>
                                    <div className='resume'>
                                        <div className="guides-collections ">
                                            <div className='guidesdetails mb-3'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <FontAwesomeIcon icon={faFile} className='file mr-3' /><h3>Resume Guide</h3>
                                                </div>
                                                <div className='heading p-1'>Crafting a professional summary of your achievements and skills.</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faFileVideo} className='mr-3' />
                                                    <a href="https://drive.google.com/file/d/1XljFQ2ssj0cMMOsjRpH0_LWGUAc_qLOt/view?t=143" target="_blank">Open Video</a>
                                                </div>
                                            </div>
                                            <div className='guidesdetails mb-3'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <FontAwesomeIcon icon={faPaperclip} className='clip-1 mr-3' /><h3>Resume Template Example</h3>
                                                </div>
                                                <div className='heading p-1'>Explore additional resume examples for inspiration.</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-3' />
                                                    <a href="https://create.microsoft.com/en-us/template/restaurant-manager-resume-57cae682-222c-4646-9a80-c404ee5c5d7e" target='_blank'>External Link</a>
                                                </div>
                                            </div>
                                            <div className='guidesdetails mb-3'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <FontAwesomeIcon icon={faPaperclip} className='clip-2 mr-3' /><h3>Resume Template Example 2</h3>
                                                </div>
                                                <div className='heading p-1'>Document showcasing examples.</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-3' />
                                                    <a href="https://create.microsoft.com/en-us/template/project-management-resume-5ff67a28-3747-45d8-ab40-465bf569783b" target='_blank'>External Link</a>
                                                </div>
                                            </div>
                                            <div className='guidesdetails mb-3'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <FontAwesomeIcon icon={faPaperclip} className='clip-3 mr-3' /><h3>Resume Template Example 3</h3>
                                                </div>
                                                <div className='heading p-1'>Document showcasing examples.</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-3' />
                                                    <a href="https://create.microsoft.com/en-us/template/basic-sales-resume-7af668a8-d9d4-4a54-989e-422a9b87280f" target='_blank'>External Link</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {item.label === 'PTP Resources' && (
                                <div className='col-9 mob-guides-section pl-5'>
                                    <div className='resources'>
                                        <div className="guides-collections ">
                                            <div className='guidesdetails mb-3'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <FontAwesomeIcon icon={faFolderOpen} className='open-1 mr-3' /><h3>Post-Secondary Plan</h3>
                                                </div>
                                                <div className='heading p-1'>A description for this section</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-3' />
                                                    <a href="https://sites.google.com/k12.hi.us/class-of-2025-ptp/post-secondary-plan" target='_blank'>External Link</a>
                                                </div>
                                            </div>
                                            <div className='guidesdetails'>
                                                <div className='img flex align-items-center justify-content-evenly'>
                                                    <FontAwesomeIcon icon={faFolderOpen} className='open-2 mr-3' /><h3>Post-Secondary Plan 2</h3>
                                                </div>
                                                <div className='heading p-1'>A description for this section</div>
                                                <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faFileWord} className='mr-3' />
                                                    <a href="https://staticfile.laulamaschools.com/Post-Secondary+Plan+Draft+2024.docx" target="_blank" download>Download Word</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {item.label === 'Help Resources' && (
                                <div className='col-9 mob-guides-section pl-5'>
                                    <div className='help_resources'>
                                        <div className="guides-collections ">
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <p>We will show here More Help Resources once we have more information.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </AccordionTab>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

export default SubCategory;
