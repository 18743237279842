import React, { useState } from 'react'
import './ContactUs.scss';
import { useNavigate } from 'react-router-dom';
import Contactus from '../../Assets/Contact-Us.png';
import { ScrollTop } from 'primereact/scrolltop';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import NewHeader from '../Home/NewHeader/NewHeader';
import FooterSection from '../Home/laulama-footer-section/FooterSection';
import { Checkbox } from 'primereact/checkbox';
import { Contact_Us, CONTENT_HEADERS } from '../../Utils/LandingService';
import axios from 'axios';
import CalendlyComponent from '../../Shared-Component/Calendly/Calendly';


const ContactUs = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        phoneNumberType: '',
        category: '',
        description: '',
        starTtime: '',
        endTime: '',
        selectedDays: [],
        termsChecked: false,
    });

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onStartTimeChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            starTtime: e.value,
        }));
    };

    const onEndTimeChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            endTime: e.value,
        }));
    };

    const phoneNumberTypes = [
        { label: 'Mobile', value: 'Mobile' },
        { label: 'Home', value: 'Home' },
        // Add more types as needed
    ];

    const categories = [
        { label: 'General Inquiry', value: 'General Inquiry' },
        { label: 'Support', value: 'Support' },
        // Add more categories as needed
    ];

    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

    const [selectedDays, setSelectedDays] = useState([]);

    const handleCheckboxChange = (day) => {
        const { selectedDays } = formData;
        if (selectedDays.includes(day)) {
            setFormData({
                ...formData,
                selectedDays: selectedDays.filter((selectedDay) => selectedDay !== day),
            });
        } else {
            setFormData({
                ...formData,
                selectedDays: [...selectedDays, day],
            });
        }
    };

    const onSubmit = () => {
        const clientFormData = {
            name: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            type: formData.phoneNumberType,
            category: formData.category,
            description: formData.description,
            contactHours: formData.starTtime + '-' + formData.endTime,
            daysOfWeek: formData.selectedDays,
        };

        axios
            .post(Contact_Us, clientFormData, { headers: CONTENT_HEADERS })
            .then(() => {
                navigate('/');
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            termsChecked: e.checked,
        });
    }

    return (
        <>
            <ScrollTop />
            <NewHeader />
            <div className='contact-us'>
                <div>
                    <div className="grid m-0">
                        <div className="contact-left-section field col-12 md:col-12 lg:col-6">
                            <div className='info'>
                                <div className='letsTalk'>
                                    Let's Talk
                                </div>
                                <div className='cu-sub-text'>Let's foster a communication culture that transcends boundaries and builds a support network for educational success, whether you're a student, parent, teacher, principal, or administrator.
                                    Your voice is essential to our journey – let's talk and learn together.</div>
                                <div>
                                    <div className='emailname'>Make an appoinment with us</div>
                                    <div className='calendly-component'><CalendlyComponent /></div>
                                </div>
                                <div className='email-info'>
                                    <div className='emailname'>Call Us on</div>
                                    <div className='email-id'>(808)-342-9783</div>
                                </div>
                                <div className='socials'>Follow Us on</div>
                                <div className='social-profile'>Instagram</div>
                                <div className='social-profile'>Twitter</div>
                                <div className='social-profile'>Facebook</div>
                            </div>
                        </div>
                        <div className="contact-right-section field col-12 md:col-12 lg:col-6">
                            <div className="header-image">
                                <img className='contactus-img' src={Contactus} />
                            </div>
                            <div className="client-form">
                                <div className="grid">
                                    <div className="field col-12 md:col-6 flex flex-column">
                                        <label htmlFor="firstName">First Name</label>
                                        <InputText id="firstName" name="firstName" value={formData.firstName} onChange={onInputChange} />
                                    </div>
                                    <div className="field col-12 md:col-6 flex flex-column">
                                        <label htmlFor="lastName">Last Name</label>
                                        <InputText id="lastName" name="lastName" value={formData.lastName} onChange={onInputChange} />
                                    </div>

                                    <div className="field col-12 flex flex-column">
                                        <label htmlFor="email">Email</label>
                                        <InputText id="email" name="email" value={formData.email} onChange={onInputChange} />
                                    </div>
                                    <div className="field col-12 flex flex-column">
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        <InputMask
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            mask="(999) 999-9999"
                                            value={formData.phoneNumber}
                                            onChange={onInputChange}
                                        />
                                    </div>
                                    {/* <div className="field col-12 md:col-6 flex flex-column gap-2">
                                        <label htmlFor="phoneNumberType">Phone Number Type</label>
                                        <Dropdown
                                            id="phoneNumberType"
                                            name="phoneNumberType"
                                            options={phoneNumberTypes}
                                            value={formData.phoneNumberType}
                                            onChange={onInputChange}
                                            placeholder="Select a type"
                                        />
                                    </div> */}
                                    {/* <div className="field col-12 flex flex-column gap-2">
                                        <label htmlFor="category">Category</label>
                                        <Dropdown
                                            id="category"
                                            name="category"
                                            options={categories}
                                            value={formData.category}
                                            onChange={onInputChange}
                                            placeholder="Select a category"
                                        />
                                    </div> */}
                                    {/* <div className="field col-12 flex flex-column gap-2">
                                        <label htmlFor="description">Description</label>
                                        <InputTextarea id="description" name="description" rows={6} value={formData.description} onChange={onInputChange} />
                                    </div> */}
                                    {/* <div className="field flex flex-column gap-2">
                                        <label htmlFor="calendar-timeonly">Contact Hours</label>
                                        <div className="field flex flex-wrap gap-5">
                                            <div className="flex flex-wrap justify-content-center gap-1">
                                                <Calendar id="calendar-timeonly" name="starttime" value={formData.starTtime} onChange={onStartTimeChange} timeOnly style={{ width: '10vw' }} />
                                                <div className='mt-2'>-</div>
                                                <Calendar id="timePicker" name="endtime" value={formData.endTime} onChange={onEndTimeChange} timeOnly showTime style={{ width: '10vw' }} />
                                            </div>
                                            <div className="flex flex-wrap justify-content-center mt-2 gap-3">
                                                {daysOfWeek.map((day) => (
                                                    <div key={day} className="p-field-checkbox">
                                                        <Checkbox className=''
                                                            inputId={day}
                                                            value={day}
                                                            onChange={() => handleCheckboxChange(day)}
                                                            checked={formData.selectedDays.includes(day)}
                                                        />
                                                        <label htmlFor={day} className='contactus-checkbox'>{day}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="flex align-items-center gap-2">
                                        <Checkbox
                                            inputId="terms"
                                            checked={formData.termsChecked}
                                            onChange={handleChange}
                                        ></Checkbox>
                                        <label htmlFor="terms">
                                            I’ve read the{" "}
                                            <a href="" target="_blank" >Privacy Policy</a>
                                        </label>
                                    </div> */}
                                </div>
                                <div className="contactus-footer-buttons">
                                    {/* <Button className='mr-3' label="Cancel" severity="secondary" /> */}
                                    <Button type="submit" label="Send me more information" severity="primary" onClick={onSubmit} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <FooterSection />
        </>
    )
}
export default ContactUs