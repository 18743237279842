// Base Url
export const BASE_URL = `${process.env.REACT_APP_URL}`;

// export const BASE_URL = 'https://backend.devl.laulamaschools.com/laulama';

// Headers
export const AUTHORIZATION_HEADERS = {
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};
export const CREATE_EXCEL_URL_HEADERS = {
    'Content-Type': 'multipart/form-data',
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};

export const CONTENT_HEADERS = {
    "content-type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};

// API Endpoints

export const GET_TOTAL_NUMBER_OF_USERS_URL = `${BASE_URL}/users/userStats/`;

export const CREATE_EXCEL_URL = `${BASE_URL}/admin/upload/users`;

export const CREATE_GOAL_URL = `${BASE_URL}/group`;

export const GET_GROUP_URL = `${BASE_URL}/group`;

export const UPDATE_GROUP_URL = `${BASE_URL}/group/`;

export const DELETE_GROUP_URL = `${BASE_URL}/admin/group/`

export const CREATE_USER_URL = `${BASE_URL}/admin/create/users`

export const UPDATE_USER_URL = `${BASE_URL}/admin/create/users`

export const GET_STUDENT_URL = `${BASE_URL}/users/group`

export const ASSIGN_GROUP_URL = `${BASE_URL}/assign/group`

export const GET_SCHOOL_URL = `${BASE_URL}/schools`

export const SEND_EMAIL_URL = `${BASE_URL}/notification/`

export const SEND_BULK_EMAIL_URL = `${BASE_URL}/notification/group/send`

export const DELETE_USER_URL = `${BASE_URL}/admin/delete_user/`