import React from 'react';
import './AboutusBanner.scss';
import AboutBannerImg from '../../../Assets/AboutUs/aboutbanner.png';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const AboutusBanner = () => {
    return (
        <div className='aboutus-section'>
            <div className='aboutus-img'>
                <div className='bg'>
                    <div className='overlay'></div>
                    <img src={AboutBannerImg} alt='AboutBannerImg' />
                    <div className='aboutus-para-section'>
                        <p>
                            <span className="quote-icon"><FaQuoteLeft /></span>
                            Wisdom, knowledge and collaboration across many sources working together to create a better system for everyone.
                            <span className="quote-icon"><FaQuoteRight /></span>
                        </p>
                    </div>
                    <div className='aboutus-name'>
                        <p>-Mel Horikami, Laulama CEO</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutusBanner
