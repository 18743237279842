// Base Url
export const BASE_URL = `${process.env.REACT_APP_URL}`;

// export const BASE_URL = 'https://backend.devl.laulamaschools.com/laulama';

// Headers
export const AUTHORIZATION_HEADERS = {
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};

export const CONTENT_HEADERS = {
    "content-type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};

export const UNIQUE_ID_URL = `${BASE_URL}/uniqueId`;

export const UNIQUE_ID_HEADERS = {
    "content-type": "application/json",
};

// API endpoints

export const SIGNUP_URL = `${BASE_URL}/users/sign_up`;

export const SIGNUP_URL_HEADERS = {
    'Content-Type': 'application/json',
};

export const CONFIRMATION_URL = `${BASE_URL}/sign_up/confirmation`;

export const RESEND_CONFIRMATION = `${BASE_URL}/resend/confirmation_code/`;

export const CONFIRMATION_URL_HEADERS = {
    'Content-Type': 'application/json',
};

export const LOGIN_URL = `${BASE_URL}/log_in`;

export const LOGIN_URL_HEADERS = {
    'Content-Type': 'application/json',
};

export const REFRESH_TOKEN_URL = `${BASE_URL}/refresh_token/`;

export const REFRESH_TOKEN_URL_HEADERS = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};

export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgot_password/resend_code`;

export const RESET_PASSWORD_URL = `${BASE_URL}/forgot_password`;

export const LOGOUT_URL = `${BASE_URL}/sign_out/${localStorage.getItem('email')}`;

export const DELETE_URL = `${BASE_URL}/users/delete/`;