import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import { CONTENT_HEADERS, GET_PRINCIPAL_DETAILS_URL } from '../../../Utils/PrincipalService';
import "../ViewCard.scss";
import { OverlayPanel } from 'primereact/overlaypanel';
import '../../../index.scss';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';

const TeacherDataTable = () => {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });
    const [teacherList, setTeacher] = useState('');
    const [teacherLists, setTeachers] = useState([]);
    const [processedData, setProcessedData] = useState([]);

    useEffect(() => {
        fetchPrincipalDetails();
    }, []);

    const fetchPrincipalDetails = () => {
        const principalId = localStorage.getItem('userId');
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });
        axios
            .get(GET_PRINCIPAL_DETAILS_URL + principalId, { headers: CONTENT_HEADERS })
            .then((response) => {
                setData(response.data.groupResponses);
                setLoading(false);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
            })
            .catch((error) => {
                setLoading(true);
                console.log(error);
            });
    };

    const overlayPanelRef = useRef(null);

    const showTeacherList = (teachers) => {
        const teacherAvatars = teachers.map((teacher, index) => (
            <div key={index} className="teacher-info">
                <div className="avatar-container">
                    {teacher.gender === 'Male' || teacher.gender === 'M' ? (
                        <Avatar
                            image={require(`../../../Assets/Avatar/StudentProfile.png`)}
                            style={{ width: 40, height: 40 }}
                            size="large"
                            shape="circle"
                        />
                    ) : teacher.gender === 'Female' || teacher.gender === 'F' ? (
                        <Avatar
                            image={require(`../../../Assets/Avatar/TeacherProfile.png`)}
                            style={{ width: 40, height: 40 }}
                            size="large"
                            shape="circle"
                        />
                    ) : (
                        <Avatar icon="pi pi-user" size="xlarge" shape="circle" />
                    )}
                </div>
            </div>
        ));

        const teacherNames = teachers.map((teacher) => `${teacher.firstName} ${teacher.lastName}`);
        const teacherItems = teacherNames.map((name, index) => <li key={index}>{name}</li>);

        const display = (
            <div className="teacher-list-display">
                <div className="avatar-list">{teacherAvatars}</div>
                <div className="teacher-names">{teacherItems}</div>
            </div>
        );

        setTeachers(display);
    };


    const listTemplate = (list) => {
        const showAllTeachers = (event) => {
            showTeacherList(list.assignedTeachers);
            overlayPanelRef.current.toggle(event);
        };

        const MAX_TEACHERS_TO_DISPLAY = 3;
        const teachersToDisplay = list.assignedTeachers.slice(0, MAX_TEACHERS_TO_DISPLAY);
        const additionalTeachersCount = list.assignedTeachers.length - 0;
        const firstTeacherName = teachersToDisplay.length > 0 ? `${teachersToDisplay[0].firstName}` : '';
        setTeacher(firstTeacherName);

        return (
            <div className="avatars-label" style={{ cursor: 'pointer' }} onClick={showAllTeachers}>
                {teachersToDisplay.map((teacher, index) => (
                    <div key={index} className="avatar-icon">
                        {teacher.gender === "Male" || teacher.gender === 'M' ? (
                            <Avatar
                                image={require(`../../../Assets/Avatar/StudentProfile.png`)}
                                style={{ width: 40, height: 40 }}
                                size="large"
                                shape="circle"
                            />
                        ) : teacher.gender === 'Female' || teacher.gender === 'F' ? (
                            <Avatar
                                image={require(`../../../Assets/Avatar/TeacherProfile.png`)}
                                style={{ width: 40, height: 40 }}
                                size="large"
                                shape="circle"
                            />
                        ) : (
                            <Avatar icon="pi pi-user" size="xlarge" shape="circle" />
                        )}
                    </div>
                ))}
                {firstTeacherName && <span>{firstTeacherName}</span>}
                {additionalTeachersCount > 1 && <span>...</span>}
            </div>
        );
    };
    const filteredLists = data.filter((list) =>
        String(list.assignedTeacher).toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        if (filteredLists && filteredLists.length > 0) {
            const processedData = filteredLists.map((item, index) => {
                return { ...item, id: index + 1 }; 
            });
            setProcessedData(processedData);
        }
    }, [filteredLists]);
    

    return (
        <div className='Teacher-datatable'>
            <div className='flex justify-content-between align-items-center mx-5'>
                <h3>Groups</h3>
                <span className="p-input-icon-right">
                <div className="flex align-items-center justify-content-end">
                    <InputText placeholder="Search Teacher" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    <i className="pi pi-search" style={{position:"absolute", padding:"15px"}}/>
                    </div>
                </span>
            </div>
            <Card className='mx-5 my-2'>
                <div className="card-section">
                    <div className="spinner-section">
                        {loading ? <Spinner /> : null}
                    </div>
                    <DataTable value={processedData} stripedRows tableStyle={{ minWidth: '50rem' }} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}>
                        <Column field="id" header="ID"/>
                        <Column field="groupName" header="Group" sortable />
                        <Column field="graduationYear" header="Graduation Year" sortable style={{ textAlign: 'center' }} />
                        <Column field="assignedTeacher" header="Assigned Teacher" body={listTemplate} sortable style={{ textAlign: 'left' }} />
                        <Column field="studentCount" header="Number of Students" sortable style={{ textAlign: 'center' }} />
                        <Column field="tenYearPlanNotStarted" header="PTP not Started" sortable style={{ textAlign: 'center' }} />
                        <Column field="tenYearPlanStarted" header="PTP Started" sortable style={{ textAlign: 'center' }} />
                    </DataTable>

                    <OverlayPanel ref={overlayPanelRef} appendTo={document.body}>
                        <ul className="teacher-menu-lists" >
                            {teacherLists}
                        </ul>
                    </OverlayPanel>

                </div>
            </Card>
        </div>
    );
};

export default TeacherDataTable;
