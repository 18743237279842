import React, { useEffect, useState } from 'react'
import "../Home/Home.scss";
import LaulamaHeader from '../Home/LaulamaHeader/LaulamaHeader';
import AboutusBanner from './Aboutus-banner/AboutusBanner';
import Laulamahistory from './Laulama-History/Laulamahistory';
import Laulamafounder from '../AboutUs/Founder/Laulamafounder';
import FooterSection from '../Home/laulama-footer-section/FooterSection';
import LaulamaTeam from '../AboutUs/LaulamaTeam/LaulamaTeam';
import NewHeader from '../Home/NewHeader/NewHeader';
import LaulamaValues from "../Home/Laulama-values/LaulamaValues";
import { useLocation } from 'react-router-dom';
import FAQ from '../FAQ/FAQ';
import Founder from './Founder/Founder';
import MainBanner from './Aboutus-MainBanner/MainBanner';

const AboutUs = () => {

    const location = useLocation();
    const [showContent, setShowContent] = useState(false);
    const [showFounder, setShowFounder] = useState(false);

    useEffect(() => {
        const hash = location.hash;
      
        if (hash) {
          const targetElement = document.querySelector(hash);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
      
          setShowContent(hash === '#frequentlyAskedQuestions');
          setShowFounder(hash === '#Founder');
        } else {
          setShowContent(false);
          setShowFounder(false);
        }
      }, [location.hash]);
      

    return (
        <div>
            {/* <LaulamaHeader /> */}
            <NewHeader />
            {showContent ? (
                <>
                    <FAQ />
                </>
            ) : (
                <>

                    {showFounder ? (
                        <>
                            <Founder />
                            <FooterSection />
                        </>
                    ) : (
                        <>
                            <MainBanner />
                            <LaulamaValues />
                            <AboutusBanner />
                            <Laulamahistory />
                            <Laulamafounder />
                            <LaulamaTeam />
                            <FooterSection />
                        </>
                    )}
                </>

            )}
        </div>
    )
}

export default AboutUs