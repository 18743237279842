import React, { useState, useEffect } from 'react'
import './Extra-Activities-Form.scss';
import "primeicons/primeicons.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import ExtraActivities from './Extra-Activities/extra-activities';
import AwardsRecognitions from './Awards & Recognitions/awards-recognitions';
import CommunityService from './Community-Service/Community-Service';
import WorkInternship from './Work-Internship/Work-Internship';
import WritersLettersRecommendation from './Writers-Letters-Recommendation/writers-letters-recommendation';
import { AUTHORIZATION_HEADERS, CONTENT_HEADERS, EXTRA_ACTIVITIES, GET_EXTRA_ACTIVITIES } from '../../../../../Utils/StudentService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ExtraActivitiesForm = ({ onNextButtonClick, handleBackButtonClick, extraActivityState }) => {

    const navigate = useNavigate();

    function refreshPage() { 
        window.location.reload(); 
    }

    useEffect(() => {
        fetchExtraActivities();
    }, []);

    const [getResponse, setGetResponse] = useState({});
    const [teacherNotes, setTeacherNotes] = useState("");
    const [teacherName, setTeacherName] = useState('');
    const [lastUpdatedOn, setLastUpdatedOn] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    const fetchExtraActivities = () => {
        axios
            .get(GET_EXTRA_ACTIVITIES, { headers: CONTENT_HEADERS })
            .then((response) => {
                setGetResponse(response.data);
                const latestTeacherNotes = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.notes;
                const latestTeacherName = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.teacherName;
                const updatedOn = response.data?.updatedOn ? new Date(response.data?.updatedOn).toLocaleDateString() : null;
                const createdOn = response.data?.createdOn ? new Date(response.data?.createdOn).toLocaleDateString() : null;

                setTeacherNotes(latestTeacherNotes);
                const teacherName = latestTeacherName;
                setTeacherName(teacherName);
                const lastUpdatedOn = updatedOn || createdOn;
                setLastUpdatedOn(lastUpdatedOn);

            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    const extraActivities = [
        {
            label: 'Achievements',
            component: <AwardsRecognitions getApiResponse={getResponse} />,
        },
        {
            label: 'Extracurricular and Co-Curricular Activities',
            component: <ExtraActivities getApiResponse={getResponse} />,
        },
        {
            label: 'Community Service',
            component: <CommunityService getApiResponse={getResponse} />,
        },
        {
            label: 'Work/Internship Experience',
            component: <WorkInternship getApiResponse={getResponse} />,
        },
        {
            label: 'Writers of Letters of Recommendation',
            component: <WritersLettersRecommendation getApiResponse={getResponse} />,
        },
    ];

    const isTabOpen = (index) => activeIndex === index;
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    const handleBackClick = () => {
        if (window.innerWidth > 1008) {
            handleBackButtonClick();
        } else {
            navigate('/student/testScore');
        }
    };

    const handleSubmitClick = () => {

        const achievements_Items = JSON.parse(localStorage.getItem('Achievements'));
        const achievements_FinalItems = achievements_Items && achievements_Items.length > 0 ?
            achievements_Items.map(item => {
                const val = item.date;
                const [month, year] = val.split('/');
                const convertedDate = new Date(`${year}`, month - 1, 1);
                const formattedMonth = month < 10 ? `0${convertedDate.getMonth() + 1}` : convertedDate.getMonth() + 1;
                const formattedDateDay = convertedDate.getDate() < 10 ? `0${convertedDate.getDate()}` : convertedDate.getDate();
                const formattedDate = `${convertedDate.getFullYear()}-${formattedMonth}-${formattedDateDay}`;
                return {
                    achievement: item.achievement,
                    date: formattedDate,
                    description: item?.description,
                };
            }) : [];
        // console.log('local storage Achievements------->', achievements_FinalItems);

        const extra_Activities_Items = JSON.parse(localStorage.getItem('ExtraActivities'));
        const extra_Activities_FinalItems = extra_Activities_Items && extra_Activities_Items.length > 0 ?
            extra_Activities_Items.map(item => {
                const startDateValue = item.startDate;
                const [month, year] = startDateValue.split('/');
                const convertedStartDate = new Date(`${year}`, month - 1, 1);
                const startDateformattedMonth = month < 10 ? `0${convertedStartDate.getMonth() + 1}` : convertedStartDate.getMonth() + 1;
                const startDateformattedDate = convertedStartDate.getDate() < 10 ? `0${convertedStartDate.getDate()}` : convertedStartDate.getDate();
                const formattedStartDate = `${convertedStartDate.getFullYear()}-${startDateformattedMonth}-${startDateformattedDate}`;
                if(item.currentActivity) {
                    var formattedEndDate = null; 
                 } else {
                    const endDateValue = item.endDate;
                    const [month, year] = endDateValue.split('/');
                    const convertedEndDate = new Date(`${year}`, month - 1, 1);
                    const endDateformattedMonth = month < 10 ? `0${convertedEndDate.getMonth() + 1}` : convertedEndDate.getMonth() + 1;
                    const endDateformattedDate = convertedEndDate.getDate() < 10 ? `0${convertedEndDate.getDate()}` : convertedEndDate.getDate();
                    var formattedEndDate = `${convertedEndDate.getFullYear()}-${endDateformattedMonth}-${endDateformattedDate}`;
                 }
            return {
                currentActivity: item.currentActivity,
                extraCurricularActivity: item.extraCurricularActivity,
                positionHeld: item.positionHeld,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                description: item.description,
            };
        }) : [];
        // console.log('local storage Extra Activities------->', extra_Activities_FinalItems);
        
        const community_Services_Items = JSON.parse(localStorage.getItem('CommunityServices'));
        const community_Services_FinalItems = community_Services_Items && community_Services_Items.length > 0 ?
        community_Services_Items.map(item => {
            const startDateValue = item.startDate;
                const [month, year] = startDateValue.split('/');
                const convertedStartDate = new Date(`${year}`, month - 1, 1);
                const startDateformattedMonth = month < 10 ? `0${convertedStartDate.getMonth() + 1}` : convertedStartDate.getMonth() + 1;
                const startDateformattedDate = convertedStartDate.getDate() < 10 ? `0${convertedStartDate.getDate()}` : convertedStartDate.getDate();
                const formattedStartDate = `${convertedStartDate.getFullYear()}-${startDateformattedMonth}-${startDateformattedDate}`;
                if(item.currentService) {
                    var formattedEndDate = null; 
                 } else {
                    const endDateValue = item.endDate;
                    const [month, year] = endDateValue.split('/');
                    const convertedEndDate = new Date(`${year}`, month - 1, 1);
                    const endDateformattedMonth = month < 10 ? `0${convertedEndDate.getMonth() + 1}` : convertedEndDate.getMonth() + 1;
                    const endDateformattedDate = convertedEndDate.getDate() < 10 ? `0${convertedEndDate.getDate()}` : convertedEndDate.getDate();
                    var formattedEndDate = `${convertedEndDate.getFullYear()}-${endDateformattedMonth}-${endDateformattedDate}`;
                 }
            return {
                currentService: item.currentService,
                communityService: item.communityService,
                place: item.place,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                totalHours: item.totalHours,
                description: item.description,
            };
        }) : [];
        // console.log('local storage Community Services------->', community_Services_FinalItems);

        const workIntership_Items = JSON.parse(localStorage.getItem('WorkIntership'));
        const workIntership_FinalItems = workIntership_Items && workIntership_Items.length > 0 ?
        workIntership_Items.map(item => {
            const startDateValue = item.startDate;
                const [month, year] = startDateValue.split('/');
                const convertedStartDate = new Date(`${year}`, month - 1, 1);
                const startDateformattedMonth = month < 10 ? `0${convertedStartDate.getMonth() + 1}` : convertedStartDate.getMonth() + 1;
                const startDateformattedDate = convertedStartDate.getDate() < 10 ? `0${convertedStartDate.getDate()}` : convertedStartDate.getDate();
                const formattedStartDate = `${convertedStartDate.getFullYear()}-${startDateformattedMonth}-${startDateformattedDate}`;
                if(item.currentJob) {
                    var formattedEndDate = null; 
                 } else {
                    const endDateValue = item.endDate;
                    const [month, year] = endDateValue.split('/');
                    const convertedEndDate = new Date(`${year}`, month - 1, 1);
                    const endDateformattedMonth = month < 10 ? `0${convertedEndDate.getMonth() + 1}` : convertedEndDate.getMonth() + 1;
                    const endDateformattedDate = convertedEndDate.getDate() < 10 ? `0${convertedEndDate.getDate()}` : convertedEndDate.getDate();
                    var formattedEndDate = `${convertedEndDate.getFullYear()}-${endDateformattedMonth}-${endDateformattedDate}`;
                 }
            return {
                currentJob: item.currentJob,
                jobTitle: item.jobTitle,
                company: item.company,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                description: item.description,
            };
        }) : [];
        // console.log('local storage Work/Intership------->', workIntership_FinalItems);


        const Writers_Letters_Recommendation_Items = JSON.parse(localStorage.getItem('WritersLettersRecommendation'));
        const Writers_Letters_Recommendation_FinalItems = Writers_Letters_Recommendation_Items;
        // console.log('local storage WritersLettersRecommendation------->', Writers_Letters_Recommendation_FinalItems);

        const extraActivities =
        {
            "achievements": achievements_FinalItems,
            "extraCurricularActivities": extra_Activities_FinalItems,
            "communityServices": community_Services_FinalItems,
            "experiences": workIntership_FinalItems,
            "letterOfRecommendations": Writers_Letters_Recommendation_FinalItems,
        }
            axios
                .post(EXTRA_ACTIVITIES, extraActivities, { headers: AUTHORIZATION_HEADERS })
                .then((response) => {                    
                    if( window.innerWidth > 1008) 
                    {
                        if ((tabState && buttonState) || (tabState === null && buttonState && !extraActivityState)) {
                            onNextButtonClick();
                            refreshPage();
                        }
                    }
                    else if (response.status === 200)
                    {                   
                        navigate('/student/dashboard');
                        refreshPage();
                    }
                    else{
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.error('API Error:', error);
                }
            )

            setIsDisabled(true);
    };

    const [tabState, setTabState] = useState(null);
    const [buttonState, setButtonState] = useState(false);

    useEffect(() => {
        if (!extraActivityState && !tabState) {
            setButtonState(true);
        }

        if (extraActivityState) {
            handleSubmitClick();
            setTabState(true);
        }
    }, [extraActivityState]);

    const accordionItems = extraActivities.map((activitiesItem) => ({
        title: activitiesItem.label,
        content: (
            <div className="accordion-content">
                <div className="checklist-item">
                    <div className="accordion-header">
                    </div>
                    <div className="checklist-item-content">
                        {activitiesItem.component}
                    </div>
                </div>
            </div>
        )
    }));

    const [activeIndexteacher, setActiveIndexteacher] = useState(null);

    const handleClick = (index) => {
        setActiveIndexteacher(activeIndexteacher === index ? null : index);
    };

    const toggleAccordionTeacherNotes = (index) => {
        if (activeIndexteacher === index) {
            setActiveIndexteacher(null);
        } else {
            setActiveIndexteacher(index);
        }
    };
    const teacherNotesAccordionItems = [
        {
            title: "Teacher Notes",
            content: (
                <div className="accordion-content">
                    <>
                        {teacherNotes ? (
                            <div className='teacher-notes-section m-5'>
                                <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                            </div>
                        ) : (
                            <div className='teacher-notes-section m-5'>
                                <p>There are no teacher notes.</p>
                            </div>
                        )}
                    </>
                </div>
            )
        }
    ];

    return (
        <div className="card extraactivities-section">
            <Card className="m-4">
                <div className="extra-activities-form">
                 <div className='desktop-activities'>
                 <Accordion
                        activeIndex={activeIndex}
                        onTabChange={(e) => toggleAccordion(e.index)}
                    >
                        {extraActivities.map((item, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div className="accordion-header">
                                        <div className="header-text">{item.label}</div>
                                        <div className="header-icon">
                                            <i
                                                className={`pi ${isTabOpen(index) ? "pi-minus" : "pi-plus"
                                                    }`}
                                            ></i>
                                        </div>
                                    </div>
                                }
                            >
                                <div className="">
                                    {item.component}
                                </div>
                            </AccordionTab>
                        ))}
                    </Accordion>

                    {teacherNotes && (
                        <div className='teacher-notes-section mt-5'>
                            <h3 className='head'>Teacher Notes</h3>
                            <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                        </div>
                    )}
                 </div>

                 <div className='mobile-activities'>
                        <Accordion activeIndex={activeIndex} onTabChange={(e) => toggleAccordion(e.index)}>
                            {accordionItems.map((item, index) => (
                                <AccordionTab key={index} header={
                                    <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                                        <div className="header-text">{item.title}</div>
                                        <div className="header-icon">
                                            <i className={`pi ${activeIndex === index ? "pi-minus" : "pi-plus"}`}></i>
                                        </div>
                                    </div>
                                }>
                                    <div className="accordion-content">
                                        {item.content}
                                    </div>
                                </AccordionTab>
                            ))}
                        </Accordion>

                        <Accordion activeIndex={activeIndexteacher} onTabChange={(e) => toggleAccordionTeacherNotes(e.index)}>
                            {teacherNotesAccordionItems.map((item, index) => (
                                 item.title === "Teacher Notes" && !teacherNotes ? null : (
                                <AccordionTab key={index} header={
                                    <div className="accordion-header" onClick={() => handleClick(index)}>
                                        <div className="header-text">{item.title}</div>
                                        <div className="header-icon">
                                            <i className={`pi ${activeIndexteacher === index ? "pi-minus" : "pi-plus"}`}></i>
                                        </div>
                                    </div>
                                }>
                                    <div className="accordion-content">
                                        {item.content}
                                    </div>
                                </AccordionTab>
                                 )
                            ))}
                        </Accordion>

                    </div>

                    <footer className="form-footer">
                        <Button label="Back" severity="secondary" onClick={handleBackClick} />
                        <Button
                            label="Save Information" onClick={handleSubmitClick} disabled={isDisabled}
                        />
                    </footer>
                </div>
            </Card>
        </div>
    );
};


export default ExtraActivitiesForm