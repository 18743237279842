import React from 'react';
import "./LaulamaTeam.scss";
import Venket from '../../../Assets/TeamMembers/Venket.png';
import Guillermo from '../../../Assets/TeamMembers/Guillermo.png';
import Prathyusha from '../../../Assets/TeamMembers/Prathyusha.png';
import Mahika from '../../../Assets/TeamMembers/Mahika.png';
import Harish from '../../../Assets/TeamMembers/Harish.png';
import Krishna from '../../../Assets/TeamMembers/Krishna.png';
import Ram from '../../../Assets/TeamMembers/Ram.png';
import Jayanth from '../../../Assets/TeamMembers/Jayanth.png';
import Shaji from '../../../Assets/TeamMembers/Shaji.png';
import Tania from '../../../Assets/TeamMembers/Tania.png';
import Dani from '../../../Assets/TeamMembers/Dani.png';
import Vector from '../../../Assets/TeamMembers/Vector.png';
import Star from '../../../Assets/TeamMembers/star-solid.png'
import Calendar from '../../../Assets/TeamMembers/calendar-check.png'

import Asiba from '../../../Assets/TeamMembers/Tania.png';
import Jancy from '../../../Assets/TeamMembers/Tania.png';
import Nanthini from '../../../Assets/TeamMembers/Tania.png';
import Richardson from '../../../Assets/TeamMembers/Harish.png';
import Pravin from '../../../Assets/TeamMembers/Harish.png';
import Jerin from '../../../Assets/TeamMembers/Harish.png';
import John from '../../../Assets/TeamMembers/Harish.png';
import Raja from '../../../Assets/TeamMembers/Harish.png';
import Kumareson from '../../../Assets/TeamMembers/Harish.png';
import Anto from '../../../Assets/TeamMembers/Harish.png';


const LaulamaTeam = () => {

    return (
        <>

            {/* <div className='main-sec'>

                <div className='headerSec'>
                    <div className='left'>
                        <span><b>Our work</b></span>
                    </div>
                    <div className='right'>
                        We are actively working with schools to achieve their aspiration of educational excellence and equity outcomes. Our overall approach is System Thinking in which schools learn to recognize the influence that their school system or design has on their outcomes. The approach is centered around a school’s real problems, and values insights from students, staff and other stakeholders.                    </div>
                </div>

                <br />

                <div className='condent-sec'>

                    <div className='mission'>
                        <div className='head'>
                            Our Mission
                        </div>
                        <div className='img'>
                            <img src={Vector} alt="" className='imgSec' />
                        </div>
                        <div className='note' style={{ border: "4px solid #EA8254" }} >
                            Build an improvement and innovation platform for the laulama approach
                        </div>
                    </div>

                    <div className='mission'>
                        <div className='head'>
                            Our Philosophy
                        </div>
                        <div className='img'>
                            <img src={Star} alt="" className='imgSec' />
                        </div>
                        <div className='note note2' style={{ border: "4px solid #EBB448"}} >
                            Using a systems perspective to model and understand the nature of the educational reality, and to use it to address critical, tough, and persistent challenges
                        </div>
                    </div>

                    <div className='mission'>
                        <div className='head'>
                            Our Goal
                        </div>
                        <div className='img'>
                            <img src={Calendar} alt="" className='imgSec' />
                        </div>
                        <div className='note' style={{ border: "4px solid #3B8A6E" }} >
                            Support educators with their efforts to evolve their school designs for desired outcomes.
                        </div>
                    </div>


                </div>

            </div>

            <br /> */}

            <div id='teamMembers' className='main-sec-TM'>

                <div className='headerSec'>
                    <div className='left'>
                        <span><b>Meet the team behind this project</b> </span>
                    </div>
                    <div className='right'>
                        Collaboration is at our core, with a highly skilled team of 25 experts spanning the USA, Mexico, and India. We possess the diverse knowledge and experience necessary to bring your vision to fruition.
                    </div>
                </div>

                <div className='team-sec'>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Anto} alt='AntoImage' />
                        </div>
                        <div className='name'>Anto</div>
                        <div className='position'>Software Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Asiba} alt='AsibaImage' />
                        </div>
                        <div className='name'>Asiba</div>
                        <div className='position'>Software Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Dani} alt='DaniImage' />
                        </div>
                        <div className='name'>Dani</div>
                        <div className='position'>Director, Customer Experience</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Guillermo} alt='GuillermoImage' />
                        </div>
                        <div className='name'>Guillermo</div>
                        <div className='position'>Sr. UX, Experience Analyst</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Harish} alt='HarishImage' />
                        </div>
                        <div className='name'>Harish</div>
                        <div className='position'>Director, Software Engineering</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Jancy} alt='JancyImage' />
                        </div>
                        <div className='name'>Jancy</div>
                        <div className='position'>Quality Control Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Jayanth} alt='JayanthImage' />
                        </div>
                        <div className='name'>Jayanth</div>
                        <div className='position'>Software Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Jerin} alt='JerinImage' />
                        </div>
                        <div className='name'>Jerin</div>
                        <div className='position'>Sr. Software Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={John} alt='JohnImage' />
                        </div>
                        <div className='name'>John</div>
                        <div className='position'>Quality Control Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Krishna} alt='KrishnaImage' />
                        </div>
                        <div className='name'>Krishna</div>
                        <div className='position'>Director, Software Engineering</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Kumareson} alt='KumaresonImage' />
                        </div>
                        <div className='name'>Kumareson</div>
                        <div className='position'>Software Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Mahika} alt='MahikaImage' />
                        </div>
                        <div className='name'>Mahika</div>
                        <div className='position'>Product Owner</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Nanthini} alt='NanthiniImage' />
                        </div>
                        <div className='name'>Nanthini</div>
                        <div className='position'>Software Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Prathyusha} alt='PrathyushaImage' />
                        </div>
                        <div className='name'>Prathyusha</div>
                        <div className='position'>Lead, Software Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Pravin} alt='PravinImage' />
                        </div>
                        <div className='name'>Pravin</div>
                        <div className='position'>Sr. Software Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Raja} alt='RajaImage' />
                        </div>
                        <div className='name'>Raja</div>
                        <div className='position'>DevOps Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Ram} alt='RamImage' />
                        </div>
                        <div className='name'>Ram</div>
                        <div className='position'>Sr. Data Scientist</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Richardson} alt='RichardsonImage' />
                        </div>
                        <div className='name'>Richardson</div>
                        <div className='position'>Sr. Software Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Shaji} alt='ShajiImage' />
                        </div>
                        <div className='name'>Shaji</div>
                        <div className='position'>Lead, Software Enginner</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Tania} alt='TaniaImage' />
                        </div>
                        <div className='name'>Tania</div>
                        <div className='position'>Sr. UI,  Experience Analyst</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Venket} alt='VenketImage' />
                        </div>
                        <div className='name'>Venket</div>
                        <div className='position'>Technical Advisor</div>
                    </div>

                </div>

            </div>

        </>
    );
};

export default LaulamaTeam;
