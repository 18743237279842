import React, { useState, useEffect } from "react";
import "./TestScoresForm.scss";
import ACT_Scores from "./ACT-Scores/ACT-Scores";
import PSAT_Scores from "./PSAT-Scores/PSAT-Scores";
import SAT_Scores from "./SAT-Scores/SAT-Scores";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "primeicons/primeicons.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { CONTENT_HEADERS, TEST_SCORES, GET_TEST_SCORES } from '../../../../../Utils/StudentService';


const TestScoresForm = ({ onNextButtonClick, handleBackButtonClick, testScoreState }) => {

  useEffect(() => {
    fetchTestScores();
}, []);

  const [getResponse, setGetResponse] = useState({});
  const [teacherNotes, setTeacherNotes] = useState("");
  const [teacherName, setTeacherName] = useState('');
  const [lastUpdatedOn, setLastUpdatedOn] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

const fetchTestScores = () => {
    axios
      .get(GET_TEST_SCORES, { headers: CONTENT_HEADERS })
      .then((response) => {
        setGetResponse(response.data);
        const latestTeacherNotes = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.notes;
        const latestTeacherName = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.teacherName;
        const updatedOn = response.data?.updatedOn ? new Date(response.data?.updatedOn).toLocaleDateString() : null;
        const createdOn = response.data?.createdOn ? new Date(response.data?.createdOn).toLocaleDateString() : null;

        setTeacherNotes(latestTeacherNotes);
        const teacherName = latestTeacherName;
        setTeacherName(teacherName);
        const lastUpdatedOn = updatedOn || createdOn;
        setLastUpdatedOn(lastUpdatedOn);
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
  };

  const Scores = [
    {
      label: "ACT Score",
      component: <ACT_Scores getApiResponse={getResponse}/>,
    },
    {
      label: "PSAT Score",
      component: <PSAT_Scores getApiResponse={getResponse}/>,
    },
    {
      label: "SAT Score",
      component: <SAT_Scores getApiResponse={getResponse}/>,
    },
  ];

  const isTabOpen = (index) => activeIndex === index;
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const navigate = useNavigate();

  const handleSubmitClick = () => {

        const ACT_Scores = JSON.parse(localStorage.getItem('ACT_Scores')) || "";
        const ACT_Scores_FinalItems = ACT_Scores.length > 0 ? ACT_Scores : null;

        const PSAT_Scores = JSON.parse(localStorage.getItem('PSAT_Scores')) || "";
        const PSAT_Scores_FinalItems = PSAT_Scores.length > 0 ? PSAT_Scores : null;

        const SAT_Scores = JSON.parse(localStorage.getItem('SAT_Scores')) || "";
        const SAT_Scores_FinalItems = SAT_Scores.length > 0 ? SAT_Scores : null;

        const ACT_Scores_Value = ACT_Scores_FinalItems && ACT_Scores_FinalItems.length > 0
            ? ACT_Scores_FinalItems.map(item => {
                const val = item.date;
                const [month, year] = val.split('/');
                const dateObject = new Date(`${month}/01/${year}`);
                const timestamp = dateObject.getTime();
                return {
                    date: timestamp,
                    composite: item.composite,
                    english: item.english,
                    math: item.math,
                    reading: item.reading,
                    science: item.science,
                    writing: item.writing
                };
            })
            : [];

        const PSAT_Scores_Value = PSAT_Scores_FinalItems && PSAT_Scores_FinalItems.length > 0
            ? PSAT_Scores_FinalItems.map(item => {
                const val = item.date;
                const [month, year] = val.split('/');
                const dateObject = new Date(`${month}/01/${year}`);
                const timestamp = dateObject.getTime();
                return {
                    date: timestamp,
                    evidenceBasedReadingAndWriting: item?.evidenceBasedReadingAndWriting,
                    math: item?.math,
                    essay_CR: item?.essay_CR,
                    essay_M: item?.essay_M,
                    essay_W: item?.essay_W
                };
            })
            : [];

        const SAT_Scores_Value = SAT_Scores_FinalItems && SAT_Scores_FinalItems.length > 0
            ? SAT_Scores_FinalItems.map(item => {
                const val = item.date;
                const [month, year] = val.split('/');
                const dateObject = new Date(`${month}/01/${year}`);
                const timestamp = dateObject.getTime();
                return {
                    date: timestamp,
                    evidenceBasedReadingAndWriting: item?.evidenceBasedReadingAndWriting,
                    math: item?.math,
                    essay_CR: item?.essay_CR,
                    essay_M: item?.essay_M,
                    essay_W: item?.essay_W
                };
            })
            : [];

            const testScoresPayload = {
              "actScores": ACT_Scores_Value,
              "psatScores": PSAT_Scores_Value,
              "satScores": SAT_Scores_Value
          }

        axios
        .post(TEST_SCORES, testScoresPayload, { headers: CONTENT_HEADERS })
        .then((response) => {
            if (window.innerWidth > 1008) {
              if ((tabState && buttonState) || (tabState === null && buttonState && !testScoreState)) {
                handleNextClick();
              }
            }
            else if (response.status === 200) {
                navigate('/student/extraactivities');
            } else {
                console.log(response)
            }
        })
        .catch((err) => {
            console.error(err);
        })

        setIsDisabled(true);

  };
  const [tabState, setTabState] = useState(null);
  const [buttonState, setButtonState] = useState(false);
  
  useEffect(() => {
    if (!testScoreState && !tabState) {
      setButtonState(true);
    }

    if (testScoreState) {
      handleSubmitClick();
      setTabState(true);
    }
  }, [testScoreState]);

  const handleNextClick = () => {
    onNextButtonClick();
};

const handleBackClick = () => {
    if (window.innerWidth > 1008) {
        handleBackButtonClick();
    }
    else {
        navigate('/student/highSchoolProgress');
    }
  };

  const accordionItems = Scores.map((scoresItem) => ({
    title: scoresItem.label,
    content: (
      <div className="accordion-content">
        <div className="checklist-item">
          <div className="accordion-header">
          </div>
          <div className="checklist-item-content">
            {scoresItem.component}
          </div>
        </div>
      </div>
    )
  }));

  const [activeIndexteacher, setActiveIndexteacher] = useState(null);

  const handleClick = (index) => {
    setActiveIndexteacher(activeIndexteacher === index ? null : index);
  };

  const toggleAccordionTeacherNotes = (index) => {
    if (activeIndexteacher === index) {
      setActiveIndexteacher(null);
    } else {
      setActiveIndexteacher(index);
    }
  };
  const teacherNotesAccordionItems = [
    {
      title: "Teacher Notes",
      content: (
        <div className="accordion-content">
          <>
            {teacherNotes ? (
              <div className='teacher-notes-section m-5'>
                <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
              </div>
            ) : (
              <div className='teacher-notes-section m-5'>
                <p>There are no teacher notes.</p>
              </div>
            )}
          </>
        </div>
      )
    }
  ];


  return (
    <div className="all-scores-section">
      <div className="all-scores-form">
        <div className="desktop-scores">
          <Accordion
            activeIndex={activeIndex}
            onTabChange={(e) => toggleAccordion(e.index)}
          >
            {Scores.map((item, index) => (
              <AccordionTab
                key={index}
                header={
                  <div className="accordion-header">
                    <div className="header-text">{item.label}</div>
                    <div className="header-icon">
                      <i
                        className={`pi ${isTabOpen(index) ? "pi-minus" : "pi-plus"
                          }`}
                      ></i>
                    </div>
                  </div>
                }
              >
                <div className="">{item.component}</div>
              </AccordionTab>
            ))}
          </Accordion>

          {teacherNotes && (
            <div className='teacher-notes-section mt-5'>
              <h3 className='head'>Teacher Notes</h3>
              <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
            </div>
          )}
        </div>

        <div className='mobile-scores'>
          <Accordion activeIndex={activeIndex} onTabChange={(e) => toggleAccordion(e.index)}>
            {accordionItems.map((item, index) => (
              <AccordionTab key={index} header={
                <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                  <div className="header-text">{item.title}</div>
                  <div className="header-icon">
                    <i className={`pi ${activeIndex === index ? "pi-minus" : "pi-plus"}`}></i>
                  </div>
                </div>
              }>
                <div className="accordion-content">
                  {item.content}
                </div>
              </AccordionTab>
            ))}
          </Accordion>

          <Accordion activeIndex={activeIndexteacher} onTabChange={(e) => toggleAccordionTeacherNotes(e.index)}>
            {teacherNotesAccordionItems.map((item, index) => (
               item.title === "Teacher Notes" && !teacherNotes ? null : (
              <AccordionTab key={index} header={
                <div className="accordion-header" onClick={() => handleClick(index)}>
                  <div className="header-text">{item.title}</div>
                  <div className="header-icon">
                    <i className={`pi ${activeIndexteacher === index ? "pi-minus" : "pi-plus"}`}></i>
                  </div>
                </div>
              }>
                <div className="accordion-content">
                  {item.content}
                </div>
              </AccordionTab>
               )
            ))}
          </Accordion>

        </div>


        <footer className="form-footer">
          <Button label="Back" severity="secondary" onClick={handleBackClick} />
          <Button label="Save and Continue" onClick={handleSubmitClick} disabled={isDisabled}  />
        </footer>
      </div>
    </div>
  );
};

export default TestScoresForm;
