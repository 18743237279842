import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import "../../CheckList-Progress-Form/Graduation-Requirements/Electives/Electives.scss";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Divider } from 'primereact/divider';

const Electives = ({ getApiResponse }) => {

    const electivesLocalStorageKey = 'Electives';
    const [selectedCategories, setSelectedCategories] = useState({});
    const [isOpen, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!isOpen);
    };

    useEffect(() => {
        if (getApiResponse && getApiResponse.electiveCourses && getApiResponse.electiveCourses.length > 0) {
            const selectedCategoriesMap = {};

            getApiResponse.electiveCourses.forEach((category) => {
                if (category.courses && category.courses.length > 0) {
                    category.courses.forEach((course) => {
                        const key = `${category.categoryName}_${course.course}`;
                        selectedCategoriesMap[key] = true;
                    });
                }
            });

            setSelectedCategories(selectedCategoriesMap);
        }
    }, [getApiResponse]);


    const electiveRequirements = {
        'Fine Arts Electives': {
            categories: [
                {
                    label: 'Band', key: 'Band',
                    sublabels: [
                        { label: 'Band 1', key: 'Band 1', categoryName: 'Fine Arts Electives', courseCategory: 'Band' },
                        { label: 'Band 2', key: 'Band 2', categoryName: 'Fine Arts Electives', courseCategory: 'Band' },
                        { label: 'Band 3', key: 'Band 3', categoryName: 'Fine Arts Electives', courseCategory: 'Band' },
                        { label: 'Band 4', key: 'Band 4', categoryName: 'Fine Arts Electives', courseCategory: 'Band' },
                    ]
                },
                {
                    label: 'Chorus', key: 'Chorus',
                    sublabels: [
                        { label: 'Chorus 1', key: 'Chorus 1', categoryName: 'Fine Arts Electives', courseCategory: 'Chorus' },
                        { label: 'Chorus 2', key: 'Chorus 2', categoryName: 'Fine Arts Electives', courseCategory: 'Chorus' },
                        { label: 'Chorus 3', key: 'Chorus 3', categoryName: 'Fine Arts Electives', courseCategory: 'Chorus' },
                        { label: 'Chorus 4', key: 'Chorus 4', categoryName: 'Fine Arts Electives', courseCategory: 'Chorus' },
                    ]
                },
                {
                    label: 'Piano', key: 'Piano',
                    sublabels: [
                        { label: 'Piano 1', key: 'Piano 1', categoryName: 'Fine Arts Electives', courseCategory: 'Piano' },
                        { label: 'Piano 2', key: 'Piano 2', categoryName: 'Fine Arts Electives', courseCategory: 'Piano' },
                        { label: 'Piano 3', key: 'Piano 3', categoryName: 'Fine Arts Electives', courseCategory: 'Piano' },
                        { label: 'Piano 4', key: 'Piano 4', categoryName: 'Fine Arts Electives', courseCategory: 'Piano' },
                    ]
                },
                {
                    label: 'Ukulele', key: 'Ukulele',
                    sublabels: [
                        { label: 'Ukulele 1', key: 'Ukulele 1', categoryName: 'Fine Arts Electives', courseCategory: 'Ukulele' },
                        { label: 'Ukulele 2', key: 'Ukulele 2', categoryName: 'Fine Arts Electives', courseCategory: 'Ukulele' },
                        { label: 'Ukulele 3', key: 'Ukulele 3', categoryName: 'Fine Arts Electives', courseCategory: 'Ukulele' },
                        { label: 'Ukulele 4', key: 'Ukulele 4', categoryName: 'Fine Arts Electives', courseCategory: 'Ukulele' },
                    ]
                },
                {
                    label: 'Jazz Band', key: 'Jazz Band',
                    sublabels: [
                        { label: 'Jazz Band 1', key: 'Jazz Band 1', categoryName: 'Fine Arts Electives', courseCategory: 'Jazz Band' },
                        { label: 'Jazz Band 2', key: 'Jazz Band 2', categoryName: 'Fine Arts Electives', courseCategory: 'Jazz Band' },
                        { label: 'Jazz Band 3', key: 'Jazz Band 3', categoryName: 'Fine Arts Electives', courseCategory: 'Jazz Band' },
                        { label: 'Jazz Band 4', key: 'Jazz Band 4', categoryName: 'Fine Arts Electives', courseCategory: 'Jazz Band' },
                    ]
                },
                {
                    label: 'Play Production', key: 'Play Production',
                    sublabels: [
                        { label: 'Play Production 1', key: 'Play Production 1', categoryName: 'Fine Arts Electives', courseCategory: 'Play Production' },
                        { label: 'Play Production 2', key: 'Play Production 2', categoryName: 'Fine Arts Electives', courseCategory: 'Play Production' },
                        { label: 'Play Production 3', key: 'Play Production 3', categoryName: 'Fine Arts Electives', courseCategory: 'Play Production' },
                        { label: 'Play Production 4', key: 'Play Production 4', categoryName: 'Fine Arts Electives', courseCategory: 'Play Production' },
                    ]
                },
                {
                    label: 'General Arts', key: 'General Arts',
                    sublabels: [
                        { label: 'General Arts 1', key: 'General Arts 1', categoryName: 'Fine Arts Electives', courseCategory: 'General Arts' },
                        { label: 'General Arts 2', key: 'General Arts 2', categoryName: 'Fine Arts Electives', courseCategory: 'General Arts' },
                        { label: 'General Arts 3', key: 'General Arts 3', categoryName: 'Fine Arts Electives', courseCategory: 'General Arts' },
                    ]
                },
                {
                    label: 'Sculpture', key: 'Sculpture',
                    sublabels: [
                        { label: 'Sculpture 1', key: 'Sculpture 1', categoryName: 'Fine Arts Electives', courseCategory: 'Sculpture' },
                        { label: 'Sculpture 2', key: 'Sculpture 2', categoryName: 'Fine Arts Electives', courseCategory: 'Sculpture' },
                        { label: 'Sculpture 3', key: 'Sculpture 3', categoryName: 'Fine Arts Electives', courseCategory: 'Sculpture' },
                    ]
                },
                {
                    label: 'Drawing / Painting', key: 'Drawing / Painting',
                    sublabels: [
                        { label: 'Drawing / Painting 1', key: 'Drawing / Painting 1', categoryName: 'Fine Arts Electives', courseCategory: 'Drawing / Painting' },
                        { label: 'Drawing / Painting 2', key: 'Drawing / Painting 2', categoryName: 'Fine Arts Electives', courseCategory: 'Drawing / Painting' },
                        { label: 'Drawing / Painting 3', key: 'Drawing / Painting 3', categoryName: 'Fine Arts Electives', courseCategory: 'Drawing / Painting' },
                    ]
                },
                {
                    label: 'Directed Study Art Problems', key: 'Directed Study Art Problems',
                    sublabels: [
                        { label: 'Directed Study Art Problems', key: 'Directed Study Art Problems', categoryName: 'Fine Arts Electives', courseCategory: 'Directed Study Art Problems' },
                    ]
                },
            ]
        },
        'World Languages Electives': {
            categories: [
                {
                    label: 'Japanese', key: 'Japanese',
                    sublabels: [
                        { label: 'Japanese 1', key: 'Japanese 1', categoryName: 'World Languages Electives', courseCategory: 'Japanese' },
                        { label: 'Japanese 2', key: 'Japanese 2', categoryName: 'World Languages Electives', courseCategory: 'Japanese' },
                        { label: 'Japanese 3', key: 'Japanese 3', categoryName: 'World Languages Electives', courseCategory: 'Japanese' },
                        { label: 'Japanese 4', key: 'Japanese 4', categoryName: 'World Languages Electives', courseCategory: 'Japanese' },
                    ]
                },
                {
                    label: 'Spanish', key: 'Spanish',
                    sublabels: [
                        { label: 'Spanish 1', key: 'Spanish 1', categoryName: 'World Languages Electives', courseCategory: 'Spanish' },
                        { label: 'Spanish 2', key: 'Spanish 2', categoryName: 'World Languages Electives', courseCategory: 'Spanish' },
                        { label: 'Spanish 3', key: 'Spanish 3', categoryName: 'World Languages Electives', courseCategory: 'Spanish' },
                        { label: 'Spanish 4', key: 'Spanish 4', categoryName: 'World Languages Electives', courseCategory: 'Spanish' },
                    ]
                },
                {
                    label: 'Hawaiian', key: 'Hawaiian',
                    sublabels: [
                        { label: 'Hawaiian 1', key: 'Hawaiian 1', categoryName: 'World Languages Electives', courseCategory: 'Hawaiian' },
                        { label: 'Hawaiian 2', key: 'Hawaiian 2', categoryName: 'World Languages Electives', courseCategory: 'Hawaiian' },
                        { label: 'Hawaiian 3', key: 'Hawaiian 3', categoryName: 'World Languages Electives', courseCategory: 'Hawaiian' },
                        { label: 'Hawaiian 4', key: 'Hawaiian 4', categoryName: 'World Languages Electives', courseCategory: 'Hawaiian' },
                    ]
                },
            ]
        },
        'CTE Electives': {
            categories: [
                {
                    label: 'Academy of Human Services - Agriculture, Food & Natural Resources', key: 'Academy of Human Services - Agriculture, Food & Natural Resources',
                    sublabels: [
                        { label: 'Foundations of Agriculture, Food, & Natural Resources', key: 'Foundations of Agriculture, Food, & Natural Resources', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Agriculture, Food & Natural Resources' },
                        { label: 'Principles Food Production', key: 'Principles Food Production', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Agriculture, Food & Natural Resources' },
                        { label: 'Food Products & Process Systems 1', key: 'Food Products & Process Systems 1', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Agriculture, Food & Natural Resources' },
                        { label: 'Food Products & Process Systems 2', key: 'Food Products & Process Systems 2', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Agriculture, Food & Natural Resources' },
                    ]
                },
                {
                    label: 'Academy of Human Services - Business Management Finance & Marketing', key: 'Academy of Human Services - Business Management Finance & Marketing',
                    sublabels: [
                        { label: 'Foundations of Business & Marketing', key: 'Foundations of Business & Marketing', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Business Management Finance & Marketing' },
                        { label: 'Entrepreneurship 1', key: 'Entrepreneurship 1', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Business Management Finance & Marketing' },
                        { label: 'Entrepreneurship 2', key: 'Entrepreneurship 2', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Business Management Finance & Marketing' },
                    ]
                },
                {
                    label: 'Academy of Human Services - Culinary Arts', key: 'Academy of Human Services - Culinary Arts',
                    sublabels: [
                        { label: 'Foundations of Culinary Arts', key: 'Foundations of Culinary Arts', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Culinary Arts' },
                        { label: 'Culinary Arts: Food Preparation', key: 'Culinary Arts: Food Preparation', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Culinary Arts' },
                        { label: 'Advanced Culinary Arts: Pastry and Savory', key: 'Advanced Culinary Arts: Pastry and Savory', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Culinary Arts' },
                        { label: 'Kitchen & Restaurant Operations', key: 'Kitchen & Restaurant Operations', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Culinary Arts' },
                    ]
                },
                {
                    label: 'Academy of Human Services - Health Services', key: 'Academy of Human Services - Health Services',
                    sublabels: [
                        { label: 'Foundations of Health Services', key: 'Foundations of Health Services', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Health Services' },
                        { label: 'Advanced Health Services', key: 'Advanced Health Services', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Health Services' },
                        { label: 'Nursing Services 1', key: 'Nursing Services 1', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Health Services' },
                        { label: 'Nursing Services WK-Base Learn', key: 'Nursing Services WK-Base Learn', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Health Services' },
                    ]
                },
                {
                    label: 'Academy of Human Services - Sustainable Hospitality & Tourism', key: 'Academy of Human Services - Sustainable Hospitality & Tourism',
                    sublabels: [
                        { label: 'Foundations of Sustainable Hospitality Tourism Management', key: 'Foundations of Sustainable Hospitality Tourism Management', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Sustainable Hospitality & Tourism' },
                        { label: 'Sustainable Hospitality Tourism Management 1', key: 'Sustainable Hospitality Tourism Management 1', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Sustainable Hospitality & Tourism' },
                        { label: 'Sustainable Hospitality Tourism Management 2', key: 'Sustainable Hospitality Tourism Management 2', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services - Sustainable Hospitality & Tourism' },
                    ]
                },
                {
                    label: 'Academy of Human Services – Education', key: 'Academy of Human Services – Education',
                    sublabels: [
                        { label: 'Foundations of Education', key: 'Foundations of Education', categoryName: 'CTE Electives', courseCategory: 'Academy of Human Services – Education' },
                    ]
                },
                {
                    label: 'Academy of Innovation and Design - Transportation Services', key: 'Academy of Innovation and Design - Transportation Services',
                    sublabels: [
                        { label: 'Foundations Automotive Main & Light Repair', key: 'Foundations Automotive Main & Light Repair', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Transportation Services' },
                        { label: 'Automotive Main & Light Repair 1', key: 'Automotive Main & Light Repair 1', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Transportation Services' },
                    ]
                },
                {
                    label: 'Academy of Innovation and Design – Manufacturing', key: 'Academy of Innovation and Design – Manufacturing',
                    sublabels: [
                        { label: 'Foundation of Manufacturing', key: 'Foundation of Manufacturing', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design – Manufacturing' },
                    ]
                },
                {
                    label: 'Academy of Innovation and Design - Information Technology & Digital Transformation', key: 'Academy of Innovation and Design - Information Technology & Digital Transformation',
                    sublabels: [
                        { label: 'Foundations of Computer Systems and technology', key: 'Foundations of Computer Systems and technology', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Information Technology & Digital Transformation' },
                        { label: 'Cybersecurity 1', key: 'Cybersecurity 1', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Information Technology & Digital Transformation' },
                        { label: 'Cybersecurity 2', key: 'Cybersecurity 2', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Information Technology & Digital Transformation' },
                    ]
                },
                {
                    label: 'Academy of Innovation and Design - Building & Construction', key: 'Academy of Innovation and Design - Building & Construction',
                    sublabels: [
                        { label: 'Foundations of Construction', key: 'Foundations of Construction', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Building & Construction' },
                        { label: 'Residential & Commercial Construction 1', key: 'Residential & Commercial Construction 1', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Building & Construction' },
                        { label: 'Residential & Commercial Construction 2', key: 'Residential & Commercial Construction 2', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Building & Construction' },
                        { label: 'Residential & Commercial Construction WK-Base Learn', key: 'Residential & Commercial Construction WK-Base Learn', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Building & Construction' },
                    ]
                },
                {
                    label: 'Academy of Innovation and Design - Media & Entertainment', key: 'Academy of Innovation and Design - Media & Entertainment',
                    sublabels: [
                        { label: 'Foundations of Creative Media - Digital Video', key: 'Foundations of Creative Media - Digital Video', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                        { label: 'Digital Video Technology', key: 'Digital Video Technology', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                        { label: 'Film Media Production 1', key: 'Film Media Production 1', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                        { label: 'Foundations of Creative Media – Design', key: 'Foundations of Creative Media – Design', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                        { label: 'Digital Design 1', key: 'Digital Design 1', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                        { label: 'Digital Design 2', key: 'Digital Design 2', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                        { label: 'Digital Design WK-Base Learn', key: 'Digital Design WK-Base Learn', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                    ]
                },
                {
                    label: 'Academy of Innovation and Design - Engineering Technology', key: 'Academy of Innovation and Design - Engineering Technology',
                    sublabels: [
                        { label: 'Foundations of Engineering Technology', key: 'Foundations of Engineering Technology', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                        { label: 'Engineering Technology 1', key: 'Engineering Technology 1', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                        { label: 'Engineering Technology 2', key: 'Engineering Technology 2', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                        { label: 'Engineering Technology 3', key: 'Engineering Technology 3', categoryName: 'CTE Electives', courseCategory: 'Academy of Innovation and Design - Media & Entertainment' },
                    ]
                },
            ]
        },
        'Early College Electives': {
            categories: [
                { label: 'Math 103-College Algebra', key: 'Math 103-College Algebra', categoryName: 'Early College Electives' },
                { label: 'Math 140-Pre-Calculus: Trigonometry and Analytic Geometry', key: 'Math 140-Pre-Calculus: Trigonometry and Analytic Geometry', categoryName: 'Early College Electives' },
                { label: 'Math 241-Calculus I', key: 'Math 241-Calculus I', categoryName: 'Early College Electives' },
                { label: 'Calculus A', key: 'Calculus A', categoryName: 'Early College Electives' },
                { label: 'PSYCH 100- Survey of Psychology', key: 'PSYCH 100- Survey of Psychology', categoryName: 'Early College Electives' },
                { label: 'BOT 105 Ethnobotany', key: 'BOT 105 Ethnobotany', categoryName: 'Early College Electives' },
                { label: 'BOT 130 Plants in the Hawaiian Environment', key: 'BOT 130 Plants in the Hawaiian Environment', categoryName: 'Early College Electives' },
            ]
        },
        'Other': {
            categories: [
                {
                    label: 'STEM Capstone', key: 'STEM Capstone',
                    sublabels: [
                        { label: 'STEM Capstone', key: 'STEM Capstone', categoryName: "Other", courseCategory: 'STEM Capstone' },
                    ]
                },
                {
                    label: 'Year Book Production', key: 'Year Book Production',
                    sublabels: [
                        { label: 'Year Book Production 1', key: 'Year Book Production 1', categoryName: "Other", courseCategory: 'Year Book Production' },
                        { label: 'Year Book Production 2', key: 'Year Book Production 2', categoryName: "Other", courseCategory: 'Year Book Production' },
                        { label: 'Year Book Production 3', key: 'Year Book Production 3', categoryName: "Other", courseCategory: 'Year Book Production' },
                        { label: 'Year Book Production 4', key: 'Year Book Production 4', categoryName: "Other", courseCategory: 'Year Book Production' },
                    ]
                },
                {
                    label: 'English Speaking Other Language', key: 'English Speaking Other Language',
                    sublabels: [
                        { label: 'English Speaking Other Language 1A', key: 'English Speaking Other Language 1A', categoryName: "Other", courseCategory: 'English Speaking Other Language' },
                        { label: 'English Speaking Other Language 1B', key: 'English Speaking Other Language 1B', categoryName: "Other", courseCategory: 'English Speaking Other Language' },
                        { label: 'English Speaking Other Language 2A', key: 'English Speaking Other Language 2A', categoryName: "Other", courseCategory: 'English Speaking Other Language' },
                        { label: 'English Speaking Other Language 2B', key: 'English Speaking Other Language 2B', categoryName: "Other", courseCategory: 'English Speaking Other Language' },
                    ]
                },
                {
                    label: 'Leadership Training', key: 'Leadership Training',
                    sublabels: [
                        { label: 'Leadership Training: Student Activity', key: 'Leadership Training: Student Activity', categoryName: "Other", courseCategory: 'Leadership Training' },
                        { label: 'Leadership Training: Student Body', key: 'Leadership Training: Student Body', categoryName: "Other", courseCategory: 'Leadership Training' },
                    ]
                }
            ]
        },
    };

    const [activeElectiveButton, setActiveElectiveButton] = useState(null);
    const [selectedElectiveCategories, setSelectedElectiveCategories] = useState({});


    const [activeIndex, setActiveIndex] = useState(null);
    const isTabOpen = (index) => activeIndex === index;

    const [activeElectiveAccordionIndex, setActiveElectiveAccordionIndex] = useState(null);

    const handleElectiveButtonClick = (label) => {
        setActiveElectiveButton(label);

        if (selectedElectiveCategories[label]) {
            setSelectedElectiveCategories((prevState) => ({
                ...prevState,
                [label]: prevState[label],
            }));
        } else {
            setSelectedElectiveCategories((prevState) => ({
                ...prevState,
                [label]: {},
            }));
        }
    };


    const onCategoryChange = (e) => {
        const { value, checked } = e.target;
        const key = `${value.categoryName}_${value.label}`;

        let updatedCategories = { ...selectedCategories };

        if (checked) {
            updatedCategories[key] = true;
        } else {
            delete updatedCategories[key];
        }

        setSelectedCategories(updatedCategories);

        const convertedData = Object.keys(updatedCategories).map(item => {
            const [categoryName, label] = item.split('_');
            const category = electiveRequirements[categoryName];
            let courseCategory = null;

            if (category && category.categories) {
                const hasSublabels = category.categories.some(subcategory => subcategory.sublabels);
                if (hasSublabels) {
                    for (let i = 0; i < category.categories.length; i++) {
                        const subcategory = category.categories[i];
                        if (subcategory.sublabels) {
                            const sublabel = subcategory.sublabels.find(sub => sub.label === label);
                            if (sublabel) {
                                courseCategory = sublabel.courseCategory;
                                break;
                            }
                        } else if (subcategory.label === label) {
                            courseCategory = subcategory.courseCategory || subcategory.label;
                            break;
                        }
                    }
                }
            }

            const dataObject = {
                label,
                key: label,
                categoryName
            };

            if (courseCategory !== null && courseCategory !== undefined) {
                dataObject.courseCategory = courseCategory;
            }

            return dataObject;
        });

        localStorage.setItem(electivesLocalStorageKey, JSON.stringify(convertedData));
    };

    return (
        <div className="elective-container card">
            <Divider className='elective-hide' />
            <div className="elective-hide">
                <h2>Electives</h2>
                <p><i>To obtain your high school diploma, you must complete at least 2 courses in one of these areas.</i></p>
                <div className="top-section-buttons flex flex-row flex-wrap gap-4">
                    {Object.keys(electiveRequirements).map((label) => (
                        <div className='button-section m-2' key={label}>
                            <div className={label === activeElectiveButton ? "category-btn-active" : "category-btn"}
                                onClick={() => handleElectiveButtonClick(label)}>
                                {label}
                            </div>
                        </div>
                    ))}
                </div>
                {electiveRequirements[activeElectiveButton]?.categories && (
                    <>
                        <div className="elective-subjects mt-5">
                            {electiveRequirements[activeElectiveButton].categories.map((category, index) => (
                                <div key={index}>
                                    {category.sublabels && category.sublabels.length > 0 ? (
                                        <Accordion
                                            activeIndex={activeElectiveAccordionIndex === category.key ? 0 : -1}
                                            onTabChange={(e) => setActiveElectiveAccordionIndex(activeElectiveAccordionIndex === category.key ? null : category.key)}
                                        >
                                            <AccordionTab
                                                header={
                                                    <div className="accordion-header">
                                                        <div className="header-text">{category.label}</div>
                                                        <div className="header-icon">
                                                            <i
                                                                className={`pi ${activeElectiveAccordionIndex === category.key ? "pi-minus" : "pi-plus"}`}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setActiveElectiveAccordionIndex(activeElectiveAccordionIndex === category.key ? null : category.key);
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <div className="sublabels-container flex flex-wrap categories">
                                                    {category.sublabels.map((sublabel, subIndex) => {
                                                        const key = `${category.categoryName}_${sublabel.label}`;
                                                        return (
                                                            <div key={sublabel.key} className="flex align-items-center pb-3">

                                                                <Checkbox
                                                                    inputId={sublabel.key}
                                                                    name="sublabel"
                                                                    value={sublabel}
                                                                    onChange={onCategoryChange}
                                                                    checked={selectedCategories[`${sublabel.categoryName}_${sublabel.label}`] || false}
                                                                />

                                                                <label htmlFor={sublabel.key} className="ml-2 mr-3">
                                                                    {sublabel.label}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </AccordionTab>
                                        </Accordion>
                                    ) : (
                                        <div className="sublabels-container sub-categories">
                                            <Checkbox
                                                inputId={category.key}
                                                name="category"
                                                value={category}
                                                onChange={onCategoryChange}
                                                checked={selectedCategories[`${category.categoryName}_${category.label}`] || false}
                                            />
                                            <label htmlFor={category.key} className="ml-2 mr-3">
                                                {category.label}
                                            </label>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            {window.innerWidth < 1008 && (
                <>
                    <div>
                        <div className="mob-subjects">
                            <Accordion activeIndex={isOpen ? [] : [0]}>
                                <AccordionTab
                                    header={
                                        <div className="accordion-header">
                                            <div className="header-text">Electives</div>
                                            <div className="header-icon">
                                                <div className="accordion-header" onClick={toggle}>
                                                    {isOpen ? (
                                                        <i className="pi pi-minus"></i>
                                                    ) : (
                                                        <i className="pi pi-plus"></i>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <p>
                                        You must complete 8 electives to get your High School
                                        Diploma
                                    </p>
                                    <div className="mob-top-section-buttons flex flex-row flex-wrap gap-4 mb-5">
                                        {Object.keys(electiveRequirements).map((label) => (
                                            <div className="button-section m-2" key={label}>
                                                <div
                                                    className={
                                                        label === activeElectiveButton
                                                            ? "category-btn-active"
                                                            : "category-btn"
                                                    }
                                                    onClick={() => handleElectiveButtonClick(label)}
                                                >
                                                    {label}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {electiveRequirements[activeElectiveButton]?.categories && (
                                        <>
                                            <div className="mob-elective-subjects">
                                                {electiveRequirements[activeElectiveButton].categories.map((category, index) => (
                                                    <div key={index}>
                                                        {category.sublabels &&
                                                            category.sublabels.length > 0 ? (
                                                            <Accordion
                                                                activeIndex={
                                                                    activeElectiveAccordionIndex === category.key
                                                                        ? 0
                                                                        : -1
                                                                }
                                                                onTabChange={(e) =>
                                                                    setActiveElectiveAccordionIndex(activeElectiveAccordionIndex === category.key ? null : category.key)
                                                                }
                                                            >
                                                                <AccordionTab
                                                                    header={
                                                                        <div className="accordion-header">
                                                                            <div className="header-text">
                                                                                {category.label}
                                                                            </div>
                                                                            <div className="header-icon">
                                                                                <i className={`pi ${activeElectiveAccordionIndex === category.key ? "pi-minus" : "pi-plus"}`}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setActiveElectiveAccordionIndex(activeElectiveAccordionIndex === category.key ? null : category.key);
                                                                                    }}
                                                                                >
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <div className="mob-sublabels-containers categories">
                                                                        {(category.sublabels).map(
                                                                            (sublabel, subIndex) => {
                                                                                const key = `${category.categoryName}_${sublabel.label}`;
                                                                                return (
                                                                                    <div key={sublabel.key} className="flex align-items-center">
                                                                                        <Checkbox
                                                                                            inputId={sublabel.key}
                                                                                            name="sublabel"
                                                                                            value={sublabel}
                                                                                            onChange={onCategoryChange}
                                                                                            checked={selectedCategories[`${sublabel.categoryName}_${sublabel.label}`] || false}
                                                                                        />
                                                                                        <label htmlFor={sublabel.key} className="mx-2">
                                                                                            {sublabel.label}
                                                                                        </label>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </AccordionTab>
                                                            </Accordion>
                                                        ) : (
                                                            <div className="mob-sublabels-container categories mt-3">
                                                                <Checkbox
                                                                    inputId={category.key}
                                                                    name="category"
                                                                    value={category}
                                                                    onChange={onCategoryChange}
                                                                    checked={selectedCategories[`${category.categoryName}_${category.label}`] || false}
                                                                />
                                                                <label htmlFor={category.key} className="ml-2">
                                                                    {category.label}
                                                                </label>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Electives;