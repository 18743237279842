import React, { useEffect, useState } from 'react';
import '../Help.scss';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faFileVideo, faFileWord, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import SubCategory from '../Category/Category';
import NavBar from '../../StackNavBar/StackNavBar';

const PTPResources = () => {
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const [userRole, setUserRoles] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('PTP Resources');

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        const currentUrl = window.location.pathname;

        if (currentUrl.includes('/admin')) {
            handleAdminMenuClick(activeMenuItem);
            setUserRoles("Admin")
        } else if (currentUrl.includes('/principal')) {
            handlePrincipalMenuClick(activeMenuItem);
            setUserRoles("Principal")
        } else if (currentUrl.includes('/teacher')) {
            handleTeacherMenuClick(activeMenuItem);
            setUserRoles("Teacher")
        } else if (currentUrl.includes('/student')) {
            handleStudentMenuClick(activeMenuItem);
            setUserRoles("Student")
        }

    }, [activeMenuItem]);

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
    };

    const handleAdminMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case "dashboard":
                navigate("/admin/dashboard");
                break;
            case "principals":
                navigate("/admin/principals");
                break;
            case "teachers":
                navigate("/admin/teachers");
                break;
            case "groups":
                navigate("/admin/groups");
                break;
            case "students":
                navigate("/admin/students");
                break;
            default:
                break;
        }
    };

    const handlePrincipalMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/principal/dashboard');
                break;
            case 'groups':
                navigate('/principal/groups');
                break;
            case 'students':
                navigate('/principal/students');
                break;
            default:
                break;
        }
    }

    const handleTeacherMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/teacher/dashboard');
                break;
            case 'students':
                navigate('/teacher/studentsList');
                break;
            default:
                break;
        }
    };

    const handleStudentMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/student/dashboard');
                break;
            case "profile":
                navigate("/student/profile");
                break;
            default:
                break;
        }
    }
    return (
        <>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />
            <div className='help-section'>
                <div className='help-heading'>
                    <h3>Help Section</h3>
                </div>
                <div className='help-sub-section '>
                    <div className='flex flex-wrap m-5'>
                        <div className='col-3 category-section border-right-3'>
                            <h3 className='mb-6'><b>By Category</b></h3>
                            <SubCategory selectedCategory={selectedCategory} handleCategoryClick={handleCategoryClick} />
                        </div>
                        <div className='col-9 guide-section-details pl-5'>
                            {selectedCategory === 'PTP Resources' && (
                                <div className='resources'>
                                    <h2 className='mb-6'>PTP Resources</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails mb-3 mr-3'>
                                            <div className='img flex align-items-center justify-content-evenly'>
                                                <FontAwesomeIcon icon={faFolderOpen} className='open-1 mr-3' /><h3>Post-Secondary Plan</h3>
                                            </div>
                                            <div className='heading p-1'>A description for this section</div>
                                            <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-3' />
                                                <a href="https://sites.google.com/k12.hi.us/class-of-2025-ptp/post-secondary-plan" target='_blank'>External Link</a>
                                            </div>
                                        </div>
                                        <div className='guidesdetails mb-3 mr-3'>
                                            <div className='img flex align-items-center justify-content-evenly'>
                                                <FontAwesomeIcon icon={faFolderOpen} className='open-2 mr-3' /><h3>Post-Secondary Plan 2</h3>
                                            </div>
                                            <div className='heading p-1'>A description for this section</div>
                                            <div className="content flex align-items-center justify-content-start"><FontAwesomeIcon icon={faFileWord} className='mr-3' />
                                                <a href="https://staticfile.laulamaschools.com/Post-Secondary+Plan+Draft+2024.docx" target="_blank" download>Download Word</a>                                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PTPResources;
