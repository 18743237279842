import React, { useState, useEffect } from "react";
import "./Academic-Goals-Form.scss";
import { Accordion, AccordionTab } from "primereact/accordion";
import "primeicons/primeicons.css";
import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { AUTHORIZATION_HEADERS, ACADEMIC_GOALS, GET_ACADEMIC_GOALS, UPDATE_ACADEMIC_GOALS, CONTENT_HEADERS } from '../../../../../Utils/StudentService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AcademicGoalsForm = ({ onNextButtonClick, handleBackButtonClick, onAcademicGoalUpdate, academicGoalsState }) => {

    const navigate = useNavigate();

    useEffect(() => {
        fetchAcademicGoals();
    }, []);

    const [getResponse, setGetResponse] = useState({});
    const [teacherNotes, setTeacherNotes] = useState("");
    const [teacherName, setTeacherName] = useState('');
    const [lastUpdatedOn, setLastUpdatedOn] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    const fetchAcademicGoals = () => {
        axios
            .get(GET_ACADEMIC_GOALS, { headers: CONTENT_HEADERS })
            .then((response) => {
                setGetResponse(response.data);

                const latestTeacherNotes = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.notes;
                const latestTeacherName = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.teacherName;
                const updatedOn = response.data?.updatedOn ? new Date(response.data?.updatedOn).toLocaleDateString() : null;
                const createdOn = response.data?.createdOn ? new Date(response.data?.createdOn).toLocaleDateString() : null;

                setTeacherNotes(latestTeacherNotes);
                const teacherName = latestTeacherName;
                setTeacherName(teacherName);
                const lastUpdatedOn = updatedOn || createdOn;
                setLastUpdatedOn(lastUpdatedOn);

                if (response.data?.academicGoals?.length > 0) {
                    const latestVersion = ((response.data.academicGoals.length) - 1);
                    const latestAcademicGoal = response.data.academicGoals[latestVersion];

                    const educationalGoal = (latestAcademicGoal.goals).filter(item => item.academicGoalsCategoryName === "Educational Goal");
                    selectedCategory["Educational Goal"] = educationalGoal[0]?.goalOptions;
                   
                    const CumulativeGpaGoal =  (latestAcademicGoal.goals).filter(item => item.academicGoalsCategoryName === "Cumulative GPA Goal");
                    selectedCategory["Cumulative GPA Goal"] = CumulativeGpaGoal[0]?.goalOptions;

                    const honorRecognition =  (latestAcademicGoal.goals).filter(item => item.academicGoalsCategoryName === "Honors Recognition Certificate Goal");
                    selectedCategory["Honors Recognition Certificate Goal"] = honorRecognition[0]?.goalOptions;

                    const collegeGoal =  (latestAcademicGoal.goals).filter(item => item.academicGoalsCategoryName === "After High School Plan");
                    selectedCategory["After High School Plan"] = collegeGoal[0]?.goalOptions;

                    setShowInputs(collegeGoal[0]?.goalOptions);

                    setCollegeMajorValue(collegeGoal[0]?.collegeMajor);
                    setCollegeInterestValue(collegeGoal[0]?.collegeInterest);
                }

            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };


    const goals = [
        {
            label: "Educational Goal",
            categories: [
                { label: "Technical or vocational certificate", key: "Technical or vocational certificate" },
                { label: "Two-year college degree (AA/AS)", key: "Two-year college degree (AA/AS)" },
                { label: "High school diploma", key: "High school diploma" },
                { label: "Four-year college degree (BA/BS)", key: "Four-year college degree (BA/BS)" },
                { label: "Graduate degree (MD, MA, JD, PhD, MBA)", key: "Graduate degree (MD, MA, JD, PhD, MBA)" },
            ],
        },
        {
            label: "Cumulative GPA Goal",
            categories: [
                { label: "Cum Laude – 3.0 to 3.5", key: "Cum Laude – 3.0 to 3.5" },
                { label: "Summa Cum Laude – 3.8+", key: "Summa Cum Laude – 3.8+" },
                { label: "Magna Cum Laude – 3.5+ to 3.8", key: "Magna Cum Laude – 3.5+ to 3.8" },
                { label: "Valedictorian – 4.0+ and met requirements of one Honors Recognition Certificate", key: "Valedictorian – 4.0+ and met requirements of one Honors Recognition Certificate" },
            ],
        },
        {
            label: "Honors Recognition Certificate Goal",
            categories: [
                {
                    label: "Academic Honors", key: "Academic Honors",
                    credits: [
                        { description: "4 math credits(Algebra 2 and higher)" },
                        { description: "4 science credits" },
                        { description: "2 college level credits(AP/EC/Running Start)" },
                    ],
                },
                {
                    label: "STEM Honors", key: "STEM Honors",
                    credits: [
                        { description: "4 math credits(Algebra 2 and higher)" },
                        { description: "4 science credits" },
                        { description: "Successful completion of a STEM Capstone Project" },
                    ],
                },
                {
                    label: "CTE Honors", key: "CTE Honors",
                    credits: [
                        { description: "Completes program of study in sequence and specific academic course requirement" },
                        { description: "Earn a B or better in each required program of study course" },
                        {
                            description: "Meet/Exceed proficiency on performance-based assessments for corresponding program of study"
                        },
                    ],
                },
            ],
        },
        {
            label: "After High School Plan",
            categories: [
                { label: "Go to a vocational or trade school", key: "Go to a vocational or trade school" },
                { label: "Go to a four-year college/University*", key: "Go to a four-year college/University" },
                { label: "Find a Job", key: "Find a Job" },
                { label: "Go to a two-year college/Community College*", key: "Go to a two-year college/Community College" },
                { label: "Join the military", key: "Join the military" },
                { label: "I don't know", key: "I don't know" },
            ],
            inputs: true,
        },
    ];

    const isTabOpen = (index) => activeIndex === index;
    const [activeIndex, setActiveIndex] = useState(null);
    const [activeIndexteacher, setActiveIndexteacher] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [collegeMajorValue, setCollegeMajorValue] = useState('');
    const [collegeInterestValue, setCollegeInterestValue] = useState('');
    const [showInputs, setShowInputs] = useState('');


    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    const handleOptionChange = (goalId, categoryId) => {
        setSelectedCategory((prevSelectedAnswers) => ({
            ...prevSelectedAnswers,
            [goalId]: categoryId,
        }));
        setShowInputs(categoryId)
    };


    const handleNextClick = () => {
        const selectedEducationalGoal = selectedCategory["Educational Goal"];
        const selectedCumulativeGpaGoal = selectedCategory["Cumulative GPA Goal"];
        const selectedHonorsRecognitionCertificateGoal = selectedCategory["Honors Recognition Certificate Goal"];
        const selectedHighSchoolPlan = selectedCategory["After High School Plan"];

        const academic_goals = {
            "academicGoals": [
                selectedEducationalGoal ? {
                    academicGoalsCategoryName: "Educational Goal",
                    goalOptions: selectedEducationalGoal,
                } : null,
                selectedCumulativeGpaGoal ? {
                    academicGoalsCategoryName: "Cumulative GPA Goal",
                    goalOptions: selectedCumulativeGpaGoal,
                } : null,
                selectedHonorsRecognitionCertificateGoal ? {
                    academicGoalsCategoryName: "Honors Recognition Certificate Goal",
                    goalOptions: selectedHonorsRecognitionCertificateGoal,
                } : null,
                selectedHighSchoolPlan ? {
                    academicGoalsCategoryName: "After High School Plan",
                    goalOptions: selectedHighSchoolPlan,
                    collegeMajor: collegeMajorValue,
                    collegeInterest: collegeInterestValue,
                } : null,
            ].filter(Boolean)
        };


        const academicGoalsArray = academic_goals.academicGoals;
        const filtered_academic_goals = academicGoalsArray.filter(goal =>
            goal.goalOptions && goal.goalOptions !== "" ||
            (goal.collegeMajor !== null && goal.collegeMajor !== "") &&
            (goal.collegeInterest !== null && goal.collegeInterest !== "")
        );

        axios
            .post(ACADEMIC_GOALS, { academicGoals: filtered_academic_goals }, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {
                if (window.innerWidth > 1008) {
                    onAcademicGoalUpdate(filtered_academic_goals);
                    if ((tabState && buttonState) || (tabState === null && buttonState && !academicGoalsState)) {
                        onNextButtonClick();
                    }
                }
                else if (response.status === 200) {
                    navigate('/student/academypathway');
                } else {
                    console.log(response)
                }
            }
            )
            .catch((error) => {
                console.error('API Error:', error);
            }
            )

            setIsDisabled(true);
    };

    const [tabState, setTabState] = useState(null);
    const [buttonState, setButtonState] = useState(false);

    useEffect(() => {
        if (!academicGoalsState && !tabState) {
            setButtonState(true);
        }

        if (academicGoalsState) {
            handleNextClick();
            setTabState(true);
        }
    }, [academicGoalsState]);

    const handleBackClick = () => {
        if (window.innerWidth > 1008) {
            handleBackButtonClick();
        }
        else {
            navigate('/student/careergoals');
        }
    };


    const accordionItems = [
        {
            title: "Teacher Notes",
            content: (
                <div className="accordion-content">
                    <>
                    {teacherNotes ? (
                        <div className='teacher-notes-section m-5'>
                            <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                        </div>
                    ) : (
                        <div className='teacher-notes-section m-5'>
                            <p>There are no teacher notes.</p>
                        </div>
                    )}
                    </>
                </div>
            )
        }
    ];

    const handleClick = (index) => {
        setActiveIndexteacher(activeIndexteacher === index ? null : index);
    };

    const toggleAccordionTeacherNotes = (index) => {
        if (activeIndexteacher === index) {
            setActiveIndexteacher(null);
        } else {
            setActiveIndexteacher(index);
        }
    };

    return (
        <div className="card acadamic-section">
            <Card className="m-4">
                <div className="academic-goals-form">
                    <Accordion
                        activeIndex={activeIndex}
                        onTabChange={(e) => toggleAccordion(e.index)}
                    >
                        {goals.map((item, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div className="accordion-header">
                                        <div className="header-text">{item.label}</div>
                                        <div className="header-icon">
                                            <i
                                                className={`pi ${isTabOpen(index) ? "pi-minus" : "pi-plus"
                                                    }`}
                                            ></i>
                                        </div>
                                    </div>
                                }
                            >
                                <div className="choose">
                                    <div>Choose one option</div>
                                </div>
                                <div className="radio-buttons acedamic-options">
                                    {item.categories.map((category) => {
                                        return (
                                            <div
                                                key={category.key}
                                                className="options-value"
                                            >
                                                <RadioButton
                                                    inputId={category.key}
                                                    name="category"
                                                    value={category}
                                                    onChange={(e) =>
                                                        handleOptionChange(item.label, category.key)
                                                    }
                                                    checked={
                                                        selectedCategory[item.label] === category.key
                                                    }
                                                />
                                                <label
                                                    htmlFor={category.key}
                                                    className="ml-2 pt-2 radiobutton-label"
                                                >
                                                    {category.label}
                                                </label>
                                                {category.credits?.length > 0 && (
                                                    <span>
                                                        <div className="radiobutton-description">
                                                            {category.credits.map((credit) => {
                                                                return (
                                                                    <ul>
                                                                        <li>{credit.description}</li>
                                                                    </ul>
                                                                );
                                                            })}
                                                        </div>
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                {((showInputs === "Go to a two-year college/Community College") || (showInputs === "Go to a four-year college/University")) &&
                                    <div>
                                        {item.inputs && (
                                            <div className="flex flex-wrap gap-6 inputs pt-3">
                                                <div className="flex flex-column m-2">
                                                    <label>*College Major</label>
                                                    <InputText value={collegeMajorValue} onChange={(e) => setCollegeMajorValue(e.target.value)} />
                                                </div>
                                                <div className="flex flex-column m-2">
                                                    <label>*College Interest</label>
                                                    <InputText value={collegeInterestValue} onChange={(e) => setCollegeInterestValue(e.target.value)} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }

                            </AccordionTab>
                        ))}
                    </Accordion>
                    
                    <div className='mobile-academic'>
                    <Accordion activeIndex={activeIndexteacher} onTabChange={(e) => toggleAccordionTeacherNotes(e.index)}>
                        {accordionItems.map((item, index) => (
                             item.title === "Teacher Notes" && !teacherNotes ? null : (
                            <AccordionTab key={index} header={
                                <div className="accordion-header" onClick={() => handleClick(index)}>
                                    <div className="header-text">{item.title}</div>
                                    <div className="header-icon">
                                        <i className={`pi ${activeIndexteacher === index ? "pi-minus" : "pi-plus"}`}></i>
                                    </div>
                                </div>
                            }>
                                <div className="accordion-content">
                                    {item.content}
                                </div>
                            </AccordionTab>
                             )
                        ))}
                    </Accordion>
                    </div>

                    <div className='desktop-academic'>
                    {teacherNotes && (
                        <div className='teacher-notes-section mt-5'>
                            <h3 className='head'>Teacher Notes</h3>
                            <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                        </div>
                    )}
                    </div>

                    <footer className="form-footer">
                        <Button label="Back" severity="secondary" onClick={handleBackClick} />
                        <Button
                            label="Save and Continue"
                            onClick={() => { handleNextClick() }}
                            disabled={isDisabled} 
                        />
                    </footer>
                </div>
            </Card>
        </div>
    );
};

export default AcademicGoalsForm;
