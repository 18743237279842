import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import "../ViewLists/ViewCard.scss";
import CustomFilter from "./CustomFilter/CustomFilter";
import axios from "axios";
import { GET_STUDENT_URL } from "../../Utils/AdminService";
import { OverlayPanel } from "primereact/overlaypanel";
import Spinner from '../../Shared-Component/Spinner/Gif-Loader';
import { Dropdown } from "primereact/dropdown";
import FilterChips from "../../Module/Admin/Principals/FilterChips";

const TotalStudentDataTable = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [datass, setDatass] = useState([]);

    const [checkedItems, setCheckedItems] = useState({});
    const [groupCheckboxes, setGroupCheckboxes] = useState([]);

    const [initialData, setInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

    const [displayedGroup, setDisplayedGroup] = useState([]);

    const overlayPanelRef = useRef(null);

    const [overlayPanelVisible, setOverlayPanelVisible] = useState(false);

    const [students, setStudents] = useState([]);
    const [lastUpdateDates, setLastUpdateDates] = useState({});


    const showListofGroups = (event, rowData) => {
        const groupNames = rowData.groups

        setDisplayedGroup(groupNames);
        overlayPanelRef.current.toggle(event);
        setOverlayPanelVisible(true);
        document.body.style.overflow = 'hidden';
    };

    const hideOverlayPanel = () => {
        overlayPanelRef.current.hide();
        setOverlayPanelVisible(false);
        document.body.style.overflow = '';
    };

    useEffect(() => {
        localStorage.removeItem("rowStudentId");
        sessionStorage.setItem('userType', 'principal');
        const groupName = "Student";
        const schoolId = localStorage.getItem('schoolId');
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });
        axios
            .get(`${GET_STUDENT_URL}?groupName=${groupName}&schoolId=${schoolId}`)
            .then((res) => {
                const groupUsersInfoArray = res.data;

                const mappedData = groupUsersInfoArray.map((student) => {
                    const latestAcademicGoals = student.academicGoals?.academicGoals?.[student.academicGoals?.academicGoals.length - 1];

                    const firstName = student.firstName || "-";
                    const gender = student.gender || "-";
                    const groups = student.groups.map(group => group.groupName) || "-";
                    const studentUserId = student.userId || "-";
                    const status = student.status;
                    const tenYearPlan = student.tenYearPlan;

                    const educationalgoal = latestAcademicGoals?.goals.find(goal => goal.academicGoalsCategoryName === "Educational Goal")?.goalOptions || "-";
                    const certificategoal = latestAcademicGoals?.goals.find(goal => goal.academicGoalsCategoryName === "Honors Recognition Certificate Goal")?.goalOptions || "-";
                    const highschoolplan = latestAcademicGoals?.goals.find(goal => goal.academicGoalsCategoryName === "After High School Plan")?.goalOptions || "-";

                    const latestVersion = ((student.academiesPathways?.academiesPathwayVersion?.academiesPathway.length) - 1);
                        const latestAcademyPathway = student?.academiesPathways?.academiesPathwayVersion?.academiesPathway[latestVersion];
                        const academicCategory = latestAcademyPathway?.academiesPathwayCategory?.find(category => category?.academiesPathWay.some(pathway => pathway !== null));
                        const categoryName = academicCategory ? academicCategory?.academiesCategoryName : null;

                    const lastUpdated = student.updatedOn || student.createdOn; 
                    const lastUpdatedDate = lastUpdated ? new Date(lastUpdated).toLocaleDateString() : "-";
                    setLastUpdateDates(prevState => ({ ...prevState, [student.userId]: lastUpdatedDate }));

                    return {
                        firstName,
                        gender,
                        groups,
                        studentUserId,
                        status,
                        tenYearPlan,
                        educationalgoal,
                        certificategoal,
                        highschoolplan,
                        academy: categoryName?.length > 0 ? categoryName : "-",
                    };
                });

                setDatass(mappedData);
                setInitialData(mappedData);
                setLoading(false);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
                const uniqueGroups = [
                    ...new Set(mappedData.flatMap((item) => item.groups)),
                ];
                const checkboxes = uniqueGroups.map((group) => ({
                    label: group,
                    value: group,
                }));
                setGroupCheckboxes(checkboxes);
            })
            .catch((error) => {
                setLoading(true);
                console.error("Error fetching data:", error);
            });
    }, []);


    const listTemplate = (list) => {
        return (
            <div className="flex align-items-center gap-2">
                {list.gender === "Male" || list.gender === "M" ? (
                    <Avatar
                        image={require(`../../Assets/Avatar/StudentProfile.png`)}
                        style={{ width: 60, height: 60 }}
                        size="large"
                        shape="circle"
                    />
                ) : list.gender === "Female" || list.gender === "F"? (
                    <Avatar
                        image={require(`../../Assets/Avatar/TeacherProfile.png`)}
                        style={{ width: 60, height: 60 }}
                        size="large"
                        shape="circle"
                    />
                ) : (
                    <Avatar icon="pi pi-user" size="xlarge" shape="circle" />
                )}
                <span>{list.firstName}</span>
            </div>
        );
    };

    const [selectedCareerGoalsFilter, setSelectedCareerGoalsFilter] = useState([]);
    const [selectedEducationalGoalsFilter, setSelectedEducationalGoalsFilter] = useState([]);
    const [selectedCertificateGoalsFilter, setSelectedCertificateGoalsFilter] = useState([]);
    const [selectedAcademyFilter, setSelectedAcademyFilter] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);

    const handleCheckboxChange = (item, filterCategory) => {
        const updatedCheckedItems = {
            ...checkedItems,
            [item]: !checkedItems[item],
        };
        setCheckedItems(updatedCheckedItems);

        if (filterCategory === "careerGoals") {
            setSelectedCareerGoalsFilter([...selectedCareerGoalsFilter, item]);
        } else if (filterCategory === "educationalgoal") {
            setSelectedEducationalGoalsFilter([...selectedEducationalGoalsFilter, item]);
        } else if (filterCategory === "certificategoal") {
            setSelectedCertificateGoalsFilter([...selectedCertificateGoalsFilter, item]);
        } else if (filterCategory === "academy") {
            setSelectedAcademyFilter([...selectedAcademyFilter, item]);
        }

        const selectedFieldValues = Object.keys(updatedCheckedItems).filter(
            (key) => updatedCheckedItems[key]
        );

        setSelectedFilters(selectedFieldValues);
    };

    const [selectedField, setSelectedField] = useState([]);

    const applyFilter = (field) => {
        const selectedFieldValues = Object.keys(checkedItems).filter(
            (key) => checkedItems[key]
        );

        setSelectedField(selectedFieldValues)

        const selectedCareerGoals = selectedCareerGoalsFilter.filter(
            (key) => checkedItems[key]
        );
        const selectedEducationalGoals = selectedEducationalGoalsFilter.filter(
            (key) => checkedItems[key]
        );
        const selectedCertificateGoals = selectedCertificateGoalsFilter.filter(
            (key) => checkedItems[key]
        );
        const selectedAcademy = selectedAcademyFilter.filter(
            (key) => checkedItems[key]
        );

        if (selectedFieldValues.length === 0 &&
            selectedCareerGoals.length === 0 &&
            selectedEducationalGoals.length === 0 &&
            selectedCertificateGoals.length === 0 &&
            selectedAcademy.length === 0
        ) {
            setDatass(initialData);
            const overlayElement = document.querySelector(".p-column-filter-overlay");
            if (overlayElement) {
                overlayElement.style.display = "none";
            }
            return;
        }
        const filteredData = initialData.filter((item) => {
            const fieldFilter =
                selectedFieldValues.length === 0 ||
                (field === "groups" &&
                    selectedFieldValues.some((selectedValue) =>
                        item.groups.includes(selectedValue)
                    )) ||
                selectedFieldValues.includes(item[field]);

            const careerGoalsFilter =
                selectedCareerGoals.length === 0 ||
                selectedCareerGoals.some((selectedValue) =>
                    item.careerGoals.includes(selectedValue)
                );

            const educationalGoalsFilter =
                selectedEducationalGoals.length === 0 ||
                selectedEducationalGoals.some((selectedValue) =>
                    item.educationalgoal.includes(selectedValue)
                );

            const certificateGoalsFilter =
                selectedCertificateGoals.length === 0 ||
                selectedCertificateGoals.some((selectedValue) =>
                    item.certificategoal.includes(selectedValue)
                );

            const academyFilter =
                selectedAcademy.length === 0 ||
                selectedAcademy.some((selectedValue) =>
                    item.academy.includes(selectedValue)
                );

            return fieldFilter && careerGoalsFilter && educationalGoalsFilter && certificateGoalsFilter && academyFilter;
        });

        setDatass(filteredData);
        const overlayElement = document.querySelector(".p-column-filter-overlay");
        if (overlayElement) {
            overlayElement.style.display = "none";
        }
    };

    const handleClearAllFilters = () => {

        setCheckedItems({});
        setSelectedCareerGoalsFilter([]);
        setSelectedEducationalGoalsFilter([]);
        setSelectedCertificateGoalsFilter([]);
        setSelectedAcademyFilter([]);
        setSelectedField([])

        setSelectedFilters([]);

        setDatass(initialData);

        const overlayElement = document.querySelector('.p-column-filter-overlay');
        if (overlayElement) {
            overlayElement.style.display = 'none';
        }

    };

    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedFilterValue, setSelectedFilterValue] = useState(null);
    const [selectedTenYearPlanFilter, setSelectedTenYearPlanFilter] = useState(null);

    const filterOptions = [
        { label: 'All', value: 'all' },
        { label: 'Registered Account', value: 'registered' },
        { label: 'Not Registered Account', value: 'notRegistered' },
        { label: 'Personal Pathway Started', value: 'personalPathwayStarted' },
    ];

    const handleFilter = (e) => {

        setSelectedFilterValue(e.value)

        const availableStatuses = [...new Set(datass.map((item) => item.status))];
        const availablePersonalPathwayPlans = [...new Set(datass.map((item) => item.tenYearPlan))];

        if (e.value === 'all') {
            setSelectedFilter(null);
            setSelectedTenYearPlanFilter(null)
            handleClearAllFilters();
        } else if (e.value === 'registered') {
            const completedStatusExists = availableStatuses.includes("Completed");
            setSelectedFilter(completedStatusExists ? "Completed" : null);
            setSelectedTenYearPlanFilter(null)
        } else if (e.value === 'notRegistered') {
            const unInvitedStatusExists = availableStatuses.includes("Pending");
            setSelectedFilter(unInvitedStatusExists ? "Pending" : null);
            setSelectedTenYearPlanFilter(null);
        } else if (e.value === 'personalPathwayStarted') {
            const personalPathwayExists = availablePersonalPathwayPlans.includes("true");
            setSelectedTenYearPlanFilter(personalPathwayExists ? "true" : null);
            setSelectedFilter(null);
        } else {
            const pendingStatusExists = availableStatuses.includes("Uninvited");
            setSelectedFilter(pendingStatusExists ? "Uninvited" : null);
        }
    };

    const filterData = () => {
        return datass.filter((item) => {
            const nameFilter = item.firstName.toLowerCase().includes(searchQuery.toLowerCase());
            const statusFilter = !selectedFilter || item.status === selectedFilter;
            const tenYearPlanFilter = !selectedTenYearPlanFilter || item.tenYearPlan === selectedTenYearPlanFilter;

            return nameFilter && statusFilter && tenYearPlanFilter;
        });
    };

    return (
        <div className="Student-datatable">
            <div className="student-dt">
                <div className="search-field flex justify-content-between align-items-center mx-5">
                    <h3>My Students</h3>

                    <span className="p-input-icon-right flex gap-4">
                        <Dropdown
                            onChange={handleFilter}
                            placeholder="Select a Status"
                            value={selectedFilterValue}
                            options={filterOptions}
                            optionLabel="label"
                            className="m-2"
                        />
                        <div className="flex align-items-center justify-content-end">
                        <InputText
                            placeholder="Search Student"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="m-2"
                        />
                        <i className="pi pi-search" style={{position:"absolute", padding:"15px"}} />
                        </div>
                    </span>
                </div>
                <Card className="card-field mx-5 my-2">
                    {selectedField.length > 0 ?
                        (
                            <FilterChips
                                selectedFilters={selectedField}

                                onRemoveFilter={(removedFilter) => {
                                    setCheckedItems((prevCheckedItems) => {
                                        const updatedCheckedItems = { ...prevCheckedItems };
                                        delete updatedCheckedItems[removedFilter];

                                        return updatedCheckedItems;
                                    });
                                }}

                                handleClearAllFilters={handleClearAllFilters}
                            />
                        ) : ''
                    }

                    <div className="datatable-card-section p-0">
                        <div className="spinner-section">
                            {loading ? <Spinner /> : null}
                        </div>
                        <DataTable
                            value={filterData()}
                            stripedRows
                            tableStyle={{ minWidth: "50rem" }}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                        >
                            {datass.firstName}
                            <Column
                                field="firstName"
                                header="Student Name"
                                body={listTemplate}
                                style={{ textAlign: "center" }}
                                filterMatchMode="contains"
                                filterFunction={(value, filter) => handleFilter({ value, filter, field: "firstName" })}
                            ></Column>

                            <Column
                                field="groups"
                                header="Group"
                                style={{ textAlign: "center" }}
                                filter
                                filterClear
                                filterApply={() => (
                                    <CustomFilter
                                        checkboxes={groupCheckboxes}
                                        checkedItems={checkedItems}
                                        onCheckboxChange={handleCheckboxChange}
                                        onApplyFilter={() => applyFilter("groups")}
                                    />
                                )}
                                body={(rowData) => {
                                    const groupNames = rowData.groups;
                                    const displayGroupNames = groupNames.length > 1 ? groupNames.slice(0, 1).join(", ") + "..." : groupNames;

                                    return (
                                        <span
                                            onClick={(e) => showListofGroups(e, rowData)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {displayGroupNames}
                                        </span>
                                    );
                                }}

                            ></Column>
                            <Column
                                field="educationalgoal"
                                header="Educational Goal"
                                style={{ textAlign: "center" }}
                                filter
                                filterClear
                                filterApply={() => (
                                    <CustomFilter
                                        checkboxes={[
                                            { label: "Technical or vocational certificate", value: "Technical or vocational certificate" },
                                            { label: "Two-year college degree (AA/AS)", value: "Two-year college degree (AA/AS)" },
                                            { label: "Technical School", value: "Technical School" },
                                            { label: "High school diploma", value: "High school diploma" },
                                            { label: "Four-year college degree (BA/BS)", value: "Four-year college degree (BA/BS)" },
                                            { label: "Graduate degree (MD, MA, JD, PhD, MBA)", value: "Graduate degree (MD, MA, JD, PhD, MBA)" },
                                        ]}
                                        checkedItems={checkedItems}
                                        // onCheckboxChange={handleCheckboxChange}
                                        onCheckboxChange={(item) => handleCheckboxChange(item, "educationalgoal")}
                                        onApplyFilter={() => applyFilter("educationalgoal")}
                                    />
                                )}
                            ></Column>
                            <Column
                                field="certificategoal"
                                header="Certificate Goal"
                                filter
                                filterClear
                                style={{ textAlign: "center" }}
                                filterApply={() => (
                                    <CustomFilter
                                        checkboxes={[
                                            { label: "Academic Honors", value: "Academic Honors" },
                                            { label: "STEM Honors", value: "STEM Honors" },
                                            { label: "CTE Honors", value: "CTE Honors" },
                                        ]}
                                        checkedItems={checkedItems}
                                        // onCheckboxChange={handleCheckboxChange}
                                        onCheckboxChange={(item) => handleCheckboxChange(item, "certificategoal")}
                                        onApplyFilter={() => applyFilter("certificategoal")}
                                    />
                                )}
                            ></Column>
                            <Column
                                field="highschoolplan"
                                header="After HS plan"
                                filter
                                filterClear
                                style={{ textAlign: "center" }}
                                filterApply={
                                    <CustomFilter
                                        checkboxes={[
                                            { label: "Go to a vocational or trade school", value: "Go to a vocational or trade school" },
                                            { label: "Go to a four-year college/University", value: "Go to a four-year college/University" },
                                            { label: "Find a Job", value: "Find a Job" },
                                            { label: "Go to a two-year college/Community College", value: "Go to a two-year college/Community College" },
                                            { label: "Join the military", value: "Join the military" },
                                            { label: "I don't know", value: "I don't know" },
                                        ]}
                                        checkedItems={checkedItems}
                                        onCheckboxChange={(item) => handleCheckboxChange(item, "highschoolplan")}
                                        onApplyFilter={() => applyFilter("highschoolplan")}
                                    />
                                }
                            ></Column>

                            <Column
                                field="academy"
                                header="CTE Pathway"
                                filter
                                filterClear
                                style={{ textAlign: "center" }}
                                filterApply={() => (
                                    <CustomFilter
                                        checkboxes={[
                                            { label: "Academy of Human Services", value: "Academy of Human Services" },
                                            { label: "Academy of Innovation and Design", value: "Academy of Innovation and Design" },
                                        ]}
                                        checkedItems={checkedItems}
                                        // onCheckboxChange={handleCheckboxChange}
                                        onCheckboxChange={(item) => handleCheckboxChange(item, "academy")}
                                        onApplyFilter={() => applyFilter("academy")}
                                    />
                                )}
                            ></Column>
                            <Column
                                header="Last Update"
                                style={{ textAlign: "center" }}
                                body={(row) => <span>{lastUpdateDates[row.studentUserId]}</span>}
                            ></Column>
                            <Column
                                className="view-details"
                                field=""
                                header="Actions"
                                style={{ textAlign: "center" }}
                                body={(rowData) => (
                                    <Link
                                        onClick={() => {
                                            const userId = rowData?.studentUserId;
                                            localStorage.setItem("rowStudentId", userId);
                                        }}
                                        style={{ textDecoration: "none", color: "#1575e5" }}
                                        to={'/' + sessionStorage.userType + '/studentinfo'}>
                                        View PTP
                                    </Link>
                                )}
                            ></Column>
                        </DataTable>
                        <OverlayPanel
                            ref={overlayPanelRef}
                            appendTo={document.body}
                            onHide={hideOverlayPanel}
                        >
                            <ul className="profile-menu-list">
                                {Array.isArray(displayedGroup) ? (
                                    displayedGroup.map((group, index) => (
                                        <li key={index}>{group}</li>
                                    ))
                                ) : (
                                    <li>{displayedGroup}</li>
                                )}
                            </ul>
                        </OverlayPanel>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default TotalStudentDataTable;
