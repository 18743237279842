
import React, { useState,useEffect} from 'react';
import "../Principal.scss";
import { useNavigate } from 'react-router-dom'
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import axios from 'axios';
import { CONTENT_HEADERS, GET_PRINCIPAL_DETAILS_URL } from '../../../Utils/PrincipalService';
import TotalStudentDataTable from '../../../Shared-Component/ViewLists/TotalStudentDataTable';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';

function Students() {
    const userRole = 'Principal';
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('students');
    const [principalDetails, setPrincipalDetails] = useState();
    const [principalName, setPrincipalName] = useState();
    const [name, setName] = useState();

    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
        switch (item) {
            case 'dashboard':
                navigate('/principal/dashboard');
                break;
            case 'groups':
                navigate('/principal/groups');
                break;
            case 'students':
                navigate('/principal/students');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        fetchPrincipalDetails();
    }, []);

    const fetchPrincipalDetails = () => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });



        const principalId = localStorage.getItem('userId');
        axios
            .get(GET_PRINCIPAL_DETAILS_URL + principalId, { headers: CONTENT_HEADERS })
            .then((response) => {
                setPrincipalDetails(response.data);
                const detail = response.data?.groupResponses;
                setPrincipalName(detail)
                setName(`${response.data.userDTO?.firstName} ${response.data.userDTO?.lastName}`);
                setLoading(false);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
            })
            .catch((error) => {
                setLoading(true);
                console.error('Error fetching data:', error);
            });
    }

    return (
        <div className='principal-students-section'>
             <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
                name={name}
            />
            <TotalStudentDataTable/>
        </div>
    )
}

export default Students