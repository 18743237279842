// Base Url
export const BASE_URL = `${process.env.REACT_APP_URL}`;

// export const BASE_URL = 'https://backend.devl.laulamaschools.com/laulama';

// Headers

export const CONTENT_HEADERS = {
    "content-type": "application/json",
};


// API Endpoints

export const Contact_Us = `${BASE_URL}/client-form/submit`;

