import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import '../../../Dashboard/MobileStudentForm/MobileStudentForm.scss';
import NavBar from '../../../../../Shared-Component/StackNavBar/StackNavBar';
import { Button } from 'primereact/button';
import CheckListProgressForm from '../../StudentForm/CheckList-Progress-Form/CheckList-Progress-Form';
import HighSchoolProgressForm from '../../StudentForm/High-School-Progrss-Form/HighSchoolProgressForm';



const MobileCheckListProgressForm = () => {

    const userRole = 'Student';
    const [activeMenuItem, setActiveMenuItem] = useState('dashboard');


    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

    };



    return (
        <div className="mob-checklist-section">

            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />
            <div className='m-5 '>
                <div className='mob-checklist-link' >
                    <Link to="/student/dashboard"> <FaChevronLeft /> Back To Dashboard</Link>
                    {/* <h2>High School Progress</h2> */}
                </div>
                {/* <div className='mob-checklist-button pt-3' > */}
                <div className='mob-checklist-button mt-3 mb-3' >
                    <Button className='Button'>5. High School Progress </Button>
                </div>
                {/* <CheckListProgressForm></CheckListProgressForm> */}
                <HighSchoolProgressForm />
            </div>

        </div>
    );
};

export default MobileCheckListProgressForm;
