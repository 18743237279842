import React from 'react';
import './LaulamaValues.scss';
import { Card } from 'primereact/card';
import Innovation from '../../../Assets/Home/Values/Innovation-ilustration.png';
import Collaboration from '../../../Assets/Home/Values/Collaboration-ilustration.png';
import Personalization from '../../../Assets/Home/Values/Personalization-ilustration.png';
import Accesibility from '../../../Assets/Home/Values/Accesibility-ilustration.png';


const LaulamaValues = () => {

    return (
        <>
            <div id='OurPurpose' className="heading-values">
                <div className='col-3 flex justify-content-start align-items-center '>
                    <p style={{ borderBottom: '4px solid #91BF82' }}><b>Our Purpose</b></p>
                </div>
                <div className='values-content'>
                    <p>We are working to innovate education’s underlying improvement system for breakthroughs that transform schools for its leaders, educators and students.</p>
                </div>
            </div>

            <div className="heading-values">
                <div className='col-3 flex justify-content-start align-items-center '>
                    <p style={{ borderBottom: '4px solid #91BF82' }}><b>Our Values</b></p>
                </div>
                <div className='values-content'>
                    <p>Committed to creating a personalized and innovative learning experience for all students, teachers, and administrators.</p>
                </div>
            </div>

            <div className="main-value">
                <div className="main-Sec">
                    <div className="condent">
                        <div className="half-circle" style={{ background: "rgba(235, 180, 72, 0.25)" }}></div>
                        <div className='ml-5'>
                            <div className='values-icon'>
                                <img src={Innovation} alt='Innovation' />
                            </div>
                            <div className='values-head'>
                                <h2 className='position'><b>Innovation</b></h2>
                            </div>
                            <div className='values-para'>
                                <span>Our approach requires us to acknowledge the reality and constantly push the boundaries of possibilities.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-Sec">
                    <div className="condent">
                        <div className="half-circle" style={{ background: "rgba(234, 130, 84, 0.25)" }}></div>
                        <div className='ml-5'>
                            <div className='values-icon'>
                                <img src={Collaboration} alt='Collaboration' />
                            </div>
                            <div className='values-head'>
                                <h2 className='position'><b>Collaboration</b></h2>
                            </div>
                            <div className='values-para'>
                                <span>Breakthroughs emerge and scale through our collective knowledge, wisdom and support.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-Sec">
                    <div className="condent">
                        <div className="half-circle" style={{ background: "rgba(145, 191, 130, 0.25)" }}></div>
                        <div className='ml-5'>
                            <div className='values-icon'>
                                <img src={Personalization} alt='Personalization' />
                            </div>
                            <div className='values-head'>
                                <h2 className='position'><b>Personalization</b></h2>
                            </div>
                            <div className='values-para'>
                                <span>We strive to adapt our approach for each school and student’s individual learning style and preferences.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-Sec">
                    <div className="condent">
                        <div className="half-circle" style={{ background: "rgba(252, 81, 69, 0.25" }}></div>
                        <div className='ml-5'>
                            <div className='values-icon'>
                                <img src={Accesibility} alt='Accesibility' />
                            </div>
                            <div className='values-head'>
                                <h2 className='position'><b>Excellence & Equity</b></h2>
                            </div>
                            <div className='values-para'>
                                <span>We believe that every person deserves to achieve their highest potential.</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>

    )
}

export default LaulamaValues