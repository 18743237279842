import React, { useState, useEffect } from "react";
import "./Resume-Builder.scss";
import { Link, useNavigate } from 'react-router-dom';
import Template1 from "./Template1/Template1";
import Template2 from "./Template2/Template2";
import Template3 from "./Template3/Template3";
import axios from 'axios';
import { AUTHORIZATION_HEADERS, GET_STUDENT_DETAILS } from "../../../../Utils/StudentService";
import NavBar from "../../../../Shared-Component/StackNavBar/StackNavBar";
import { FaChevronLeft } from "react-icons/fa";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ResumeBuilder = () => {

    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('students');
    const [personalDatas, setPersonalDatas] = useState({});

    useEffect(() => {
        fetchDetails();
    }, []);

    const fetchDetails = () => {
        axios
            .get(GET_STUDENT_DETAILS, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {
                setPersonalDatas(response.data)
            })
    }

    const handleMenuItemClick = item => {
        setActiveMenuItem(item);
        navigate(`/student/${item}`);
    };

    return (
        <>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole="Student"
            />

            <div className="resume-builder">

                <div className="backBtn">
                    <div className=''>
                        <Link
                            to={'/student/profile'}
                            style={{ textDecoration: "none", color: "#1575e5" }}
                            className='flex align-items-center'
                        >
                            <FaChevronLeft />
                            Back
                        </Link>
                    </div>
                </div>

            <h1>Generate my resume</h1>
            <p>
                With this tool you can generate a resume with the information that you provide in your personal Pathway.
            </p>
            <p>Pick one template for your resume.</p>
            <p>Note these templates are provided for Laulama if you want more options <Link style={{color: "rgb(16, 103, 184)"}} to="/student/help/resume">go to help</Link> and in resume link you can have more options`.` </p>
            <div className="grid resume-templates">
                <div className="col md:col-6 lg:col-6 xl:col-6 resume-template">
                    <Template1
                        personalDatas={personalDatas}
                    />
                </div>
                <div className="col md:col-6 lg:col-6 xl:col-6 resume-template">
                    <Template2
                        personalDatas={personalDatas}
                    />
                </div>
                <div className="col md:col-6 lg:col-6 xl:col-6 resume-template">
                    <Template3
                        personalDatas={personalDatas}
                    />
                </div>
            </div>
        </div>
        </>
    );
};

export default ResumeBuilder;
