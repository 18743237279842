import React, { useState, useEffect,useRef } from 'react';
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import '../Admin.scss';
import { FaChevronLeft } from 'react-icons/fa';
import axios from 'axios';
import { CREATE_GOAL_URL, GET_SCHOOL_URL, UPDATE_GROUP_URL } from '../../../Utils/AdminService';
import { GET_TEACHER_URL } from '../../../Utils/TeacherService';
import { Calendar } from 'primereact/calendar';
import { CONTENT_HEADERS } from '../../../Utils/StudentService';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';

const CreateGroup = () => {
    const userRole = 'Admin';
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('groups');

    const [teacherOptions, setTeacherOptions] = useState([]);
    const [schoolOptions, setSchoolOptions] = useState([]);

    const [isEditMode, setIsEditMode] = useState(false);
    const [schoolName, setSchoolName] = useState(null);
    const location = useLocation();
    const { rowData } = location.state || {};

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [formData, setFormData] = useState({
        schoolName: "",
        groupName: "",
        graduationYear: "",
        assignedTeacher: "",
        grade: "",
        userId: "",
        schoolId: "",
    });

    const [errors, setErrors] = useState({
        groupName: '',
        assignedTeachers: '',
        grade: '',
        graduationYear: ''
    });

    useEffect(() => {
        fetchTeachers();
        fetchSchool();
    }, []);

    const fetchTeachers = () => {
        axios
            .get(GET_TEACHER_URL, { headers: CONTENT_HEADERS })
            .then((response) => {
                setTeacherOptions(response.data);
            })
            .catch((error) => {
                console.error('Teachers API Error:', error);
            });
    };

    const fetchSchool = () => {
        axios
            .get(GET_SCHOOL_URL, { headers: CONTENT_HEADERS })
            .then((response) => {
                setSchoolOptions(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleMenuItemClick = (item) => {

        setActiveMenuItem(item);
        switch (item) {
            case 'dashboard':
                navigate('/admin/dashboard');
                break;
            case 'principals':
                navigate('/admin/principals');
                break;
            case 'teachers':
                navigate('/admin/teachers');
                break;
            case 'groups':
                navigate('/admin/groups');
                break;
            case 'students':
                navigate('/admin/students');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (rowData) {

            const graduationYearDate = rowData.graduationYear
                ? new Date(`${rowData.graduationYear}-01-01`)
                : null;

            let school = null;

         const selectedTeachers = teacherOptions.filter(teacher =>
            rowData.assignedTeacher.includes(teacher.firstName)
        );

        setSelectedUsers(selectedTeachers);

            schoolOptions.forEach((val) => {
                if (val.schoolName === rowData.schoolName) {
                    school = val;
                }
            })

            if (school) {
                setSchoolName(school)
            }


            setIsEditMode(true);
            setFormData({
                groupName: rowData?.groupName,
                schoolName: schoolName,
                graduationYear: graduationYearDate,
                assignedTeacher: selectedTeachers,
                grade: rowData?.grade,
                userId: rowData.userId,
                schoolId: rowData?.groupRegistrationId,
            });
        } else {
            setIsEditMode(false);
            setFormData({
                groupName: "",
                schoolName: "",
                graduationYear: "",
                assignedTeacher: "",
                grade: "",
                userId: "",
                schoolId: ""
            });
        }
    }, [rowData, teacherOptions]);

    const handleCreateGroup = async (e) => {
        e.preventDefault();
        if (validateForm()) {
        if (isEditMode) {
            handleEditGroup();
        } else {
            handleAddGroup();
        }
    }
    };
    const toast = useRef(null);

    const handleAddGroup = () => {

        const userIds = selectedUsers.map(user => user.userId);
        const assignedTeacherNames = selectedUsers.map(user => user.firstName);
        const schoolIds = localStorage.getItem('schoolId');
        let updatedValue = formData.graduationYear;
        if (formData.graduationYear && formData.graduationYear instanceof Date) {
            updatedValue = formData.graduationYear.getFullYear();
        }
        const schoolName = localStorage.getItem('schoolName');
        
        const newGroup = {
            groupName: formData.groupName,
            schoolName: schoolName,
            graduationYear: updatedValue,
            assignedTeacher: assignedTeacherNames,
            grade: formData.grade,
            userId: userIds, 
            schoolId: schoolIds,
        };

        axios
            .post(CREATE_GOAL_URL, newGroup, { headers: CONTENT_HEADERS })
            .then(() => {
                navigate('/admin/groups');
            })
            .catch((error) => {
                console.error('API Error:', error);
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{ life: 10000, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
                }
            });
    }

    const handleEditGroup = () => {
        const userIds = selectedUsers.map(user => user.userId);
        const assignedTeacherNames = selectedUsers.map(user => user.firstName);
        const schoolIds = localStorage.getItem('schoolId');
        let updatedValue = formData.graduationYear;
        if (formData.graduationYear && formData.graduationYear instanceof Date) {
            updatedValue = formData.graduationYear.getFullYear();
        }

        const schoolName = localStorage.getItem('schoolName');

        const editedData = {
            schoolId: schoolIds,
            userId: userIds,
            groupName: formData.groupName,
            schoolName: schoolName,
            graduationYear: updatedValue,
            assignedTeacher: assignedTeacherNames,
            grade: formData.grade,
        };

        axios
            .put(UPDATE_GROUP_URL + rowData.groupId, editedData, { headers: CONTENT_HEADERS })
            .then(() => {
                navigate('/admin/groups');
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleTeachersChange = (e) => {
        setSelectedUsers(e.value);
        setErrors((prevErrors) => ({ ...prevErrors, assignedTeachers: '' }));
    };

    const GraduationYearOptions = [];
    const GradeOptions = ['Grade 9', 'Grade 10', 'Grade 11', 'Grade 12'];

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
    
        if (!formData.groupName) {
            newErrors.groupName = 'Group name is required';
            isValid = false;
        } else {
            newErrors.groupName = '';
        }
    
        if (selectedUsers.length === 0) {
            newErrors.assignedTeachers = 'Select a Teacher';
            isValid = false;
        } else {
            newErrors.assignedTeachers = '';
        }
    
        if (!formData.grade) {
            newErrors.grade = 'Select a Grade';
            isValid = false;
        } else {
            newErrors.grade = '';
        }
    
        if (!formData.graduationYear) {
            newErrors.graduationYear = 'Select a Graduation year';
            isValid = false;
        } else {
            newErrors.graduationYear = '';
        }
    
        setErrors(newErrors);
        return isValid;
    };

    return (
        <div>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />

            <div className="form-section">
                <div className=''>
                    <Link
                        to="/admin/groups"
                        className="textdecor"
                    >
                        <FaChevronLeft />
                        Back to Groups
                    </Link>
                </div>

            </div>

            <div className="m-6 admin-section">
                <Card className="flex flex-column justify-content-center items-center mt-2 m-4">
                <Toast  ref={toast} />
                    <div className="p-3">
                        <div className="grid justify-content-start align-items-center">
                            <h2 className="flex justify-content-center w-full">Laulama Group Registration</h2>
                        </div>
                        <div className="header flex justify-content-start w-full">
                            <h2>{isEditMode ? "Edit Group" : "Create a New Group"}</h2>
                        </div>
                        <form className="formgrid" onSubmit={handleCreateGroup}>
                            <div className="grid col">
                                <div className="field col-4">
                                    <label>School Name</label>
                                    <InputText
                                        name="schoolName"
                                        value={localStorage.getItem('schoolName')}
                                        onChange={handleChange}
                                        placeholder="Enter School Name"
                                        className="p-inputtext-sm w-full"
                                    />
                                </div>
                                <div className="field col-4">
                                    <label>Group Name</label>
                                    <InputText
                                        name="groupName"
                                        value={formData.groupName}
                                        onChange={handleChange}
                                        placeholder="Group Name"
                                        className="p-inputtext-sm w-full"
                                    />
                                    {errors.groupName && <small className="text-red-500">{errors.groupName}</small>}
                                </div>
                                <div className="field col-4">
                                    <label>Assign Teacher</label>
                                    <MultiSelect
                                        name="assignedTeachers"
                                        options={teacherOptions}
                                        optionLabel='firstName'
                                        value={selectedUsers}
                                        // onChange={(e) => setSelectedUsers(e.value)}
                                        onChange={handleTeachersChange}
                                        placeholder="Select Assigned Teachers"
                                        className="w-full"
                                        filter
                                        display="chip"
                                    />
                                    {errors.assignedTeachers && <small className="text-red-500">{errors.assignedTeachers}</small>}
                                </div>
                            </div>
                            <div className="grid col">
                                <div className="field col-12 md:col-6">
                                    <label>Student's Grade</label>
                                    <Dropdown
                                        name="grade"
                                        options={GradeOptions}
                                        value={formData.grade}
                                        onChange={handleChange}
                                        placeholder="Select Grade"
                                        className="w-full"
                                    />
                                    {errors.grade && <small className="text-red-500">{errors.grade}</small>}
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label>Student's Graduation Year</label>
                                    <Calendar name="graduationYear"
                                        options={GraduationYearOptions}
                                        value={formData.graduationYear}
                                        onChange={handleChange}
                                        placeholder="Select graduation year"
                                        className="w-full" view="year" dateFormat="yy"
                                    />
                                    {errors.graduationYear && <small className="text-red-500">{errors.graduationYear}</small>}
                                </div>
                            </div>

                            <div className="flex justify-content-end gap-4 align-items-center">
                                <Link
                                    to="/admin/groups"
                                    className="textdecor-button"
                                    style={{ textDecoration: 'none' }}
                                >
                                    Cancel
                                </Link>
                                <Button
                                    label="Confirm"
                                    className="p-button-success"
                                    type="submit"
                                />
                            </div>
                        </form>
                    </div>
                </Card>
            </div>

        </div>
    );
};


export default CreateGroup