import React from "react";
import "./Personal-Pathway.scss";
import NewHeader from "../../Home/NewHeader/NewHeader";
import FooterSection from "../../Home/laulama-footer-section/FooterSection";
import PPHeaderimage from "../../../Assets/Products/Personal-Pathway/Personal-pathway.png";
import PPContentimage from "../../../Assets/Products/Personal-Pathway/Laptop-Laulama-Portal-WB.png";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const PersonalPathway = () => {
  const navigate = useNavigate();

  const handlePersonalPathway = () => {
    navigate("/login");
  };

  return (
    <>
      <NewHeader />
      <div className="personal-pathway">
        <div className="pp-header-section grid m-0">
          <div className="col-12 md:col-12 lg:col-6 pp-left">
            <div className="pp-header-content">
              <div className="pp-h-title">Personal Pathway Plan</div>
              <p className="pp-h-p">
                All High Schools are required to develop Personal Pathway Plans
                for their students. We have developed a digitized version that
                not only streamlines the process, it is being used to transform
                the way education is personalized.
              </p>
              <Button
                label="Go to personal pathway"
                className="p-button-sm"
                onClick={handlePersonalPathway}
              />
            </div>
          </div>
          <div className="col-12 md:col-12 lg:col-6 space">
            <img className="pp-image" src={PPHeaderimage} alt="PSimage" />
          </div>
        </div>

        <div className="pp-content-section grid">
          <div className="col-12 md:col-12 lg:col-6 pp-left-section ">
            <div className="pp-position">
              <img className="pp-c-image" src={PPContentimage} alt="PSimage" />
            </div>
          </div>
          <div className="col-12 md:col-12 lg:col-6">
            <div className="pp-header-content">
              <div className="pp-c-title">Our Platform</div>
              <p className="pp-c-p">
                We utilize the technology platform and its insights to change
                the way we interact with students and parents, redesign pathway
                programs, all while improving the school’s effectiveness, cost
                efficiency and sustainability. Laulama Schools is making it
                possible for schools to make personalized education a reality
                for all their students..
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default PersonalPathway;
