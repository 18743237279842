export const getStatusForCourse = (courseName, latestVersionData) => {
    let status = "";

    if (latestVersionData && latestVersionData.subjects) {
        latestVersionData.subjects.forEach(subject => {
            if (subject && subject.courses) {
                subject.courses.forEach(course => {
                    if (course.course === courseName) {
                        status = course.status;
                    }
                });
            }

            if (subject && subject.electiveCategories) {
                subject.electiveCategories.forEach(category => {
                    if (category && category.courses) {
                        category.courses.forEach(electiveCourse => {
                            if (electiveCourse.course === courseName) {
                                status = electiveCourse.status;
                            }
                        });
                    }
                });
            }
        });
    }

    return status;
};

export const getStatusForElectiveCourse = (courseName, latestElectiveVersionData) => {
    let status = "";

    if (latestElectiveVersionData && latestElectiveVersionData?.electiveCourses) {
        latestElectiveVersionData.electiveCourses.forEach(category => {
            if (category && category.courses) {
                console.log(category.courses)
                category.courses.forEach(course => {
                    if (course.course === courseName) {
                        status = course.status
                    }
                });
            }
        });
    }

    return status;
};