import React, { useRef, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import './FAQ.scss'
import LaulamaHeader from '../Home/LaulamaHeader/LaulamaHeader';
import FooterSection from '../Home/laulama-footer-section/FooterSection';
import PaperPlaneIcon from '../../Assets/FAQ-icons/paper-plane-solid.png';
import SchoolIcon from '../../Assets/FAQ-icons/school-solid.png';
import ShieldHalvedIcon from '../../Assets/FAQ-icons/shield-halved-solid.png';
import UserSolidIcon from '../../Assets/FAQ-icons/user-solid.png';
import NewHeader from '../Home/NewHeader/NewHeader';
const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const [searchInput, setSearchInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const [activeIndexAbout, setActiveIndexAbout] = useState(null);
    const [activeIndexAccount, setActiveIndexAccount] = useState(null);
    const [activeIndexPlan, setActiveIndexPlan] = useState(null);
    const [activeIndexTechnical, setActiveIndexTechnical] = useState(null);
    const [filteredQuestions, setFilteredQuestions] = useState([]);

    const handleSearchInputChange = (event) => {
        const userInput = event.target.value;
        setSearchInput(userInput);

        if (userInput.trim() === '') {
            setFilteredQuestions([]);
        } else {
            const allQuestions = [...faqQuestions, ...accountQuestions, ...pathwayQuestion, ...technicalQuestions];

            const updatedQuestions = allQuestions.filter((question) => {
                return question.label.toLowerCase().includes(userInput.toLowerCase());
            });

            setFilteredQuestions(updatedQuestions);
        }
    };



    const faqRef = useRef(null);
    const accountRef = useRef(null);
    const privacyRef = useRef(null);
    const planRef = useRef(null);
    const technicalRef = useRef(null);

    const [faqQuestions, setFaqQuestions] = useState([
        {
            label: 'What is the purpose of Laulama?',
            content: 'Laulama was created by Mel Horikami in collaboration with Hawaiian school educators, with a shared commitment to enhancing educational excellence through data-driven services. Its mission is to empower schools with valuable data-driven tools and solutions to improve educational outcomes.',
            highlightedContent: ''
        },
        {
            label: 'How do I access the Laulama school portal? ', content: (
                <ol>
                    <li>
                        To access the Laulama school portal, please follow these steps:
                    </li>
                    <li>
                        Visit the Laulama school website's home page.
                    </li>
                    <li>
                        Look for a button labeled "Login" on the home page.
                    </li>
                    <li>
                        Click on the "Log In" button.
                    </li>
                    <li>
                        You will be redirected to the login page to enter your credentials and access the school portal.
                    </li>
                </ol>
            )
            , highlightedContent: ''
        },
        {
            label: 'What do I do if I forget my username or password?',
            content: 'Your username will be your school-associated email, and your password can be reset through the Forget Password Link below the login button, which will then send you an email to reset your password.',
            highlightedContent: ''
        },
    ]);

    const [accountQuestions, setAccountQuestions] = useState([
        {
            label: 'How do I create an account on the Laulama portal?', content: (
                <>
                    <>To create an account on the Laulama portal, follow these steps:</>
                    <ol>
                        <li>
                            Receive Your Laulama Login: Your Laulama login details will be sent to your school email address.
                        </li>
                        <li>
                            Registration by Guidance Counselor: Your school's guidance counselor will handle the registration process for you. They will create your profile on the Laulama portal.
                        </li>
                        <li>
                            First-Time Login: You will receive first-time login credentials once your guidance counselor has successfully registered you. You can use these credentials to log in to the Laulama portal.
                        </li>
                        <li>
                            Create Your Profile: After logging in with your first-time credentials, you will be prompted to create your profile. Follow the provided instructions to complete this step.
                        </li>
                    </ol>
                    <>
                        That's it! You should now have an active account on the Laulama portal. If you encounter any issues during the process, please reach out to your school's guidance counselor for assistance.
                    </>

                </>
            ),
            highlightedContent: ''
        },
        {
            label: 'Can I update my contact information and personal details in my profile?',
            content: "All personal information and details of a student will be uploaded by their respective guidance counselor. For any updates or changes to personal information, we kindly request you to reach out to your school's guidance counselor. ",
            highlightedContent: ''
        },
        {
            label: 'What should I do if I need to change my password?',
            content: 'Your password can be reset through the Forget Password Link below the login button, which will then send you an email to reset your password. ',
            highlightedContent: ''
        },
        {
            label: 'Can I change my email address associated with the portal account?',
            content: "The email address utilized for the account's creation was furnished by the school's guidance counselor, and therefore that email must be utilized.",
            highlightedContent: ''
        },
        {
            label: 'Can I upload a profile picture?',
            content: 'At present, the option to upload a profile picture is not available.',
            highlightedContent: ''
        },
    ]);

    // const [privacyQuestions] = useState([
    //     { label: 'How is my personal information protected on the portal?', content: '', highlightedContent: '' },
    //     { label: 'What measures are in place to ensure the security of my data?', content: '', highlightedContent: '' },
    //     { label: 'Can I control who has access to my information on the portal?', content: '', highlightedContent: '' },
    //     { label: "What is the school's data privacy policy?", content: '', highlightedContent: '' },
    // ]);

    const [pathwayQuestion] = useState([
        {
            label: 'What is a ten year plan? ',
            content: 'The Ten Year Plan is a program designed by the DOE (Department of Education) to assist students in developing ideas and strategies for their careers after graduation.',
            highlightedContent: ''
        },
        {
            label: 'Why should a student fill out a ten year plan?',
            content: 'Through the ten year plan process students can set long-term career goals and create a roadmap to achieve them. It may include career assessments, goal-setting exercises, and guidance from school counselors or advisors.',
            highlightedContent: ''
        },
        {
            label: 'How do you fill out a ten year plan?',
            content: 'Once in the Laulama system, there will be a step by step guide to help you navigate the ten year plan.',
            highlightedContent: ''
        },
        {
            label: "What is the school's data privacy policy?",
            content: 'Ten Year Plan’s are merely a tool to help students further understand their prospects after highschool, they are not permanent, and will be changed periodically. ',
            highlightedContent: ''
        },
        {
            label: 'Are ten year plans permanent?',
            content: 'Ten Year Plan’s are merely a tool to help students further understand their prospects after highschool, they are not permanent, and will be changed periodically.'
        },
        {
            label: 'How many times does a student need to fill this out?',
            content: 'A student is required to fill this form twice a school year, once at the beginning and the end in order to ensure the school is supporting the student on their desired career pathway.'
        },
    ]);

    const [technicalQuestions] = useState([
        {
            label: 'What should I do if I encounter technical problems with the portal?',
            content: 'If any technical issues occur, please contact your guidance counsellor for support.',
            highlightedContent: ''
        },
        {
            label: 'How often is the portal updated or maintained?',
            content: 'The portal is updated monthly',
            highlightedContent: ''
        },
    ]);

    const toggleAccordion = (section, index) => {
        switch (section) {
            case 'about':
                setActiveIndexAbout(activeIndexAbout === index ? null : index);
                break;
            case 'account':
                setActiveIndexAccount(activeIndexAccount === index ? null : index);
                break;
            case 'plan':
                setActiveIndexPlan(activeIndexPlan === index ? null : index);
                break;
            case 'technical':
                setActiveIndexTechnical(activeIndexTechnical === index ? null : index);
                break;
            default:
                break;
        }
    };

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    return (
        <>
            {/* <LaulamaHeader></LaulamaHeader> */}
            <NewHeader />

            <div className="search-section faq-page">
                <h2>How can we help? </h2>
                <div className='faq-search-section'>
                    <div className="search-box">
                        <input
                            className='search-input'
                            type="text"
                            placeholder="Search..."
                            value={searchInput}
                            onChange={handleSearchInputChange}
                        />
                        <i className="pi pi-search search-icon" />
                        {/* {suggestions.length > 0 && (
                        <ul className="suggestions-dropdown">
                            {suggestions.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    )} */}
                    </div>
                </div>
            </div>

            {filteredQuestions.length > 0 && (
                <div className="searchrelated-section m-5">
                    <h2>Related search:</h2>
                    <Accordion>
                        {filteredQuestions.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-header ${activeIndexAbout === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion('about', index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndexAbout === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndexAbout === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>
                            </AccordionTab>
                        ))}
                    </Accordion>
                </div>
            )}


            <div className="container-section">
                <div className="search-container">
                </div>
                <h2 className='header-section'>Frequently Asked Questions</h2>
                <>
                    <div className="flex flex-wrap  faq-heading-section">
                        <div className="flex-1 text-boxes text-center p-4 border-round faq-heading" onClick={() => scrollToSection(faqRef)}><div><img src={SchoolIcon} alt="SchoolIcon" /></div>About Laulama</div>
                        <div className="flex-1 text-boxes text-center p-4 border-round faq-heading" onClick={() => scrollToSection(accountRef)}><div><img src={UserSolidIcon} alt="UserSolidIcon" /></div>Account and Profile</div>
                        {/* <div className="flex-1 text-boxes text-center p-4 border-round" onClick={() => scrollToSection(privacyRef)}>Privacy</div> */}
                        {/* <div className="flex-1 text-boxes text-center p-4 border-round faq-heading" onClick={() => scrollToSection(planRef)}><div><img src={PaperPlaneIcon} alt="PaperPlaneIcon" /></div>10 Year Plan Questions</div> */}
                        <div className="flex-1 text-boxes text-center p-4 border-round faq-heading" onClick={() => scrollToSection(technicalRef)}><div><img src={ShieldHalvedIcon} alt="SheildHalvedIcon" /></div>Technical Issues</div>
                    </div>
                </>

                <div ref={faqRef} className='mt-6 faq-sub-headings'>
                    <h2>About Laulama </h2>
                    <Accordion>
                        {faqQuestions.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-header ${activeIndexAbout === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion('about', index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndexAbout === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndexAbout === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>

                            </AccordionTab>
                        ))}
                    </Accordion>
                </div>

                <div ref={accountRef} className='faq-sub-headings'>
                    <h2>Account and Profile</h2>
                    <Accordion>
                        {accountQuestions.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-header ${activeIndexAccount === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion('account', index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndexAccount === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndexAccount === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>
                            </AccordionTab>
                        ))}
                    </Accordion>
                </div>
                {/* <div ref={privacyRef}>
                    <h2>Privacy and Security</h2>
                    <Accordion>
                        {pathwayQuestion.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-header ${activeIndex === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndex === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndex === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>
                            </AccordionTab>
                        ))}
                    </Accordion>
                </div> */}

                {/* <div ref={planRef} className='faq-sub-headings'>
                    <h2>10 Year Plan Questions</h2>
                    <Accordion>
                        {pathwayQuestion.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-header ${activeIndexPlan === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion('plan', index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndexPlan === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndexPlan === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>
                            </AccordionTab>
                        ))}
                    </Accordion>
                </div> */}

                <div ref={technicalRef} className='faq-sub-headings'>
                    <h2>Technical Issues</h2>
                    <Accordion>
                        {technicalQuestions.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-header ${activeIndexTechnical === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion('technical', index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndexTechnical === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndexTechnical === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>
                            </AccordionTab>
                        ))}
                    </Accordion>
                </div>

            </div>

            <FooterSection></FooterSection>
        </>
    );
};

export default FAQ;