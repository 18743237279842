import React, { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import "primeicons/primeicons.css";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import "../CheckList-Progress-Form/CheckList-Progress-Form.scss";
import { CONTENT_HEADERS, GET_HIGH_SCHOOL_CREDITS, GET_HIGH_SCHOOL_PROGRESS, HIGH_SCHOOL_PROGRESS } from './../../../../../Utils/StudentService';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import HighSchoolGRsection from "./HighSchool-GR-Section/HighSchool-GR-Section";
import HighSchoolProgressCourses from "./HighSchool-Progress-Courses/HighSchoolProgress-Courses";
import APCourses from "./APCourses/APCourses";
import CourseCreditMapping from "./CourseCredit";
import { getStatusForCourse, getStatusForElectiveCourse } from "./CourseStatus";


const HighSchoolProgressForm = ({ onNextButtonClick, handleBackButtonClick, highSchoolState }) => {

    const navigate = useNavigate();

    const [englishSub, setEnglishSub] = useState({});
    const [englishElectiveSub, setEnglishElectiveSub] = useState([]);
    const [socialSub, setSocialSub] = useState({});
    const [socialElectiveSub, setSocialElectiveSub] = useState([]);
    const [mathSub, setMathSub] = useState({});
    const [mathElectiveSub, setMathElectiveSub] = useState([]);
    const [scienceSub, setScienceSub] = useState({});
    const [scienceElectiveSub, setScienceElectiveSub] = useState([]);
    const [peSub, setPeSub] = useState({});
    const [peElectiveSub, setPeEl] = useState([]);
    const [healthSub, setHealthSub] = useState({});
    const [ptpSub, setPtpSub] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);

    const [electiveSub, setElectiveSub] = useState({});

    useEffect(() => {
        getCreditsScore();
        fetchHighSchoolProgress();
    }, []);

    const [getCourseResponse, setGetCourseResponse] = useState({});
    const [getElectiveCourseResponse, setGetElectiveCourseResponse] = useState({});
    const [getAPScoreResponse, setGetAPScoreResponse] = useState({});
    const [getCredits, setCredits] = useState({});

    const [getResponse, setGetResponse] = useState({});
    const [teacherNotes, setTeacherNotes] = useState("");
    const [teacherName, setTeacherName] = useState('');
    const [lastUpdatedOn, setLastUpdatedOn] = useState('');

    const getCreditsScore = () => {
        axios
            .get(GET_HIGH_SCHOOL_CREDITS, { headers: CONTENT_HEADERS })
            .then((response) => {
                setCredits(response.data)
            })
            .catch((error) => console.log("Error getting high school progress data", error))
    }

    const fetchHighSchoolProgress = () => {
        axios
            .get(GET_HIGH_SCHOOL_PROGRESS, { headers: CONTENT_HEADERS })
            .then((response) => {

                setGetResponse(response.data);
                const latestTeacherNotes = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.notes;
                const latestTeacherName = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.teacherName;
                const updatedOn = response.data?.updatedOn ? new Date(response.data?.updatedOn).toLocaleDateString() : null;
                const createdOn = response.data?.createdOn ? new Date(response.data?.createdOn).toLocaleDateString() : null;

                setTeacherNotes(latestTeacherNotes);
                const teacherName = latestTeacherName;
                setTeacherName(teacherName);
                const lastUpdatedOn = updatedOn || createdOn;
                setLastUpdatedOn(lastUpdatedOn);

                const latestVersion = Math.max(...(response.data?.highSchoolCourses?.progress || []).map(item => item.versionNumber));
                const latestVersionData = (response.data?.highSchoolCourses?.progress || []).find(item => item.versionNumber === latestVersion);
                const latestVersionElective = Math.max(...(response.data?.electiveCategories?.electives || []).map(item => item.versionNumber));
                const latestElectiveVersionData = (response.data?.electiveCategories?.electives || []).find(item => item.versionNumber === latestVersionElective);

                const highCourseId = (response.data?.highSchoolProgressId)

                setGetCourseResponse({ ...latestVersionData, highCourseId, ...latestElectiveVersionData });
                setGetElectiveCourseResponse({ ...latestElectiveVersionData });

                const highestVersionOfApScore = Math.max(...(response.data?.apScores?.scoresVersion || []).map(item => item.versionNumber));
                const highestVersionOfApScoreData = (response.data?.apScores?.scoresVersion || []).find(item => item.versionNumber === highestVersionOfApScore);
                setGetAPScoreResponse(highestVersionOfApScoreData);

                const electiveSub = (latestElectiveVersionData?.electiveCategories)
                setElectiveSub(electiveSub);

                //

                const englishCategory = latestVersionData?.subjects.find(subject => subject.category === "English");
                const englishCourse = englishCategory ? englishCategory.courses : [];
                setEnglishSub(englishCourse);

                const englishElectiveCategory = englishCategory && englishCategory.electiveCategories && englishCategory.electiveCategories.length > 0 ? englishCategory.electiveCategories[0] : null;
                if (englishElectiveCategory) {
                    setEnglishElectiveSub(englishElectiveCategory.courses);
                }

                const socialCategory = latestVersionData?.subjects.find(subject => subject.category === "Social Studies");
                const socialCourse = socialCategory ? socialCategory.courses : [];
                setSocialSub(socialCourse);

                const socialElectiveCategory = socialCategory && socialCategory.electiveCategories && socialCategory.electiveCategories.length > 0 ? socialCategory.electiveCategories[0] : null;
                if (socialElectiveCategory) {
                    setSocialElectiveSub(socialElectiveCategory.courses);
                }

                const mathCategory = latestVersionData?.subjects.find(subject => subject.category === "Math");
                const mathCourse = mathCategory ? mathCategory.courses : [];
                setMathSub(mathCourse);

                const mathElectiveCategory = mathCategory && mathCategory.electiveCategories && mathCategory.electiveCategories.length > 0 ? mathCategory.electiveCategories[0] : null;
                if (mathElectiveCategory) {
                    setMathElectiveSub(mathElectiveCategory.courses);
                }

                const scienceCategory = latestVersionData?.subjects.find(subject => subject.category === "Science");
                const scienceCourse = scienceCategory ? scienceCategory.courses : [];
                setScienceSub(scienceCourse);

                const scienceElectiveCategory = scienceCategory && scienceCategory.electiveCategories && scienceCategory.electiveCategories.length > 0 ? scienceCategory.electiveCategories[0] : null;
                if (scienceElectiveCategory) {
                    setScienceElectiveSub(scienceElectiveCategory.courses);
                }

                const peCategory = latestVersionData?.subjects.find(subject => subject.category === "PE");
                const peCourses = peCategory ? peCategory.courses : [];
                setPeSub(peCourses);

                const peElectiveCategory = peCategory && peCategory.electiveCategories && peCategory.electiveCategories.length > 0 ? peCategory.electiveCategories[0] : null;
                if (peElectiveCategory) {
                    setPeEl(peElectiveCategory.courses);
                }

                const healthCategory = latestVersionData?.subjects.find(subject => subject.category === "Health");
                const healthCourses = healthCategory ? healthCategory.courses : [];
                setHealthSub(healthCourses);

                const ptpCategory = latestVersionData?.subjects.find(subject => subject.category === "PTP");
                const ptpCourses = ptpCategory ? ptpCategory.courses : [];
                setPtpSub(ptpCourses);
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    const handleSubmitClicks = () => {

        const english = JSON.parse(localStorage.getItem('English')) !== null ? JSON.parse(localStorage.getItem('English')) : englishSub;
        const englishSubjects = english?.length > 0 ? english : null;

        const englishElective = JSON.parse(localStorage.getItem('English Elective')) !== null ? JSON.parse(localStorage.getItem('English Elective')) : englishElectiveSub;
        const englishElectiveSubjects = englishElective && englishElective?.length > 0 ? englishElective : null;

        const socialStudies = JSON.parse(localStorage.getItem('Social Studies')) !== null ? JSON.parse(localStorage.getItem('Social Studies')) : socialSub;
        const socialSubjects = socialStudies?.length > 0 ? socialStudies : null;

        const socialStudiesElective = JSON.parse(localStorage.getItem('Social Studies Elective')) !== null ? JSON.parse(localStorage.getItem('Social Studies Elective')) : socialElectiveSub;
        const socialElectiveSubjects = socialStudiesElective && socialStudiesElective?.length > 0 ? socialStudiesElective : null;

        const math = JSON.parse(localStorage.getItem('Math')) !== null ? JSON.parse(localStorage.getItem('Math')) : mathSub;
        const mathSubjects = math.length > 0 ? math : null;

        const mathElectives = JSON.parse(localStorage.getItem('Math Elective')) !== null ? JSON.parse(localStorage.getItem('Math Elective')) : mathElectiveSub;
        const mathElectivessubjects = mathElectives && mathElectives?.length > 0 ? mathElectives : null;
        // const mathElectivessubjects = Array.isArray(mathElectives) && mathElectives.length > 0 ? mathElectives : null;

        const science = JSON.parse(localStorage.getItem('Science')) !== null ? JSON.parse(localStorage.getItem('Science')) : scienceSub;
        const scienceSubjects = science.length > 0 ? science : null;

        const scienceElectives = JSON.parse(localStorage.getItem('Science Elective')) !== null ? JSON.parse(localStorage.getItem('Science Elective')) : scienceElectiveSub;
        const scienceElectiveSubjects = scienceElectives.length > 0 ? scienceElectives : null;

        const pe = JSON.parse(localStorage.getItem('PE')) !== null ? JSON.parse(localStorage.getItem('PE')) : peSub;
        const peSubjects = pe.length > 0 ? pe : null;

        const peElectives = JSON.parse(localStorage.getItem('PE Elective')) !== null ? JSON.parse(localStorage.getItem('PE Elective')) : peElectiveSub;
        const peElectiveSubjects = peElectives && peElectives.length > 0 ? peElectives : null;

        const health = JSON.parse(localStorage.getItem('Health')) !== null ? JSON.parse(localStorage.getItem('Health')) : healthSub;
        const healthSubjects = health.length > 0 ? health : null;

        const ptp = JSON.parse(localStorage.getItem('PTP')) !== null ? JSON.parse(localStorage.getItem('PTP')) : ptpSub;
        const ptpSubjects = ptp.length > 0 ? ptp : null;

        // English

        let EnglishSubjects;
        const englishData = JSON.parse(localStorage.getItem('English'));

        if (englishData !== null) {
            const englishElectiveCategoriesArray = englishElective ? Object.keys(englishElective).map(courseName => {

                const creditValue = CourseCreditMapping[courseName] || "";
                return {
                    categoryName: courseName,
                    courseCategory: "English Elective",
                    courses: [{
                        course: courseName,
                        area: "English Elective",
                        creditValue: creditValue,
                        status: getStatusForCourse(courseName, getCourseResponse)
                    }],
                }
            }) : [];

            EnglishSubjects = {
                category: "English",
                courses: englishSubjects?.length > 0 ? englishSubjects.map(course => {
                    const creditValue = CourseCreditMapping[course] || "";
                    return {
                        course,
                        area: "English",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }
                }) : [],
                electiveCategories: englishElectiveCategoriesArray
            };
        } else if (englishData !== null) {
            let output;

            if (englishElectiveSubjects !== null) {
                output = englishElectiveSubjects.map(entry => {
                    const creditValue = CourseCreditMapping[entry.course] || "";
                    return {
                        categoryName: entry.course,
                        courseCategory: "English Elective",
                        courses: [{
                            course: entry.course,
                            area: "English Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(entry.course, getCourseResponse)
                        }],
                    }
                });
            } else {
                output = [];
            }

            EnglishSubjects = {
                category: "English",
                courses: englishSubjects?.length > 0 ? englishSubjects : englishSub,
                electiveCategories: output
            };
        }

        // Social

        let SocialSubjects;
        const socialData = JSON.parse(localStorage.getItem('Social Studies'));

        if (socialData !== null) {
            const socialElectiveCategoriesArray = socialStudiesElective ? Object.keys(socialStudiesElective).map(courseName => {

                const creditValue = CourseCreditMapping[courseName] || "";
                return {
                    categoryName: courseName,
                    courseCategory: "Social Elective",
                    courses: [{
                        course: courseName,
                        area: "Social Elective",
                        creditValue: creditValue,
                        status: getStatusForCourse(courseName, getCourseResponse)
                    }],
                }
            }) : [];

            SocialSubjects = {
                category: "Social Studies",
                courses: socialSubjects?.length > 0 ? socialSubjects.map(course => {
                    const creditValue = CourseCreditMapping[course] || "";
                    return {
                        course,
                        area: "Social Studies",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }
                }) : [],
                electiveCategories: socialElectiveCategoriesArray
            };
        } else if (socialData === null) {
            let output;

            if (socialElectiveSubjects !== null) {
                output = socialElectiveSubjects.map(entry => {
                    const creditValue = CourseCreditMapping[entry.course] || "";
                    return {
                        categoryName: entry.course,
                        courseCategory: "Social Elective",
                        courses: [{
                            course: entry.course,
                            area: "Social Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(entry.course, getCourseResponse)
                        }],
                    }
                });
            } else {
                output = [];
            }

            SocialSubjects = {
                category: "Social Studies",
                courses: socialSubjects?.length > 0 ? socialSubjects : socialSub,
                electiveCategories: output
            };
        }

        // Math

        let MathSubjects;
        const mathData = JSON.parse(localStorage.getItem('Math'));

        if (mathData !== null) {
            const mathElectiveCategoriesArray = mathElectives ? Object.keys(mathElectives).map(courseName => {

                const creditValue = CourseCreditMapping[courseName] || "";
                return {
                    categoryName: courseName,
                    courseCategory: "Math Elective",
                    courses: [
                        {
                            course: courseName,
                            area: "Math Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(courseName, getCourseResponse)
                        }
                    ],
                }
            }) : [];

            MathSubjects = {
                category: "Math",
                courses: mathSubjects?.length > 0 ? mathSubjects.map(course => {
                    const creditValue = CourseCreditMapping[course] || "";

                    return {
                        course,
                        area: "Math",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }

                }) : [],
                electiveCategories: mathElectiveCategoriesArray
            };
        } else if (mathData === null) {
            let output;

            if (mathElectivessubjects !== null) {
                output = mathElectivessubjects.map(entry => {
                    const creditValue = CourseCreditMapping[entry.course] || "";
                    return {
                        categoryName: entry.course,
                        courseCategory: "Math Elective",
                        courses: [{
                            course: entry.course,
                            area: "Math Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(entry.course, getCourseResponse)
                        }],
                    }
                });
            } else {
                output = [];
            }

            MathSubjects = {
                category: "Math",
                courses: mathSubjects?.length > 0 ? mathSubjects : mathSub,
                electiveCategories: output
            };
        }

        // Science

        let ScienceSubjects;
        const scienceData = JSON.parse(localStorage.getItem('Science'));

        if (scienceData !== null) {
            const scienceElectiveCategoriesArray = scienceElectives ? Object.keys(scienceElectives).map(courseName => {

                const creditValue = CourseCreditMapping[courseName] || "";
                return {
                    categoryName: courseName,
                    courseCategory: "Science Elective",
                    courses: [{
                        course: courseName,
                        area: "Science Elective",
                        creditValue: creditValue,
                        status: getStatusForCourse(courseName, getCourseResponse)
                    }]
                }
            })
                : [];

            ScienceSubjects = {
                category: "Science",
                courses: scienceSubjects.map(course => {
                    const creditValue = CourseCreditMapping[course] || "";
                    return {
                        course,
                        area: "Science",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }
                }),
                electiveCategories: scienceElectiveCategoriesArray
            };
        } else if (scienceData === null) {

            let output;

            if (scienceElectiveSubjects !== null) {
                output = scienceElectiveSubjects.map(entry => {
                    const creditValue = CourseCreditMapping[entry.course] || "";

                    return {
                        categoryName: entry.course,
                        courseCategory: "Science Elective",
                        courses: [{
                            course: entry.course,
                            area: "Science Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(entry.course, getCourseResponse)
                        }]
                    };
                });
            } else {
                output = [];
            }

            ScienceSubjects = {
                category: "Science",
                courses: scienceSubjects?.length > 0 ? scienceSubjects : scienceSub,
                electiveCategories: output
            };
        }

        // Physical Education

        let PESubjects;
        const peData = JSON.parse(localStorage.getItem('PE'));

        if (peData !== null) {
            const peElectiveCategoriesArray = peElectives ? Object.entries(peElectives).map(([course]) => {

                const creditValue = CourseCreditMapping[course] || "";
                return {
                    categoryName: course,
                    courseCategory: "PE Elective",
                    courses: [{
                        course,
                        area: "PE Elective",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }],
                }
            })
                : [];

            PESubjects = {
                category: "PE",
                courses: peSubjects?.length > 0 ? peSubjects.map(course => {
                    const creditValue = CourseCreditMapping[course] || "";

                    return {
                        course,
                        area: "PE",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }

                }) : [],
                electiveCategories: peElectiveCategoriesArray
            };
        } else if (peData === null) {

            let output;

            if (peElectiveSubjects !== null) {
                output = peElectiveSubjects.map(entry => {
                    const creditValue = CourseCreditMapping[entry.course] || "";

                    return {
                        categoryName: entry.course,
                        courseCategory: "PE Elective",
                        courses: [{
                            course: entry.course,
                            area: "PE Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(entry.course, getCourseResponse)
                        }]
                    };
                });
            } else {
                output = [];
            }

            PESubjects = {
                category: "PE",
                courses: peSubjects?.length > 0 ? peSubjects : peSub,
                electiveCategories: output
            };

        }

        // Health

        const HealthSubjects = healthSubjects && healthSubjects.length > 0 ? healthSubjects.map(course => {
            const creditValue = CourseCreditMapping[course] || "";

            return {
                category: "Health",
                courses: [{
                    course: course,
                    area: "Health",
                    creditValue: creditValue,
                    status: getStatusForCourse(course, getCourseResponse)
                }],
                electiveCategories: []
            }
        }
        ) : [];

        // PTP

        const PTPSubjects = ptpSubjects && ptpSubjects.length > 0 ? ptpSubjects.map(course => {
            const creditValue = CourseCreditMapping[course] || "";

            return {
                category: "PTP",
                courses: [{
                    course: course,
                    area: "PTP",
                    creditValue: creditValue,
                    status: getStatusForCourse(course, getCourseResponse)
                }],
                electiveCategories: []
            }
        }
        ) : [];

        const Electives = JSON.parse(localStorage.getItem('Electives'));
        let electiveCategories;

        if (Electives !== null && Electives.length > 0) {
            const groupedCategories = {};


            Electives.forEach(item => {
                const { categoryName, courseCategory, label } = item;
                const creditValue = CourseCreditMapping[label] || "";

                if (!groupedCategories[categoryName]) {
                    groupedCategories[categoryName] = {};
                }

                if (courseCategory) {
                    if (!groupedCategories[categoryName][courseCategory]) {
                        groupedCategories[categoryName][courseCategory] = [];
                    }

                    groupedCategories[categoryName][courseCategory].push({
                        course: label,
                        area: courseCategory,
                        creditValue: creditValue,
                        status: getStatusForElectiveCourse(label, getElectiveCourseResponse)
                    });

                } else {
                    if (!groupedCategories[categoryName]['Uncategorized']) {
                        groupedCategories[categoryName]['Uncategorized'] = [];
                    }

                    groupedCategories[categoryName]['Uncategorized'].push({
                        course: label,
                        area: categoryName,
                        creditValue: creditValue,
                        status: getStatusForElectiveCourse(label, getElectiveCourseResponse)
                    });
                }
            });

            electiveCategories = Object.keys(groupedCategories).map(categoryName => {
                const courseCategories = Object.keys(groupedCategories[categoryName]).map(courseCategory => {
                    return {
                        categoryName,
                        courseCategory,
                        courses: groupedCategories[categoryName][courseCategory]
                    };
                });
                return courseCategories;
            }).flat().filter(item => item.courseCategory !== null);
        } else {
            if (Array.isArray(electiveSub)) {
                const groupedCategories = {};

                electiveSub.forEach(item => {
                    const { categoryName, courseCategory, courses } = item;

                    if (!groupedCategories[categoryName]) {
                        groupedCategories[categoryName] = {};
                    }

                    if (courseCategory) {
                        if (!groupedCategories[categoryName][courseCategory]) {
                            groupedCategories[categoryName][courseCategory] = [];
                        }
                        groupedCategories[categoryName][courseCategory].push(...courses);
                    } else {
                        if (!groupedCategories[categoryName]['Uncategorized']) {
                            groupedCategories[categoryName]['Uncategorized'] = [];
                        }
                        groupedCategories[categoryName]['Uncategorized'].push(...courses);
                    }
                });

                electiveCategories = Object.keys(groupedCategories).map(categoryName => {
                    const courseCategories = Object.keys(groupedCategories[categoryName]).map(courseCategory => {
                        return {
                            categoryName,
                            ...(courseCategory !== 'Uncategorized' && { courseCategory }),
                            courses: groupedCategories[categoryName][courseCategory]
                        };
                    });
                    return courseCategories;
                }).flat().filter(item => item.courseCategory !== null);
            } else {
                electiveCategories = [];
            }
        }

        const highSchoolCourses = [...HealthSubjects, ...PTPSubjects];

        if (EnglishSubjects && (EnglishSubjects.courses.length > 0 || (EnglishSubjects.electiveCategories && EnglishSubjects.electiveCategories.length > 0))) {
            highSchoolCourses.push(EnglishSubjects);
        }
        if (SocialSubjects && (SocialSubjects.courses.length > 0 || (SocialSubjects.electiveCategories && SocialSubjects.electiveCategories.length > 0))) {
            highSchoolCourses.push(SocialSubjects);
        }

        if (MathSubjects && (MathSubjects.courses.length > 0 || (MathSubjects.electiveCategories && MathSubjects.electiveCategories.length > 0))) {
            highSchoolCourses.push(MathSubjects);
        }

        if (ScienceSubjects && (ScienceSubjects.courses.length > 0 || (ScienceSubjects.electiveCategories && ScienceSubjects.electiveCategories.length > 0))) {
            highSchoolCourses.push(ScienceSubjects);
        }

        if (PESubjects && (PESubjects.courses.length > 0 || (PESubjects.electiveCategories && PESubjects.electiveCategories.length > 0))) {
            highSchoolCourses.push(PESubjects);
        }

        const apCourseScores = JSON.parse(localStorage.getItem('AP_Courses')) || [];

        const checklistpayload = {
            "highSchoolCourses": highSchoolCourses,
            "electiveCategories": electiveCategories,
            "apScores": apCourseScores
        }

        axios
            .post(HIGH_SCHOOL_PROGRESS, checklistpayload, { headers: CONTENT_HEADERS })
            .then((response) => {
                fetchHighSchoolProgress();
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const CheckListProgressItems = [
        {
            label: 'Graduation Requirements',
            component: <HighSchoolGRsection getApiResponse={getCredits} />,
        },
        {
            label: 'High School Courses',
            component: <HighSchoolProgressCourses getApiResponse={getCourseResponse} onStatusChange={fetchHighSchoolProgress} onSubmit={handleSubmitClicks} />,
        },
        {
            label: 'AP Score',
            component: <APCourses getApiResponse={getAPScoreResponse} />,
        }
    ];

    const isTabOpen = (index) => activeIndex === index;
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    const handleSubmitClick = () => {

        const english = JSON.parse(localStorage.getItem('English')) !== null ? JSON.parse(localStorage.getItem('English')) : englishSub;
        const englishSubjects = english?.length > 0 ? english : null;

        const englishElective = JSON.parse(localStorage.getItem('English Elective')) !== null ? JSON.parse(localStorage.getItem('English Elective')) : englishElectiveSub;
        const englishElectiveSubjects = englishElective && englishElective?.length > 0 ? englishElective : null;

        const socialStudies = JSON.parse(localStorage.getItem('Social Studies')) !== null ? JSON.parse(localStorage.getItem('Social Studies')) : socialSub;
        const socialSubjects = socialStudies?.length > 0 ? socialStudies : null;

        const socialStudiesElective = JSON.parse(localStorage.getItem('Social Studies Elective')) !== null ? JSON.parse(localStorage.getItem('Social Studies Elective')) : socialElectiveSub;
        const socialElectiveSubjects = socialStudiesElective && socialStudiesElective?.length > 0 ? socialStudiesElective : null;

        const math = JSON.parse(localStorage.getItem('Math')) !== null ? JSON.parse(localStorage.getItem('Math')) : mathSub;
        const mathSubjects = math.length > 0 ? math : null;

        const mathElectives = JSON.parse(localStorage.getItem('Math Elective')) !== null ? JSON.parse(localStorage.getItem('Math Elective')) : mathElectiveSub;
        const mathElectivessubjects = mathElectives && mathElectives?.length > 0 ? mathElectives : null;
        // const mathElectivessubjects = Array.isArray(mathElectives) && mathElectives.length > 0 ? mathElectives : null;

        const science = JSON.parse(localStorage.getItem('Science')) !== null ? JSON.parse(localStorage.getItem('Science')) : scienceSub;
        const scienceSubjects = science.length > 0 ? science : null;

        const scienceElectives = JSON.parse(localStorage.getItem('Science Elective')) !== null ? JSON.parse(localStorage.getItem('Science Elective')) : scienceElectiveSub;
        const scienceElectiveSubjects = scienceElectives.length > 0 ? scienceElectives : null;

        const pe = JSON.parse(localStorage.getItem('PE')) !== null ? JSON.parse(localStorage.getItem('PE')) : peSub;
        const peSubjects = pe.length > 0 ? pe : null;

        const peElectives = JSON.parse(localStorage.getItem('PE Elective')) !== null ? JSON.parse(localStorage.getItem('PE Elective')) : peElectiveSub;
        const peElectiveSubjects = peElectives && peElectives.length > 0 ? peElectives : null;

        const health = JSON.parse(localStorage.getItem('Health')) !== null ? JSON.parse(localStorage.getItem('Health')) : healthSub;
        const healthSubjects = health.length > 0 ? health : null;

        const ptp = JSON.parse(localStorage.getItem('PTP')) !== null ? JSON.parse(localStorage.getItem('PTP')) : ptpSub;
        const ptpSubjects = ptp.length > 0 ? ptp : null;

        // English

        let EnglishSubjects;
        const englishData = JSON.parse(localStorage.getItem('English'));

        if (englishData !== null) {
            const englishElectiveCategoriesArray = englishElective ? Object.keys(englishElective).map(courseName => {

                const creditValue = CourseCreditMapping[courseName] || "";
                return {
                    categoryName: courseName,
                    courseCategory: "English Elective",
                    courses: [{
                        course: courseName,
                        area: "English Elective",
                        creditValue: creditValue,
                        status: getStatusForCourse(courseName, getCourseResponse)
                    }],
                }
            }) : [];

            EnglishSubjects = {
                category: "English",
                courses: englishSubjects?.length > 0 ? englishSubjects.map(course => {
                    const creditValue = CourseCreditMapping[course] || "";
                    return {
                        course,
                        area: "English",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }
                }) : [],
                electiveCategories: englishElectiveCategoriesArray
            };
        } else if (englishData !== null) {
            let output;

            if (englishElectiveSubjects !== null) {
                output = englishElectiveSubjects.map(entry => {
                    const creditValue = CourseCreditMapping[entry.course] || "";
                    return {
                        categoryName: entry.course,
                        courseCategory: "English Elective",
                        courses: [{
                            course: entry.course,
                            area: "English Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(entry.course, getCourseResponse)
                        }],
                    }
                });
            } else {
                output = [];
            }

            EnglishSubjects = {
                category: "English",
                courses: englishSubjects?.length > 0 ? englishSubjects : englishSub,
                electiveCategories: output
            };
        }

        // Social

        let SocialSubjects;
        const socialData = JSON.parse(localStorage.getItem('Social Studies'));

        if (socialData !== null) {
            const socialElectiveCategoriesArray = socialStudiesElective ? Object.keys(socialStudiesElective).map(courseName => {

                const creditValue = CourseCreditMapping[courseName] || "";
                return {
                    categoryName: courseName,
                    courseCategory: "Social Elective",
                    courses: [{
                        course: courseName,
                        area: "Social Elective",
                        creditValue: creditValue,
                        status: getStatusForCourse(courseName, getCourseResponse)
                    }],
                }
            }) : [];

            SocialSubjects = {
                category: "Social Studies",
                courses: socialSubjects?.length > 0 ? socialSubjects.map(course => {
                    const creditValue = CourseCreditMapping[course] || "";
                    return {
                        course,
                        area: "Social Studies",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }
                }) : [],
                electiveCategories: socialElectiveCategoriesArray
            };
        } else if (socialData === null) {
            let output;

            if (socialElectiveSubjects !== null) {
                output = socialElectiveSubjects.map(entry => {
                    const creditValue = CourseCreditMapping[entry.course] || "";
                    return {
                        categoryName: entry.course,
                        courseCategory: "Social Elective",
                        courses: [{
                            course: entry.course,
                            area: "Social Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(entry.course, getCourseResponse)
                        }],
                    }
                });
            } else {
                output = [];
            }

            SocialSubjects = {
                category: "Social Studies",
                courses: socialSubjects?.length > 0 ? socialSubjects : socialSub,
                electiveCategories: output
            };
        }

        // Math

        let MathSubjects;
        const mathData = JSON.parse(localStorage.getItem('Math'));

        if (mathData !== null) {
            const mathElectiveCategoriesArray = mathElectives ? Object.keys(mathElectives).map(courseName => {

                const creditValue = CourseCreditMapping[courseName] || "";
                return {
                    categoryName: courseName,
                    courseCategory: "Math Elective",
                    courses: [
                        {
                            course: courseName,
                            area: "Math Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(courseName, getCourseResponse)
                        }
                    ],
                }
            }) : [];

            MathSubjects = {
                category: "Math",
                courses: mathSubjects?.length > 0 ? mathSubjects.map(course => {
                    const creditValue = CourseCreditMapping[course] || "";

                    return {
                        course,
                        area: "Math",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }

                }) : [],
                electiveCategories: mathElectiveCategoriesArray
            };
        } else if (mathData === null) {
            let output;

            if (mathElectivessubjects !== null) {
                output = mathElectivessubjects.map(entry => {
                    const creditValue = CourseCreditMapping[entry.course] || "";
                    return {
                        categoryName: entry.course,
                        courseCategory: "Math Elective",
                        courses: [{
                            course: entry.course,
                            area: "Math Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(entry.course, getCourseResponse)
                        }],
                    }
                });
            } else {
                output = [];
            }

            MathSubjects = {
                category: "Math",
                courses: mathSubjects?.length > 0 ? mathSubjects : mathSub,
                electiveCategories: output
            };
        }

        // Science

        let ScienceSubjects;
        const scienceData = JSON.parse(localStorage.getItem('Science'));

        if (scienceData !== null) {
            const scienceElectiveCategoriesArray = scienceElectives ? Object.keys(scienceElectives).map(courseName => {

                const creditValue = CourseCreditMapping[courseName] || "";
                return {
                    categoryName: courseName,
                    courseCategory: "Science Elective",
                    courses: [{
                        course: courseName,
                        area: "Science Elective",
                        creditValue: creditValue,
                        status: getStatusForCourse(courseName, getCourseResponse)
                    }]
                }
            })
                : [];

            ScienceSubjects = {
                category: "Science",
                courses: scienceSubjects.map(course => {
                    const creditValue = CourseCreditMapping[course] || "";
                    return {
                        course,
                        area: "Science",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }
                }),
                electiveCategories: scienceElectiveCategoriesArray
            };
        } else if (scienceData === null) {

            let output;

            if (scienceElectiveSubjects !== null) {
                output = scienceElectiveSubjects.map(entry => {
                    const creditValue = CourseCreditMapping[entry.course] || "";

                    return {
                        categoryName: entry.course,
                        courseCategory: "Science Elective",
                        courses: [{
                            course: entry.course,
                            area: "Science Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(entry.course, getCourseResponse)
                        }]
                    };
                });
            } else {
                output = [];
            }

            ScienceSubjects = {
                category: "Science",
                courses: scienceSubjects?.length > 0 ? scienceSubjects : scienceSub,
                electiveCategories: output
            };
        }

        // Physical Education

        let PESubjects;
        const peData = JSON.parse(localStorage.getItem('PE'));

        if (peData !== null) {
            const peElectiveCategoriesArray = peElectives ? Object.entries(peElectives).map(([course]) => {

                const creditValue = CourseCreditMapping[course] || "";
                return {
                    categoryName: course,
                    courseCategory: "PE Elective",
                    courses: [{
                        course,
                        area: "PE Elective",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }],
                }
            })
                : [];

            PESubjects = {
                category: "PE",
                courses: peSubjects?.length > 0 ? peSubjects.map(course => {
                    const creditValue = CourseCreditMapping[course] || "";

                    return {
                        course,
                        area: "PE",
                        creditValue: creditValue,
                        status: getStatusForCourse(course, getCourseResponse)
                    }

                }) : [],
                electiveCategories: peElectiveCategoriesArray
            };
        } else if (peData === null) {

            let output;

            if (peElectiveSubjects !== null) {
                output = peElectiveSubjects.map(entry => {
                    const creditValue = CourseCreditMapping[entry.course] || "";

                    return {
                        categoryName: entry.course,
                        courseCategory: "PE Elective",
                        courses: [{
                            course: entry.course,
                            area: "PE Elective",
                            creditValue: creditValue,
                            status: getStatusForCourse(entry.course, getCourseResponse)
                        }]
                    };
                });
            } else {
                output = [];
            }

            PESubjects = {
                category: "PE",
                courses: peSubjects?.length > 0 ? peSubjects : peSub,
                electiveCategories: output
            };

        }

        // Health

        const HealthSubjects = healthSubjects && healthSubjects.length > 0 ? healthSubjects.map(course => {
            const creditValue = CourseCreditMapping[course] || "";

            return {
                category: "Health",
                courses: [{
                    course: course,
                    area: "Health",
                    creditValue: creditValue,
                    status: getStatusForCourse(course, getCourseResponse)
                }],
                electiveCategories: []
            }
        }
        ) : [];

        // PTP

        const PTPSubjects = ptpSubjects && ptpSubjects.length > 0 ? ptpSubjects.map(course => {
            const creditValue = CourseCreditMapping[course] || "";

            return {
                category: "PTP",
                courses: [{
                    course: course,
                    area: "PTP",
                    creditValue: creditValue,
                    status: getStatusForCourse(course, getCourseResponse)
                }],
                electiveCategories: []
            }
        }
        ) : [];

        const Electives = JSON.parse(localStorage.getItem('Electives'));
        let electiveCategories;

        if (Electives !== null && Electives.length > 0) {
            const groupedCategories = {};


            Electives.forEach(item => {
                const { categoryName, courseCategory, label } = item;
                const creditValue = CourseCreditMapping[label] || "";

                if (!groupedCategories[categoryName]) {
                    groupedCategories[categoryName] = {};
                }

                if (courseCategory) {
                    if (!groupedCategories[categoryName][courseCategory]) {
                        groupedCategories[categoryName][courseCategory] = [];
                    }

                    groupedCategories[categoryName][courseCategory].push({
                        course: label,
                        area: courseCategory,
                        creditValue: creditValue,
                        status: getStatusForElectiveCourse(label, getElectiveCourseResponse)
                    });

                } else {
                    if (!groupedCategories[categoryName]['Uncategorized']) {
                        groupedCategories[categoryName]['Uncategorized'] = [];
                    }

                    groupedCategories[categoryName]['Uncategorized'].push({
                        course: label,
                        area: categoryName,
                        creditValue: creditValue,
                        status: getStatusForElectiveCourse(label, getElectiveCourseResponse)
                    });
                }
            });

            electiveCategories = Object.keys(groupedCategories).map(categoryName => {
                const courseCategories = Object.keys(groupedCategories[categoryName]).map(courseCategory => {
                    return {
                        categoryName,
                        courseCategory,
                        courses: groupedCategories[categoryName][courseCategory]
                    };
                });
                return courseCategories;
            }).flat().filter(item => item.courseCategory !== null);
        } else {
            if (Array.isArray(electiveSub)) {
                const groupedCategories = {};

                electiveSub.forEach(item => {
                    const { categoryName, courseCategory, courses } = item;

                    if (!groupedCategories[categoryName]) {
                        groupedCategories[categoryName] = {};
                    }

                    if (courseCategory) {
                        if (!groupedCategories[categoryName][courseCategory]) {
                            groupedCategories[categoryName][courseCategory] = [];
                        }
                        groupedCategories[categoryName][courseCategory].push(...courses);
                    } else {
                        if (!groupedCategories[categoryName]['Uncategorized']) {
                            groupedCategories[categoryName]['Uncategorized'] = [];
                        }
                        groupedCategories[categoryName]['Uncategorized'].push(...courses);
                    }
                });

                electiveCategories = Object.keys(groupedCategories).map(categoryName => {
                    const courseCategories = Object.keys(groupedCategories[categoryName]).map(courseCategory => {
                        return {
                            categoryName,
                            ...(courseCategory !== 'Uncategorized' && { courseCategory }),
                            courses: groupedCategories[categoryName][courseCategory]
                        };
                    });
                    return courseCategories;
                }).flat().filter(item => item.courseCategory !== null);
            } else {
                electiveCategories = [];
            }
        }

        const highSchoolCourses = [...HealthSubjects, ...PTPSubjects];

        if (EnglishSubjects && (EnglishSubjects.courses.length > 0 || (EnglishSubjects.electiveCategories && EnglishSubjects.electiveCategories.length > 0))) {
            highSchoolCourses.push(EnglishSubjects);
        }
        if (SocialSubjects && (SocialSubjects.courses.length > 0 || (SocialSubjects.electiveCategories && SocialSubjects.electiveCategories.length > 0))) {
            highSchoolCourses.push(SocialSubjects);
        }

        if (MathSubjects && (MathSubjects.courses.length > 0 || (MathSubjects.electiveCategories && MathSubjects.electiveCategories.length > 0))) {
            highSchoolCourses.push(MathSubjects);
        }

        if (ScienceSubjects && (ScienceSubjects.courses.length > 0 || (ScienceSubjects.electiveCategories && ScienceSubjects.electiveCategories.length > 0))) {
            highSchoolCourses.push(ScienceSubjects);
        }

        if (PESubjects && (PESubjects.courses.length > 0 || (PESubjects.electiveCategories && PESubjects.electiveCategories.length > 0))) {
            highSchoolCourses.push(PESubjects);
        }

        const apCourseScores = JSON.parse(localStorage.getItem('AP_Courses')) || [];

        const checklistpayload = {
            "highSchoolCourses": highSchoolCourses,
            "electiveCategories": electiveCategories,
            "apScores": apCourseScores
        }

        axios
            .post(HIGH_SCHOOL_PROGRESS, checklistpayload, { headers: CONTENT_HEADERS })
            .then((response) => {
                if (window.innerWidth > 1008) {
                    if ((tabState && buttonState) || (tabState === null && buttonState && !highSchoolState)) {
                        handleNextClick();
                    }
                }
                else if (response.status === 200) {
                    navigate('/student/testScore');
                } else {
                    console.log(response)
                }
            })
            .catch((err) => {
                console.error(err);
            })

            setIsDisabled(true);
    }

    const [tabState, setTabState] = useState(null);
    const [buttonState, setButtonState] = useState(false);

    useEffect(() => {
        if (!highSchoolState && !tabState) {
            setButtonState(true);
        }

        if (highSchoolState) {
            handleSubmitClick();
            setTabState(true);
        }
    }, [highSchoolState]);

    const handleNextClick = () => {
        onNextButtonClick();
    };

    const handleBackClick = () => {
        if (window.innerWidth > 1008) {
            handleBackButtonClick();
        }
        else {
            navigate('/student/academypathway');
        }
    };


    const accordionItems = CheckListProgressItems.map((progressItem) => ({
        title: progressItem.label,
        content: (
            <div className="accordion-content">
                <div className="checklist-item">
                    <div className="accordion-header">
                    </div>
                    <div className="checklist-item-content">
                        {progressItem.component}
                    </div>
                </div>
            </div>
        )
    }));

    const [activeIndexteacher, setActiveIndexteacher] = useState(null);

    const handleClick = (index) => {
        setActiveIndexteacher(activeIndexteacher === index ? null : index);
    };

    const toggleAccordionTeacherNotes = (index) => {
        if (activeIndexteacher === index) {
            setActiveIndexteacher(null);
        } else {
            setActiveIndexteacher(index);
        }
    };
    const teacherNotesAccordionItems = [
        {
            title: "Teacher Notes",
            content: (
                <div className="accordion-content">
                    <>
                        {teacherNotes ? (
                            <div className='teacher-notes-section m-5'>
                                <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                            </div>
                        ) : (
                            <div className='teacher-notes-section m-5'>
                                <p>There are no teacher notes.</p>
                            </div>
                        )}
                    </>
                </div>
            )
        }
    ];

    return (
        <div className="card checklist-card-section">
            <Card className="checklist-card m-4">
                <div className="extra-activities-form">
                    <div className='desktop-highschool'>
                        <Accordion
                            activeIndex={activeIndex}
                            onTabChange={(e) => toggleAccordion(e.index)}
                        >
                            {CheckListProgressItems.map((item, index) => (
                                <AccordionTab
                                    key={index}
                                    header={
                                        <div className="accordion-header">
                                            <div className="header-text">{item.label}</div>
                                            <div className="header-icon">
                                                <i
                                                    className={`pi ${isTabOpen(index) ? "pi-minus" : "pi-plus"
                                                        }`}
                                                ></i>
                                            </div>
                                        </div>
                                    }
                                >
                                    <div className="">
                                        {item.component}
                                    </div>
                                </AccordionTab>
                            ))}
                        </Accordion>

                        {teacherNotes && (
                            <div className='teacher-notes-section mt-5'>
                                <h3 className='head'>Teacher Notes</h3>
                                <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                            </div>
                        )}
                    </div>

                    <div className='mobile-highschool'>
                        <Accordion activeIndex={activeIndex} onTabChange={(e) => toggleAccordion(e.index)}>
                            {accordionItems.map((item, index) => (
                                <AccordionTab key={index} header={
                                    <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                                        <div className="header-text">{item.title}</div>
                                        <div className="header-icon">
                                            <i className={`pi ${activeIndex === index ? "pi-minus" : "pi-plus"}`}></i>
                                        </div>
                                    </div>
                                }>
                                    <div className="accordion-content">
                                        {item.content}
                                    </div>
                                </AccordionTab>
                            ))}
                        </Accordion>

                        <Accordion activeIndex={activeIndexteacher} onTabChange={(e) => toggleAccordionTeacherNotes(e.index)}>
                            {teacherNotesAccordionItems.map((item, index) => (
                                 item.title === "Teacher Notes" && !teacherNotes ? null : (
                                <AccordionTab key={index} header={
                                    <div className="accordion-header" onClick={() => handleClick(index)}>
                                        <div className="header-text">{item.title}</div>
                                        <div className="header-icon">
                                            <i className={`pi ${activeIndexteacher === index ? "pi-minus" : "pi-plus"}`}></i>
                                        </div>
                                    </div>
                                }>
                                    <div className="accordion-content">
                                        {item.content}
                                    </div>
                                </AccordionTab>
                                 )
                            ))}
                        </Accordion>

                    </div>

                    <footer className="form-footer">
                        <Button
                            label="Back"
                            severity="secondary"
                            onClick={handleBackClick} />
                        <Button
                            label="Save and Continue"
                            onClick={handleSubmitClick}
                            disabled={isDisabled}
                        />
                    </footer>
                </div>
            </Card>
        </div>
    );
};

export default HighSchoolProgressForm;