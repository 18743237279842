import React from "react";
import FooterLogo from "../../../Assets/Logo/Footer-Logo.svg";
import { Divider } from "primereact/divider";
import "./FooterSection.scss";
const FooterSection = () => {
  return (
    <>
      <div className="laulama-footer-section grid ml-0 mr-1">
        <div className="footer-logo col-fixed m-5" style={{ width: "400px" }}>
          <img src={FooterLogo} alt="Logo" />
          <Divider />
          <div className="sm:w-full">
            Join now to receive personalized Recommendation from the full
            catalog
          </div>
        </div>
        <div className="quick-links col pl-5 m-3">
          <h4>Quick Links:</h4>
          <ul>
            {/* <li> <a href="/aboutus">About Us</a> </li> */}
            <li> <a href="/faq">FAQ</a> </li>
            <li><a href="/subscription">Subscription</a></li>
            <li> <a href="/stories">Testimonials</a></li>
            {/* <li>Media</li> */}
          </ul>
        </div>
        <div className="legals col pl-5  m-3">
          <h4>Legals:</h4>
          <ul>
            <li>
              <a href="https://staticfile.laulamaschools.com/LaulamaPrivacyPolicies.pdf" target="_blank" >Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div className="social-media pl-5 col m-3">
          <h4>Contact with Us:</h4>
          <p className="mb-3">Contact Us</p>
          <h4>Social Media:</h4>
          <i className="pi pi-facebook mr-3" style={{ fontSize: "1.5rem" }}></i>
          <i className="pi pi-instagram" style={{ fontSize: "1.5rem" }}></i>
        </div>
      </div>
    </>
  );
};

export default FooterSection;
