import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { REFRESH_TOKEN_URL } from '../Utils/LoginService';

export const TokenContext = createContext();

const TokenProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(localStorage.getItem('userAccessToken') || '');
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('userRefreshToken') || '');

    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(REFRESH_TOKEN_URL + refreshToken, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            })
                .then((refreshResponse) => {
                    const newAccessToken = refreshResponse.data.access_token;
                    const newRefreshToken = refreshResponse.data.refresh_token;

                    setAccessToken(newAccessToken);
                    setRefreshToken(newRefreshToken);

                    localStorage.setItem('userAccessToken', newAccessToken);
                    localStorage.setItem('userRefreshToken', newRefreshToken);
                })
                .catch((error) => {
                    console.log(error);
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = '/';
                });
        }, 3100000);

        return () => clearInterval(interval);
    }, [accessToken, refreshToken]);

    return (
        <TokenContext.Provider value={{ accessToken, refreshToken }}>
            {children}
        </TokenContext.Provider>
    );
};


export default TokenProvider;
