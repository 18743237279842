import Navigation from './Navigation/Navigation';
import React from 'react'
import './App.scss';
import TokenProvider from './Hooks/TokenContext';

const App = () => {

    return (
        <TokenProvider>
            <Navigation />
        </TokenProvider>

    );
};

export default App;
