import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Link, useNavigate } from "react-router-dom";
import "./CreateAccount.scss";
import logo from "../../../Assets/Logo/LOGO.svg";
import laulamaimage from "../../../Assets/Loginimage.svg";
import { Divider } from 'primereact/divider';
import { UNIQUE_ID_HEADERS, UNIQUE_ID_URL, SIGNUP_URL, SIGNUP_URL_HEADERS, FORGOT_PASSWORD_URL, DELETE_URL } from '../../../Utils/LoginService';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import JSEncrypt from 'jsencrypt';
import ErrorMessage from "../../../Shared-Component/ErrorMessage/ErrorMessage";
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

const CreateAccount = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    createpassword: "",
    confirmPassword: "",
    termsChecked: false,
  });

  const [passwordError, setPasswordError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });
  const [validation, setValidation] = useState(false);
  const [emailValidation, setemailValidation] = useState(false);

  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      termsChecked: e.checked,
    });

    const isLengthValid = value?.length >= 8;
    const hasNumber = /\d/.test(value);
    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    setIsLengthValid(isLengthValid);
    setHasNumber(hasNumber);
    setHasUppercase(hasUppercase);
    setHasLowercase(hasLowercase);
    setHasSpecialCharacter(hasSpecialCharacter);

    if (e.target.placeholder === "Confirm Password") {
      if (
        formData?.createpassword ===
        (e.target.value || formData?.confirmPassword)
      ) {
        setValidation(true);
      } else {
        setValidation(false)
      }

      if (e?.target?.checked && (formData?.createpassword === (e.target.value || formData?.confirmPassword))) {
        setValidation(true)
      } else if (!e?.target?.checked) {
        if ((formData?.createpassword === e.target.value)) {
          setValidation(true)
        } else {
          setValidation(false)
        }
      }

    } else {
      if (formData?.confirmPassword === (e.target.value || formData?.createpassword)) {
        setValidation(true)
      } else {
        setValidation(false)
      }

      if (e?.target?.checked && (formData?.confirmPassword === (e.target.value || formData?.createpassword))) {
        setValidation(true)
      } else if (!e?.target?.checked) {
        if ((formData?.confirmPassword === (e.target.value || formData?.createpassword))) {
          setValidation(true)
        } else {
          setValidation(false)
        }
      }
    }
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData({
      ...formData,
      email: emailValue,
    });

    const isEmailValid = validateEmail(emailValue);
    if (!isEmailValid) {
      setemailValidation("Please enter a valid email address");
    } else {
      setemailValidation("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.email || !formData.createpassword || !formData.confirmPassword) {
      setPasswordError("Please fill all the fields.");
      return;
    }
    else if (formData.createpassword !== formData.confirmPassword) {
      setPasswordError("Your passwords do not match. Please make sure they match to continue.");
      return;
    }


    setPasswordError('');

    setLoading(true);
    setOpacityStyle({ opacity: 0.5 });

    setTimeout(() => {
      setLoading(false);
      setOpacityStyle({ opacity: 1, background: 'transparent' });
    }, 3000);

    const uniqueId = uuidv4();

    axios.post(UNIQUE_ID_URL, { uniqueId }, { headers: UNIQUE_ID_HEADERS })
      .then((response) => {
        const publicKey = response.data.PublicKey;

        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(publicKey);

        const encryptedPassword = jsEncrypt.encrypt(formData.createpassword);

        const requestData = {
          uniqueId: uniqueId,
          emailId: formData.email,
          password: encryptedPassword,
        };

        axios.post(SIGNUP_URL, requestData, { headers: SIGNUP_URL_HEADERS })
          .then((response) => {
            localStorage.setItem('email', requestData.emailId);
            localStorage.setItem("userId", response.data.user_id);
            navigate('/signup/confirmcode');
          })
          .catch((error) => {
            console.error('API Error:', error.response.data.message);
            if (error?.response?.data?.hasOwnProperty("message")) {
              const errorMessage = error.response.data.message;
            
              switch (errorMessage) {
                case "Given User Not Registered By Admin":
                  setPasswordError("You are not registered by Admin");
                  break;
            
                case "Cognito user already exists":                
                  axios
                    .delete(DELETE_URL + formData.email, {})
                    .then((res) => {
                      axios.post(SIGNUP_URL, requestData, { headers: SIGNUP_URL_HEADERS })
                        .then((response) => {
                          localStorage.setItem('email', requestData.emailId);
                          navigate('/signup/confirmcode');
                        })
                        .catch((error) => { });
                    })
                    .catch((error) => { });
                  break;
            
                case "Email already exists.":
                  setPasswordError(errorMessage);
                  break;
            
                case "INVALID_PASSWORD_COMPOSITION,":
                  setPasswordError("Your password is invalid. Please follow the password criteria.");
                  break;
            
                case "Exceeded daily email limit for the operation or the account":
                  setPasswordError("Exceeded daily email limit for the operation or the account");
                  break;
            
                default:
                  setPasswordError(errorMessage);
                  break;
              }
            } else {
              setPasswordError('Please double-check and try again.');
            }
            
          });
          setLoading(false);
          setOpacityStyle({opacity : 1, background : 'transparent'});
      })
      .catch((error) => {
        setLoading(false);
        console.error('API Error:', error.response.data.message);
      });
  };

  const header = <div className="font-bold mb-3">Pick a password</div>;
  const footer = (
    <>
      <Divider />
      <p className="mt-2">Your password must contain</p>
      <ul className="pl-2 ml-2 mt-0 line-height-3">
        <li className={isLengthValid ? "valid" : "invalid"}>Between 8 and 16 characters</li>
        <li className={hasNumber ? "valid" : "invalid"}>At least one number</li>
        <li className={hasSpecialCharacter ? "valid" : "invalid"}>At least one special character</li>
        <li className={hasUppercase ? "valid" : "invalid"}>At least one uppercase letter</li>
        <li className={hasLowercase ? "valid" : "invalid"}>At least one lowercase letter</li>       
      </ul>
    </>
  );
  return (
    <div className="account-container">
      <div className="spinner-section">
              {loading ? <Spinner /> : null}
      </div>
      <div className="logo">
        <a href="/"><img src={logo} alt="" /></a>
      </div>
      <Card className="signup-card-section m-7">
        <div className="grid ml-0 mr-0">
          <div className="image-field m-0 col-6">
            <img src={laulamaimage} alt="Laulama Image" />
          </div>
          <div className="form-container m-0 col-6 flex-auto">
            <div className="account m-5">
              <p>
                <b>Create Your Account</b>
                <br />
                <br />
                {passwordError && <ErrorMessage message={passwordError} isSuccess={true} />}

              </p>
              {/* <div className="">
              <p><b>Hi,</b></p>
            </div> */}
              <div className="form-field">
                <form onSubmit={handleSubmit}>
                  {/* <div className="formgrid grid">
                  <div className="field col ">
                    <label htmlFor="name">Name</label>
                    <InputText
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div className="field col">
                    <label className="middlename" htmlFor="middlename">
                      Middle Name(optional)
                    </label>
                    <InputText
                      className=""
                      id="middlename"
                      name="middlename"
                      value={formData.middlename}
                      onChange={handleChange}
                      placeholder="Middle Name"
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="lastname">Last Name</label>
                  <InputText
                    id="lastname"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                </div> */}
                  <div className="field">
                    <label htmlFor="email">Email</label>
                    <InputText
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleEmailChange}
                      placeholder="Example Email: 1234567@k12.hi.us"
                      onFocus={(e) => e.target.placeholder = ''}
                      onBlur={(e) => {
                        if (!e.target.value.trim()) {
                          e.target.placeholder = 'Example Email: 1234567@k12.hi.us';
                        }
                      }}
                      className={passwordError && !formData.email ? 'email-error' : '' || emailValidation ? 'email-error' : ''}
                    />
                     {emailValidation && <div className='errorLable'>{emailValidation}</div>}
                  </div>

                  <div className="field">
                    <label htmlFor="createpassword">Create Password</label>
                    <Password
                      placeholder="Enter Your Password"
                      name="createpassword"
                      value={formData.createpassword}
                      onChange={handleChange}
                      toggleMask
                      header={header} footer={footer}
                      className={`p-password ${passwordError && !formData.createpassword ? 'error' : ''} ${passwordError && formData.createpassword !== formData.confirmPassword || !formData.createpassword && formData.confirmPassword ? 'error' : ''}`}
                      minLength={8}
                      maxLength={16}
                    />

                    {!formData.createpassword && formData.confirmPassword ? <div>
                      <div className='errorLable'>Please enter a create password</div>
                    </div> : null}
                  </div>
                  <div className="field">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <Password
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      toggleMask
                      header={header} footer={footer}
                      className={`p-password ${passwordError && !formData.confirmPassword ? 'error' : ''} ${passwordError && formData.createpassword !== formData.confirmPassword || formData?.confirmPassword && formData?.createpassword && !validation ? 'error' : ''}`}
                      minLength={8}
                      maxLength={16}
                    />
                    {formData?.confirmPassword && formData?.createpassword && !validation ? <div>
                      <div className='errorLable'>Please check a confirm password</div>
                    </div> : null}
                  </div>
                  <div className="field-checkbox col flex align-items-center gap-2">
                    <Checkbox
                      inputId="terms"
                      checked={formData.termsChecked}
                      onChange={handleChange}
                    ></Checkbox>
                    <label htmlFor="terms">
                      I’ve read and accepted{" "}
                      <a href="https://staticfile.laulamaschools.com/LaulamaPrivacyPolicies.pdf" target="_blank" >Privacy Policy</a>
                      {/* <a>
                      <Link to="/signup">Terms of Service</Link>
                    </a>{" "}
                    and{" "}
                    <a>
                      <Link to="/signup">Privacy Policy</Link>
                    </a> */}
                    </label>
                  </div>
                  <div className="button-container">
                    <Button type="submit" label="Create an account" disabled={!formData?.email || !formData.createpassword || !formData?.confirmPassword || !formData.termsChecked || !validation || emailValidation} />
                  </div>
                  <div className="help flex align-items-center justify-content-end mt-5 mr-3">
                  <a href="https://staticfile.laulamaschools.com/Student+Dashboard+Guideline.pdf" target='_blank' style={{color: "#3183ff", cursor: "pointer", fontSize:"18px" }} >
                  <FontAwesomeIcon icon={faCircleQuestion} /> Help
                  </a>
                </div>

                </form>
              </div>
            </div>

          </div>
        </div>
      </Card>
    </div>
  );
};

export default CreateAccount;
