import React, { useState, useEffect } from "react";
import "./PSAT-Scores.scss";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import Illustration from "../../../../../../Assets/Student/tableilustration.svg";
import { Image } from "primereact/image";
import { RadioButton } from "primereact/radiobutton";
import moment from "moment";
import { InputNumber } from "primereact/inputnumber";
import ErrorMessage from "../../../../../../Shared-Component/ErrorMessage/ErrorMessage";

const PSAT_Scores = ({ getApiResponse }) => {
  const [toggleForm, setToggleForm] = useState(false);
  const [error, setError] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [psatNewData, setPsatNewData] = useState([]);
  const psatLocalStorageKey = "PSAT_Scores";

  const psatData = {
    date: "",
    evidenceBasedReadingAndWriting: "",
    math: "",
    essay_CR: "",
    essay_M: "",
    essay_W: "",
  };

  const [psatformData, setPsatFormData] = useState(psatData);

  useEffect(() => {
    if (getApiResponse?.psatScores?.scoresVersion.length > 0) {
      const response = getApiResponse?.psatScores?.scoresVersion;
      const latestVersionNumber = ((response.length) - 1);
      const psat = getApiResponse?.psatScores.scoresVersion[latestVersionNumber];
      if (psat?.scores.length > 0) {
        const psatScores = psat.scores.map((item) => {
          var val = item.date;
          const date = new Date(val);
          let formattedDate = "-";
          if (val && val !== "Invalid date" && val > 0) {
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
          const year = date.getFullYear().toString().slice(2);
          formattedDate =  `${month}/${year}`;
          }
          return {
            date: formattedDate,
            evidenceBasedReadingAndWriting:
            item?.evidenceBasedReadingAndWriting,
            math: item?.math,
            essay_CR: item?.essay_CR,
            essay_M: item?.essay_M,
            essay_W: item?.essay_W,
          };
        });
        setPsatNewData(psatScores);
        localStorage.setItem(psatLocalStorageKey, JSON.stringify(psatScores));
      }
    }
  }, [getApiResponse]);

  useEffect(() => {
    const psatStoredData = localStorage.getItem(psatLocalStorageKey);
    if (psatStoredData) {
      setPsatNewData(JSON.parse(psatStoredData));
    }
  }, []);

  const [errorEBRW, setErrorEBRW] = useState("");
  const [errorMaths, setErrorMaths] = useState("");
  const [errorEssayCR, setErrorEssayCR] = useState("");
  const [errorEssayM, setErrorEssayM] = useState("");
  const [errorEssayW, setErrorEssayW] = useState("");
  // const [errorPsatDate, setErrorPsatDate] = useState("");

  const handlePsatChange = (event) => {
    const { name, value } = event.target;
    const intValue = parseInt(value, 10);

    switch (name) {
      // case "date":
      //   if (!value) {
      //     setErrorPsatDate("Date field is required");
      //   } else {
      //     setErrorPsatDate("");
      //   }
      //   break;
      case "evidenceBasedReadingAndWriting":
        if (intValue < 160 || intValue > 760) {
          setErrorEBRW("The range must be 160-760");
        } else {
          setErrorEBRW("");
        }
        break;
      case "math":
        if (intValue < 160 || intValue > 760) {
          setErrorMaths("The range must be 160-760");
        } else {
          setErrorMaths("");
        }
        break;
      case "essay_CR":
        if (intValue < 1 || intValue > 4) {
          setErrorEssayCR("The range must be 1-4");
        } else {
          setErrorEssayCR("");
        }
        break;
      case "essay_M":
        if (intValue < 1 || intValue > 4) {
          setErrorEssayM("The range must be 1-4");
        } else {
          setErrorEssayM("");
        }
        break;
      case "essay_W":
        if (intValue < 1 || intValue > 4) {
          setErrorEssayW("The range must be 1-4");
        } else {
          setErrorEssayW("");
        }
        break;

      default:
        break;
    }

    setPsatFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handlePsatSubmit = (e) => {
    e.preventDefault();

    const fieldLabelMaps = {
      // date: "Date",
      evidenceBasedReadingAndWriting: "EBRW",
      math: "Math",
      essay_CR: "Essay CR",
      essay_M: "Essay M",
      essay_W: "Essay W",
    };

    const setError = (field, message) => {
      switch (field) {
        // case "date":
        //   setErrorPsatDate(message);
        //   break;
        case "evidenceBasedReadingAndWriting":
          setErrorEBRW(message);
          break;
        case "math":
          setErrorMaths(message);
          break;
        case "essay_CR":
          setErrorEssayCR(message);
          break;
        case "essay_M":
          setErrorEssayM(message);
          break;
        case "essay_W":
          setErrorEssayW(message);
          break;
        default:
      }
    };
    const eemessage = "This field is required";
    const errors = Object.entries(fieldLabelMaps).reduce(
      (acc, [field, label]) => {
        if (!psatformData[field]) {
          acc[field] = eemessage;
        }
        return acc;
      },
      {}
    );

    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([field, message]) => {
        setError(field, message);
      });
      return;
    }

    if (
      // !psatformData.date ||
      !psatformData.evidenceBasedReadingAndWriting ||
      !psatformData.math ||
      !psatformData.essay_CR ||
      !psatformData.essay_M ||
      !psatformData.essay_W
    ) {
      setError("");
    } else if (
      // errorPsatDate ||
      errorEBRW ||
      errorMaths ||
      errorEssayCR ||
      errorEssayM ||
      errorEssayW
    ) {
      setError("");
    } else {
      var selected_psat_date = moment
        .utc(psatformData.date)
        .format("MM/YYYY");
      const newPsatTableData = {
        date: selected_psat_date,
        evidenceBasedReadingAndWriting:
          psatformData.evidenceBasedReadingAndWriting,
        math: psatformData.math,
        essay_CR: psatformData.essay_CR,
        essay_M: psatformData.essay_M,
        essay_W: psatformData.essay_W,
      };

      if (editIndex !== null) {
        const updatedData = [...psatNewData];
        var selected_date = moment.utc(psatformData.date).format("MM/YYYY");
        const editedPsatFormData = {
          date: selected_date,
          evidenceBasedReadingAndWriting: psatformData.evidenceBasedReadingAndWriting,
          math: psatformData.math,
          essay_CR: psatformData.essay_CR,
          essay_M: psatformData.essay_M,
          essay_W: psatformData.essay_W,
        };
        updatedData[editIndex] = editedPsatFormData;
        setPsatNewData(updatedData);
        setEditIndex(null);
        setPsatFormData(psatData);
        localStorage.setItem(psatLocalStorageKey, JSON.stringify(updatedData));
      } else {
        const updatedPsatFormData = [...psatNewData, newPsatTableData];

        setPsatFormData(psatData);

        localStorage.setItem(
          psatLocalStorageKey,
          JSON.stringify(updatedPsatFormData)
        );
      }
      const psatStoredData = localStorage.getItem(psatLocalStorageKey);
      if (psatStoredData) {
        setPsatNewData(JSON.parse(psatStoredData));
      }

      handleClick();
    }
  };

  const handleClick = () => {
    setEditIndex(null);
    setPsatFormData(psatData);
    setToggleForm(!toggleForm);
  };

  const handleEdit = (rowData, index) => {
    setToggleForm(true);
    const dateValue = rowData.date;
    const [month, year] = dateValue.split('/');
    const formattedDate = new Date(`${month}/01/${year}`);
    setPsatFormData({ ...rowData, date: formattedDate });
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    const updatedData = [...psatNewData];
    updatedData.splice(index, 1);
    setPsatNewData(updatedData);
    setEditIndex(null);
    localStorage.setItem(psatLocalStorageKey, JSON.stringify(updatedData));
    setPsatFormData(psatData);
  };

  const calculateTotal = (rowData) => {
    const { evidenceBasedReadingAndWriting, math } = rowData;
    const total = evidenceBasedReadingAndWriting + math ;
    return total;
};
  return (
    <div>
      <div className="extra-activities-section">
        {toggleForm ? (
          <div className="extra-heading">
            <form onSubmit={handlePsatSubmit}>
              {error && <ErrorMessage message={error} isSuccess={true} />}

              <div className="grid act-container">
                <div className="act-label col-4 flex flex-column gap-2">
                  <label>Date(Optional)</label>
                  <Calendar
                    name="date"
                    view="month" 
                    dateFormat="mm/yy"
                    value={psatformData.date}
                    onChange={handlePsatChange}
                    showIcon
                    className={`w-full md:w-18rem 
                    // $
                    // {
                    //   errorPsatDate ? "error" : ""
                    }`
                  }
                  />
                  {/* {errorPsatDate && (
                    <div>
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorPsatDate}
                      </span>
                    </div>
                  )} */}
                </div>
                <div className="form-content col-8 flex flex-column gap-2 mt-5">
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act w-full md:w-14rem mr-1">
                      EB R/W
                    </label>
                    <InputNumber
                      name="evidenceBasedReadingAndWriting"
                      value={psatformData.evidenceBasedReadingAndWriting}
                      onValueChange={handlePsatChange}
                      keyfilter="num"
                      placeholder="Enter your points: 160-760"
                      className={`w-full md:w-25rem ${
                        errorEBRW ||
                        (error && !psatformData.evidenceBasedReadingAndWriting)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorEBRW && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorEBRW}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Math
                    </label>
                    <InputNumber
                      name="math"
                      value={psatformData.math}
                      onValueChange={handlePsatChange}
                      keyfilter="num"
                      placeholder="Enter your points: 160-760"
                      className={`w-full md:w-25rem ${
                        errorMaths || (error && !psatformData.math)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorMaths && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorMaths}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Essay-CR
                    </label>
                    <InputNumber
                      name="essay_CR"
                      value={psatformData.essay_CR}
                      onValueChange={handlePsatChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-4"
                      className={`w-full md:w-25rem ${
                        errorEssayCR || (error && !psatformData.essay_CR)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorEssayCR && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorEssayCR}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Essay-M
                    </label>
                    <InputNumber
                      name="essay_M"
                      value={psatformData.essay_M}
                      onValueChange={handlePsatChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-4"
                      className={`w-full md:w-25rem ${
                        errorEssayM || (error && !psatformData.essay_M)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorEssayM && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorEssayM}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Essay-W
                    </label>
                    <InputNumber
                      name="essay_W"
                      value={psatformData.essay_W}
                      onValueChange={handlePsatChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-4"
                      className={`w-full md:w-25rem ${
                        errorEssayW || (error && !psatformData.essay_W)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorEssayW && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorEssayW}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <footer className="Extra-activities-form-footer gap-4">
                <Button
                  className="scores-button"
                  label="Cancel"
                  severity="info"
                  onClick={handleClick}
                />
                <Button
                  type="submit"
                  value="Submit"
                  label={editIndex !== null ? "Update PSAT Score" : "Add PSAT Score"}
                  severity="secondary"
                />
              </footer>
            </form>
          </div>
        ) : (
          <div>
            <div
              className="flex flex-wrap justify-content-end gap-1 pt-4 pb-4"
              onClick={handleClick}
            >
              <div className="addextra-text">+ Add PSAT Score</div>
            </div>

            {psatNewData.length === 0 ? (
              <div className="flex flex-column justify-content-center align-items-center">
                <Image src={Illustration} alt="Image" className="extra-image" />
                <p className="ex-p">
                  You will see information here when you add your PSAT Scores
                </p>
                <Button className='m-3' label="Add PSAT Score" severity="secondary" onClick={handleClick}/>
              </div>
            ) : (
              <div className="extra-activities-tables pt-4 pb-4">
                <DataTable
                  value={psatNewData}
                  tableStyle={{ minWidth: "50rem" }}
                  className="datatable-container"
                >
                  <Column field="date" header="Date" body={(rowData) => rowData.date && rowData.date !== "Invalid date" ? rowData.date : "-"} />
                  <Column field="total" header="Total" body={(rowData) => calculateTotal(rowData)}></Column>
                  <Column
                    field="evidenceBasedReadingAndWriting"
                    header="EB R/W"
                  />
                  <Column field="math" header="Math" />
                  <Column field="essay_CR" header="Essay-CR" />
                  <Column field="essay_M" header="Essay-M" />
                  <Column field="essay_W" header="Essay-W" />
                  <Column
                    header="Actions"
                    body={(rowData, column) => (
                      <div className="actions">
                        <div>
                          <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-success mr-1"
                            onClick={() => handleEdit(rowData, column.rowIndex)}
                          />
                          <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger ml-1"
                            onClick={() => handleDelete(column.rowIndex)}
                          />
                        </div>
                      </div>
                    )}
                  />
                </DataTable>

                <ul className='hide-on-large-screens'>
                  {psatNewData.map((item, index) => (
                    <li key={item.id}>
                      <div>
                        <p className='activity-heading flex justify-content-around align-items-center'>
                          Date <span>{item.date}</span>
                        </p>
                      </div>
                      <div className='px-6 py-4'>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Total</strong> {calculateTotal(item)}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Math</strong> {item.math}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Essay-CR</strong> {item.essay_CR}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Essay-M</strong> {item.essay_M}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Essay-W</strong> {item.essay_W}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Actions</strong>
                          <div className="actions ">
                            <Button
                              icon="pi pi-pencil"
                              className="p-button-rounded p-button-success mr-1"
                              onClick={() => handleEdit(item, index)}
                            />
                            <Button
                              icon="pi pi-trash"
                              className="p-button-rounded p-button-danger ml-1"
                              onClick={() => handleDelete(index)}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PSAT_Scores;
