// Base Url
export const BASE_URL = `${process.env.REACT_APP_URL}`;

// export const BASE_URL = 'https://backend.devl.laulamaschools.com/laulama';

export const GET_TEACHER_URL = `${BASE_URL}/teachers`;

export const TEACHER_URL_HEADERS = {
    "content-type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};

export const GET_TEACHER_DETAILS_URL = `${BASE_URL}/teacher/`;

export const TEACHER_NOTES_URL = `${BASE_URL}/teacher/student_notes`;