import React from "react";
import "./Gif-Loader.scss"
import Torch from '../../Assets/Torch-beat.gif';

const Spinner = () => {

  return (
    <div className="loading-spinner-container">
      <img className="gif-loader" src={Torch} alt="loading....." />
      <div className="loading-spinner">
      </div>
    </div>
  );
}

export default Spinner;
