import React, { useState } from "react";
import "./Founder.scss";
import FounderImg from "../../../Assets/AboutUs/CEO-Image.jpg";
import { Link } from "react-router-dom";

const Founder = () => {
  return (
    <div
      id="Founder"
      className="founder"
      style={{ paddingTop: "110px", backgroundColor: "none" }}
    >
      <div className="flex justify-content-center align-items-center founder">
        <div className="p-5 founder-img">
          <img src={FounderImg} alt="" />
        </div>
        <div className="p-5">
          <div className="founder-details-section">
            <p>Mel Horikami </p>
            <span>Laulama CEO</span>
          </div>
          <div className="founder-para">
            <p>
              {" "}
              Over the course of Mel Horikami’s career in the telecommunications
              industry, he developed a track record of successfully tackling
              tough and complex problems. Mel’s ability to address and find
              solutions to such challenging issues was grounded in the unique
              perspective and set of skills acquired throughout his
              unconventional career path. Mel began his telecom career in an
              entry level field position and worked his way up through positions
              spanning a diverse array of functional areas across regions of the
              U.S. from Hawaii to New York. While working, he earned both
              undergraduate and graduate college degrees and ended his 30-year
              career in executive level positions in a Fortune 500 company.
            </p>

            <span>
              {" "}
              Upon retirement in 2008, Mel returned from Dallas, Texas to
              Honolulu, Hawaii where he began to search for a way to contribute
              to the community in which he was raised. Although areas such as
              healthcare and the environment were considered, Mel felt that
              efforts aimed at improving the educational system in Hawaii would
              have the most potential to influence the quality of life in our
              islands. He experienced first-hand the benefit that higher
              education had on his life and believed that a good educational
              foundation starting as early as in preschool could create the
              greatest future impact for our people and community. He decided to
              apply the business experience and knowledge gained throughout his
              career, in a nontraditional but innovative way, in the public
              education area.
            </span>

            <div style={{ paddingTop: "20px" }}>
              <span>
                Optimum Business Solutions LLC, an organizational transformation
                practice, was established in 2010 based on this objective and
                began the journey that has led to Laulama Learning and Laulama
                Schools.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founder;
