import React from "react";
import "./Products-Services.scss";
import PSimage from '../../../Assets/Home/ProductsServices.jpg';
import { BiSolidChevronRightSquare } from "react-icons/bi";

const Products_Services = () => {
  return (
    <>
      <div className="products_services">
        <div className="title-section">
          <div className="title">
            <div className="main-title">Products & Services</div>
          </div>
        </div>
        <div className="content-section grid">

          <div className="col-12 md:col-12 lg:col-6 ps-left">
            <img className="ps-image" src={PSimage} alt='PSimage' />
          </div>

          <div className="col-12 md:col-12 lg:col-6 ps-right">

            <div className="ps-section gird">
              <div className="section-left col-11">
                <div className="ps-title">School Design Visual Frameworks</div>
                <div className="ps-subtitle">The complex workings of a school’s design is what produces the results.   Visual Frameworks help to make the key elements and connections visible so it is clearer and simpler to understand.</div>
              </div>
              <div className="section-right col-1 text-center">
                <div className="s-icon text-center p-3"><BiSolidChevronRightSquare size="large"
                  style={{ width: "40px", color: "#3B8A6E" }}
                  className="icon-card" /></div>
              </div>
            </div>

            <div className="ps-section gird">
              <div className="section-left col-11">
                <div className="ps-title">Charts, Worksheets</div>
                <div className="ps-subtitle">Data charts and worksheets are used to show the performance of the elements and connections, and to make sense of them individually and as a whole.</div>
              </div>
              <div className="section-right col-1 text-center">
                <div className="s-icon text-center p-3"><BiSolidChevronRightSquare size="large"
                  style={{ width: "40px", color: "#3B8A6E" }}
                  className="icon-card" /></div>
              </div>
            </div>

            <div className="ps-section gird">
              <div className="section-left col-11">
                <div className="ps-title">Change Packages</div>
                <div className="ps-subtitle">Change Packages is adopted from the Carnegie Foundation for the Advancement of Teaching.  We use it to help schools think through, document and implement school design change experiments and initiatives.</div>
              </div>
              <div className="section-right col-1 text-center">
                <div className="s-icon text-center p-3"><BiSolidChevronRightSquare size="large"
                  style={{ width: "40px", color: "#3B8A6E" }}
                  className="icon-card" /></div>
              </div>
            </div>

            <div className="ps-section gird">
              <div className="section-left col-11">
                <div className="ps-title">Web Portal Devolopment</div>
                <div className="ps-subtitle">We design and develop dashboards that streamline work and improvement, by bringing together essential information and tools in way that is simple and easy to use.</div>
              </div>
              <div className="section-right col-1 text-center">
                <div className="s-icon text-center p-3"><BiSolidChevronRightSquare size="large"
                  style={{ width: "40px", color: "#3B8A6E" }}
                  className="icon-card" /></div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </>
  );
};

export default Products_Services;
