import React, { useEffect, useState } from 'react';
import './StudentForm.scss';
import { TabMenu } from 'primereact/tabmenu';
import CareerGoalsForm from './Career-Goals-Form/CareerGoalsForm';
import AcademicGoalsForm from './Academic-Goals-Form/Academic-Goals-Form';
import AcademicPathwaysForm from './Academic-Pathways-Form/Academic-Pathways-Form';
import ExtraActivitiesForm from './Extra-Activities-Form/Extra-Activities-Form';
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import PersonalStatementForm from './Personal-Statement-Form/PersonalStatementForm';
import HighSchoolProgressForm from './High-School-Progrss-Form/HighSchoolProgressForm';
import TestScoresForm from './Test-Scores-Form/TestScoresForm';


const StudentForm = ({ onCareerGoalsUpdate, onAcademicGoalsUpdate, onAcademicPathwaysUpdate }) => {
    const items = [
        { label: 'Personal Statement' },
        { label: 'Career Goals' },
        { label: 'Academic Goals' },
        { label: 'Academies Pathways' },
        { label: 'High School Progress' },
        { label: 'Test Scores' },
        { label: 'Extra Activities' }

    ];

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setPersonalStatementState(false);
        setCareerGoalsState(false);
        setAcademicGoalsState(false);
        setAcademicPathwayState(false);
        setHighSchoolState(false);
        setTestScoreState(false);
        setExtraActivityState(false);
    })

    const [personalStatementState, setPersonalStatementState] = useState(false);
    const [careerGoalsState, setCareerGoalsState] = useState(false);
    const [academicGoalsState, setAcademicGoalsState] = useState(false);
    const [academicPathwayState, setAcademicPathwayState] = useState(false);
    const [highSchoolState, setHighSchoolState] = useState(false);
    const [testScoreState, setTestScoreState] = useState(false);
    const [extraActivityState, setExtraActivityState] = useState(false);

    const acceptFunc = (e) => {

        if (activeTab === 0) {
            setPersonalStatementState(true);
            setTimeout(() =>
                setActiveTab(e.index), 1000);
        } else if (activeTab === 1) {
            setCareerGoalsState(true);
            setTimeout(() =>
                setActiveTab(e.index), 1000);
        } else if (activeTab === 2) {
            setAcademicGoalsState(true);
            setTimeout(() =>
                setActiveTab(e.index), 1000);
        } else if (activeTab === 3) {
            setAcademicPathwayState(true);
            setTimeout(() =>
                setActiveTab(e.index), 1000);
        } else if (activeTab === 4) {
            setHighSchoolState(true);
            setTimeout(() =>
                setActiveTab(e.index), 1000);
        } else if (activeTab === 5) {
            setTestScoreState(true);
            setTimeout(() =>
                setActiveTab(e.index), 1000);
        } else if (activeTab === 6) {
            setExtraActivityState(true);
            setTimeout(() =>
                setActiveTab(e.index), 1000);
        }
    }

    const handleTabChange = (event) => {
        confirmDialog({
            message: <p className='dialog-message'>Your information has been automatically saved.</p>,
            header: 'Information Saved',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptFunc(event),
            acceptLabel: 'OK',
            // rejectLabel: '-',
            acceptIcon: 'pi pi-check',
            // rejectIcon: 'pi pi-times',
            rejectClassName: 'action-buttons',
        });
        // setActiveTab(event.index);
    };


    useEffect(() => {
        handleCareerGoalsUpdate();
        handleAcademicGoalsUpdate();
        handleAcademicPathwayUpdate();
    }, []);

    const handleCareerGoalsUpdate = (updatedData) => {
        onCareerGoalsUpdate(updatedData);
    };

    const handleAcademicGoalsUpdate = (updatedData) => {
        onAcademicGoalsUpdate(updatedData);
    }

    const handleAcademicPathwayUpdate = (updatedData) => {
        onAcademicPathwaysUpdate(updatedData);
    }

    const renderSelectedComponent = () => {
        switch (activeTab) {
            case 0:
                return <PersonalStatementForm
                    onNextButtonClick={handleNextButtonClick}
                    personalStatementState={personalStatementState}
                />;
            case 1:
                return <CareerGoalsForm
                    handleBackButtonClick={handleBackButtonClick}
                    onNextButtonClick={handleNextButtonClick}
                    onCareerGoalUpdate={handleCareerGoalsUpdate}
                    careerGoalsState={careerGoalsState}
                />;
            case 2:
                return <AcademicGoalsForm
                    handleBackButtonClick={handleBackButtonClick}
                    onNextButtonClick={handleNextButtonClick}
                    onAcademicGoalUpdate={handleAcademicGoalsUpdate}
                    academicGoalsState={academicGoalsState}
                />;
            case 3:
                return <AcademicPathwaysForm
                    handleBackButtonClick={handleBackButtonClick}
                    onNextButtonClick={handleNextButtonClick}
                    onAcademicPathwayUpdate={handleAcademicPathwayUpdate}
                    academicPathwayState={academicPathwayState}
                />;
            case 4:
                return <HighSchoolProgressForm
                    handleBackButtonClick={handleBackButtonClick}
                    onNextButtonClick={handleNextButtonClick}
                    highSchoolState={highSchoolState}
                />;
            case 5:
                return <TestScoresForm
                    handleBackButtonClick={handleBackButtonClick}
                    onNextButtonClick={handleNextButtonClick}
                    testScoreState={testScoreState}
                />;
            case 6:
                return <ExtraActivitiesForm
                    handleBackButtonClick={handleBackButtonClick}
                    onNextButtonClick={handleLastButtonClick}
                    extraActivityState={extraActivityState}
                />;
            default:
                return null;
        }
    };

    const handleNextButtonClick = () => {
        if (activeTab < items.length - 1) {
          setActiveTab(activeTab + 1);
        }
      };
      

    const handleBackButtonClick = () => {
        if (activeTab > 0) {
            setActiveTab(activeTab - 1);
        }
    };

    const handleLastButtonClick = () => {
        setActiveTab(0);

      }



    return (
        <div>
            <div className='form-container'>
                {/* <div className='heading'>My 10 year Academy and Career Plan</div> */}
                <div className='heading'>My Personal Pathway</div>
                <hr></hr>
                <div><ConfirmDialog /></div>
                <div className="tabmenu">
                    <TabMenu model={items} activeIndex={activeTab} onTabChange={handleTabChange} />
                </div>
            </div>

            {renderSelectedComponent()}

        </div>
    );
};

export default StudentForm;