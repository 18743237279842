import React, { useState } from 'react';
import Logo from '../../../Assets/Logo/LOGO.svg';
import Loginimage from '../../../Assets/Loginimage.svg'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import axios from 'axios';
import { FORGOT_PASSWORD_URL, UNIQUE_ID_HEADERS } from '../../../Utils/LoginService';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../../Shared-Component/ErrorMessage/ErrorMessage';

const ForgetPassword = () => {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setUsername(e.target.value);
        setError('');
    };
    const isEmailValid = (email) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };

    const handleSentIt = () => {   
        if(!username) {
            setError("Please provide an email.");
           }
          else if (!isEmailValid(username)) {
            setError("Please enter a valid email address.");
            return;
        }
        else{
            axios
            .post(FORGOT_PASSWORD_URL, { emailId: username }, { headers: UNIQUE_ID_HEADERS })
            .then((response) => {       
                navigate(`/resetpassword?email=${encodeURIComponent(username)}`);                
            }
            )
            .catch((error) => {
                console.log(error.response.data.message);              
                 if (error?.response?.data?.hasOwnProperty("message")) {
                    const errorMessage = error.response.data.message;
                  
                   if (errorMessage === "Enter the valid username") {
                        setError("The email you’re entered doesn’t exist. Please check that you have typed your email correctly.");
                    }                    
                 } 
                 else{   
                    setError('Please double-check and try again.')           
                     
                       
                 }                  
                   
                }              
            );
        }
       
       
    };

    return (
        <div className="login-container">
            <div className="logo">
                <a href="/"><img src={Logo} alt="" /></a>
            </div>
            <Card className="login-card-section m-7" >
                <div className="grid ml-0"  style={{ minHeight: '70vh' }}>
                    <div className='image-section m-0 col-6 '>
                        <img src={Loginimage} alt='Loginimage' />
                    </div>
                    <div className='login-section m-0 col-6 flex-auto	'>
                        <div className='login m-5'>
                            <p><b>Forget Password?</b></p>
                            <div className='login-sub'>
                                <p> Please provide your email for change your password.</p>
                                {error && <ErrorMessage message={error} isSuccess={true}/>}
                            </div>
                            <div className="p-fluid">
                                <div className="field">
                                    <label className="email">Email</label>
                                    <InputText
                                        value={username}
                                        placeholder='Enter your email'
                                        onChange={handleInputChange}
                                        className={error && !username.email ? "email-error" : ""}                                       
                                    />                                 
                                    {/* <InputText value={username} placeholder='Enter your email' onChange={(e) => setUsername(e.target.value)} /> */}
                                </div>
                                <div className='button'>
                                    <Button label="Send It" className="p-button-sm" type="submit" onClick={handleSentIt} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ForgetPassword;
