import React, { useState, useEffect } from 'react'
import "./awards-recognitions.scss";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import Illustration from '../../../../../../Assets/Student/tableilustration.svg';
import { Image } from 'primereact/image';
import moment from 'moment';


const AwardsRecognitions = ({getApiResponse}) => {

    const [toggleForm, setToggleForm] = useState(false);
    const [newData, setNewData] = useState([]);
    const localStorageKey = 'Achievements';
    const [editIndex, setEditIndex] = useState(null);
    const data = {
        achievement: '',
        date: '',
        description: '',
    };

    const [formData, setFormData] = useState(data);


    useEffect(() => {
        const storedData = localStorage.getItem(localStorageKey);
        if (storedData) {
            setNewData(JSON.parse(storedData));
        }
    }, []);

      useEffect(() => {
        if (getApiResponse?.achievements?.length > 0) {
          const response = getApiResponse?.achievements;
          const latestVersionNumber = ((response.length) - 1);
          const latestAchievement = getApiResponse?.achievements[latestVersionNumber];
          if (latestAchievement?.achievement.length > 0) {
            const getAchievements = latestAchievement.achievement.map((item) => {
              var val = item.date;
                const [year, month, day] = val.split('-');
                // Format the date as mm/yyyy
                const formattedDate = `${month}/${year}`;
              return {
                    achievement: item?.achievement,
                    date: formattedDate,
                    description: item?.description,
                
              };
            });
            setNewData(getAchievements);
            localStorage.setItem(localStorageKey, JSON.stringify(getAchievements));
          }
        }
      }, [getApiResponse]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    // const handleChanges = (event) => {
    //     const formattedDate = formatDate(event.value);
    //     setFormData({ ...formData, date: formattedDate });
    // };

    // const formatDate = (date) => {
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const year = String(date.getFullYear()).slice(-2);
    //     return `${day}/${month}/${year}`;
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        var selected_date = moment.utc(formData.date).format('MM/YYYY');
        const newTableData = {
            achievement: formData.achievement,
            date: selected_date,
            description: formData.description,
        };

        if (editIndex !== null) {
            const updatedData = [...newData];
            var selected_date = moment.utc(formData.date).format('MM/YYYY');
                const editedData = {
                    achievement: formData.achievement,
                    date: selected_date,
                    description: formData.description,
                };
            updatedData[editIndex] = editedData;
            setNewData(updatedData);
            setEditIndex(null);
            setFormData(data);
            localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
          } else {
        const updatedFormData = [...newData, newTableData];

        setFormData(data);

        localStorage.setItem(localStorageKey, JSON.stringify(updatedFormData));
          }
        const storedData = localStorage.getItem(localStorageKey);
        if (storedData) {
            setNewData(JSON.parse(storedData));
        }


        handleClick();

    };

    const handleClick = () => {
        setEditIndex(null);
        setFormData(data);
        setToggleForm(!toggleForm);
      };
    
      const handleEdit = (rowData, index) => {
        setToggleForm(true);
        const dateValue = rowData.date;
        const [month, year] = dateValue.split('/');
        const formattedDate = new Date(`${month}/01/${year}`);
        setFormData({...rowData, date: formattedDate });
        setEditIndex(index);
      };
    
      const handleDelete = (index) => {
        const updatedData = [...newData];
        updatedData.splice(index, 1);
        setNewData(updatedData);
        setEditIndex(null);
        localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
        setFormData(data);
      };

   

    return (
        <div className='awards-section'>

            {toggleForm ?
                <div className='awards-recognition-heading'>
                    <div className='pt-1 pb-2'> Involve awards, special recognition like MVP, professional certifications etc.</div>
                    <form onSubmit={handleSubmit}>

                        <div className="grid pt-4">
                            <div className="col-6 flex flex-column">
                                <label>Name of the award</label>
                                <InputText name="achievement" value={formData.achievement} onChange={handleChange} required/>
                            </div>
                            <div className="col-6 flex flex-column date">
                                <label>Date</label>
                                <Calendar name="date" view="month" dateFormat="mm/yy" value={formData.date} onChange={handleChange} showIcon required />
                            </div>
                        </div>

                        <div className="flex flex-wrap justify-content-start gap-3 pt-4 pb-4">
                            <div className="flex flex-column gap-2 w-full">
                                <label>Description</label>
                                <InputTextarea name="description" className='description' value={formData.description} onChange={handleChange} rows={5} cols={120} required/>
                            </div>
                        </div>

                        <footer className="Extra-activities-form-footer gap-4">
                            <Button label="Cancel" severity="info" onClick={handleClick} />
                            <Button type="submit" value="Submit" label={editIndex !== null ? "Update Achievement" : "Add Achievement"} severity="secondary" />
                        </footer>
                    </form>
                </div>
                :
                <div>
                    <div className="grid pt-4 pb-4 notes-alignment" onClick={handleClick}>
                        <div className='col lg:col-9'>Involve awards, special recognition like MVP, professional certifications etc.</div>
                        <div className="col lg:col-3 addextra-text">+ Add Achievements</div>
                    </div>

                    {newData.length === 0 ? (
                        <div className="flex flex-column justify-content-center align-items-center">
                            <Image src={Illustration} alt="Image" className='awards-image' />
                            <p className='ar-p'>You will see information here when you add your Achievements</p>
                            <Button className='m-3' label="Add Achievements" severity="secondary" onClick={handleClick}/>
                        </div>
                    ) : (
                        <div className="extra-activities-tables pt-4 pb-4">
                            <DataTable value={newData} tableStyle={{ minWidth: '50rem' }} className='datatable-container'>
                                <Column field="achievement" header="Name of the Award"></Column>
                                <Column field="date" header="Date"></Column>
                                <Column field="description" header="Description"></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData, column) => (
                                    <div className="actions">
                                        <div>
                                        <Button
                                            icon="pi pi-pencil"
                                            className="p-button-rounded p-button-success mr-1"
                                            onClick={() => handleEdit(rowData, column.rowIndex)}
                                        />
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-rounded p-button-danger ml-1"
                                            onClick={() => handleDelete(column.rowIndex)}
                                        />
                                        </div>
                                    </div>
                                    )}
                                />
                            </DataTable>

                            <ul className=' hide-on-large-screens'>
                                {newData.map((item, index) => (
                                    <li key={item.id}>
                                        <p className='awards-heading'>{item.achievement}</p>
                                        <div className='px-4 py-4'>

                                            <div className='flex justify-content-around align-items-center'>
                                               {item.date}
                                            </div>

                                            <div className='flex justify-content-around align-items-center'>
                                                {item.description}
                                            </div>
                                            <div className="actions p-4 flex justify-content-around align-items-center">
                                                <Button
                                                    icon="pi pi-pencil"
                                                    className="p-button-rounded p-button-success mr-1"
                                                    onClick={() => handleEdit(item, index)}
                                                />
                                                <Button
                                                    icon="pi pi-trash"
                                                    className="p-button-rounded p-button-danger ml-1"
                                                    onClick={() => handleDelete(index)}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}
export default AwardsRecognitions